import React, { useEffect, useContext, useState } from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import $ from "jquery"
import { FillSpinner } from "react-spinners-kit";
import { UserContext } from "../../context/userProvider";
import { KOBO_SERVICE_LOCATION, nairaFormat } from "../../util/helpers";
import Endpoint from "../../util/endpoint";
import { ToastContainer, toast } from 'react-toastify';


const Checkout = () => {
    const userContext = useContext(UserContext);
    const [serviceAddress, setserviceAddress] = useState(userContext?.userProfile?.homeAddress)
    const [phone, setPhone] = useState(userContext?.userProfile?.phone)
    const [serviceLocation, setserviceLocation] = useState(JSON.parse(localStorage.getItem(KOBO_SERVICE_LOCATION)))


    
    const processCartCheckout = () => {
        if (serviceAddress == null || serviceAddress == ""){
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                Address cannot be left empty!
            </p>)
            return;
        }
        if (phone == null || phone == "") {
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                Phone cannot be left empty!
            </p>)
            return;
        }
        $("#preloader").fadeIn("slow")
        const payload = {
            "userId": userContext?.userPayload?.userId,
            "serviceAddress": serviceAddress,
            "requestedServices": userContext?.cartArray,
            "phone": Date().toString(),
            //"serviceTime": "10:00"
        }
        Endpoint.processCheckoutItems(payload)
        .then((res) => {
            console.log(res?.data, "checkout response")
            window.location.href = res?.data?.data?.authorization_url
        }) 
        .catch((err) => {
            console.log(err)
            $("#preloader").fadeOut("slow")
            toast.error(<p className="outfit-400 text-white" style={{ fontSize: "15px" }}>
                {err?.data?.message}
            </p>,
        {
            autoClose:10000
        }    
        )
        })
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return (
        <>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <div>
                {/* Page Title Section Start */}
                <div className="page-title-section section mt-3">
                    <div className="page-title">
                        <div className="container">
                            <h1 className="title">Checkout</h1>
                        </div>
                    </div>
                    <div className="page-breadcrumb">
                        <div className="container">
                            <ul className="breadcrumb outfit-400">
                                <li><a href="index.html">Home/ </a></li>
                                <li className="current">Checkout</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Page Title Section End */}
                {/*Checkout section start*/}
                <div className="checkout-section section section-padding-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Checkout Form Start*/}
                                <form action="#" className="checkout-form">
                                    <div className="row row-40">
                                        <div className="col-lg-7">
                                            {/* Billing Address */}
                                            <div id="billing-form" className="mb-10">
                                                <h4 className="checkout-title">Service/Billing Details</h4>
                                                <div className="row">
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>First Name*</label>
                                                        <input value={userContext?.firstName} type="text" placeholder="First Name" />
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>Last Name*</label>
                                                        <input type="text" value={userContext?.lastName} placeholder="Last Name" />
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>Email Address*</label>
                                                        <input defaultValue={userContext?.userProfile?.emailAddress} type="email" placeholder="Email Address" />
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>Phone no*</label>
                                                        <input type="text" defaultValue={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone number" />
                                                    </div>
                                                    {/* <div className="col-12 mb-20">
                                                        <label>Company Name</label>
                                                        <input type="text" placeholder="Company Name" />
                                                    </div> */}
                                                    
                                                    <div className="col-12 mb-20">
                                                        <label>Home/Service Address*</label>
                                                        <input onChange={(e) => setserviceAddress(e.target.value)} defaultValue={serviceAddress} type="text" placeholder="Enter Address" />
                                                        {/* <input type="text" placeholder="Address line 2" /> */}
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>State*</label>
                                                        <select className="outfit-400">
                                                            <option>{serviceLocation?.state}</option>
                                                            {/* <option>Enugu</option> */}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>LGA*</label>
                                                        <select className="outfit-400">
                                                            {/* <option>Lagos Island</option> */}
                                                            <option>{serviceLocation?.lga}</option>
                                                            {/* <option>Ikeja</option> */}
                                                        </select>
                                                    </div>
                                                    {/* <div className="col-md-6 col-12 mb-20">
                                                        <label>State*</label>
                                                        <input type="text" placeholder="State" />
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-20">
                                                        <label>Zip Code*</label>
                                                        <input type="text" placeholder="Zip Code" />
                                                    </div> */}
                                                    {/* <div className="col-12 mb-20">
                                                        <div className="check-box">
                                                            <input type="checkbox" id="create_account" />
                                                            <label htmlFor="create_account">Create an Acount?</label>
                                                        </div>
                                                        <div className="check-box">
                                                            <input type="checkbox" id="shiping_address" data-shipping />
                                                            <label htmlFor="shiping_address">Ship to Different Address</label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                         
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="row">
                                                {/* Cart Total */}
                                                <div className="col-12 max-mb-60">
                                                    <h4 className="checkout-title">Cart Total</h4>
                                                    <div className="checkout-cart-total">
                                                        <h4>Service(s) <span>Total</span></h4>
                                                        <ul>
                                                            {userContext?.cartArray && userContext?.cartArray?.map((x, i) => {
                                                                return(
                                                                    <li className="outfit-400">{x?.serviceName} ({x?.name}) <span>{x?.price && nairaFormat(x?.price, 2)}</span></li>
                                                                )
                                                            })}
                                                           
                                                            {/* <li className="outfit-400">Home Chef/Cook (Pro) <span>₦7,420.00</span></li> */}
                                                            
                                                        </ul>
                                                        <p>Sub Total <span>{userContext?.cartTotal && nairaFormat(userContext?.cartTotal, 2)}</span></p>
                                                        <p>Logistics <span>{userContext?.logisticsPrice && nairaFormat(userContext?.logisticsPrice, 2)}</span></p>
                                                        <h4>Grand Total <span>{userContext?.cartTotal && nairaFormat(userContext?.cartTotal + userContext?.logisticsPrice, 2)}</span></h4>
                                                    </div>
                                                </div>
                                                {/* Payment Method */}
                                                <div className="col-12">
                                                    <h4 className="checkout-title">Payment Method</h4>
                                                    <div className="checkout-payment-method">
                                                        {/* <div className="single-method">
                                                            <input type="radio" id="payment_check" name="payment-method" defaultValue="check" />
                                                            <label htmlFor="payment_check">Check Payment</label>
                                                            <p data-method="check">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                                        </div> */}
                                                        {/* <div className="single-method">
                                                            <input type="radio" id="payment_bank" name="payment-method" defaultValue="bank" />
                                                            <label htmlFor="payment_bank">Direct Bank Transfer</label>
                                                            <p data-method="bank">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                                        </div>
                                                        <div className="single-method">
                                                            <input type="radio" id="payment_cash" name="payment-method" defaultValue="cash" />
                                                            <label htmlFor="payment_cash">Cash on Delivery</label>
                                                            <p data-method="cash">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                                        </div> */}
                                                        {/* <div className="single-method">
                                                            <input type="radio" id="payment_paypal" name="payment-method" defaultValue="paypal" />
                                                            <label htmlFor="payment_paypal">Paypal</label>
                                                            <p data-method="paypal">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                                        </div> */}
                                                        <div className="single-method">
                                                            <input type="radio" id="payment_payoneer" name="payment-method" defaultValue="payoneer" />
                                                            <label htmlFor="payment_payoneer">Debit Card</label>
                                                            <p data-method="payoneer">Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                                        </div>
                                                        <div className="single-method">
                                                            <input type="checkbox" id="accept_terms" />
                                                            <label htmlFor="accept_terms">I’ve read and accept the terms &amp; conditions</label>
                                                        </div>
                                                    </div>
                                                    <button type="button" onClick={() => processCartCheckout()} className="btn btn-primary btn-hover-secondary btn-width-100 mt-40">Proceed to payment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Checkout section end*/}
            </div>


        </>
    )
}
export default withLayout(UserLayout)(Checkout)