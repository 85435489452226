import React, { createContext, useState, useEffect } from 'react';
import { KOBO_CART, KOBO_CART_FISH_SHOP, KOBO_SERVICE_LOCATION } from '../util/helpers';
import { isLoggedIn, USER_KEY } from '../util/auth';
import Endpoint from '../util/endpoint';
import { ToastContainer, toast } from 'react-toastify';
//import { Link, useLocation } from 'react-router-dom';
import $ from "jquery"
const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [userPayload, setUserPayload] = useState(JSON.parse(localStorage.getItem(USER_KEY)));
    const [cartArray, setCartArray] = useState(JSON.parse(localStorage.getItem(KOBO_CART)));
    const [firstName, setFirstName] = useState(userPayload?.fullName?.split(' ')[0]);
    const [lastName, setlastName] = useState(userPayload?.fullName?.split(' ')[1]);
    //const [cartTotal, setcartTotal] = useState(cartArray?.reduce((sum, item) => sum + item?.price, 0));
    const [cartTotal, setcartTotal] = useState(Array.isArray(cartArray) ? cartArray?.reduce((sum, item) => sum + (item?.price || 0), 0) : 0);
    
    const [cartArrayRegular, setcartArrayRegular] = useState(JSON.parse(localStorage.getItem(KOBO_CART_FISH_SHOP)));
    const [cartTotalRegular, setcartTotalRegular] = useState(Array.isArray(cartArrayRegular) ? cartArrayRegular?.reduce((sum, item) => sum + (item?.price || 0), 0) : 0);


    const [logisticsPrice, setlogisticsPrice] = useState(1200);
    const [userProfile, setuserProfile] = useState();
    const [userSubscription, setuserSubscription] = useState({});
    const [subscriptionStatus, setsubscriptionStatus] = useState(false);
    const [showSideNav, setshowSideNav] = useState(true);
    //const location = useLocation();
    //let url = location?.pathname;

    const fetchUserDetails = () => {
        Endpoint.getUserProfileByUserId(userPayload?.userId)
        .then((res) => {
            console.log(res?.data, "userprofile")

            setuserProfile(res?.data)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const fetchUserUserSubscription = () => {
        Endpoint.getUserSubscription(userPayload?.userId)
        .then((res) => {
            console.log(res?.data, "subscrr")
            if(res?.data != null){
                setsubscriptionStatus(true)
            }
            setuserSubscription(res?.data)
        })
        .catch((err) => {
            setsubscriptionStatus(false)
            setuserSubscription(null)
            console.log(err, "subscrr")
        })
    }

    const globalAddToCartRegular = (service, cartKey, kg) => {
        const data =  {
            "createdAt": "2024-08-19T06:06:39.166Z",
            "active": true,
            "id": service?.id,
            "name": service?.name,
            "price": service?.price * parseInt(kg),
            "description": service?.description,
            "extraInfo": service?.extraInfo,
            "imageUrl": service?.imageUrl,
            "kg": kg
        }

        if (cartArrayRegular?.length > 0) {
            var isAlreadyAdded = cartArrayRegular.filter(item => item.id == data.id);
            if (isAlreadyAdded?.length > 0) {
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "14px" }}>
                    Item already added to cart!
                </p>
                );
                return
            }
            var spreadItem = [...cartArrayRegular, data];
            setcartArrayRegular(spreadItem)
            updateCartSumRegular(spreadItem);
            localStorage.setItem(cartKey, JSON.stringify(spreadItem));
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Added to Cart</p>);
        }
        else {
            var newitem = [data]
            setcartArrayRegular(newitem)
            updateCartSumRegular(newitem);
            localStorage.setItem(cartKey, JSON.stringify(newitem));
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Added to Cart</p>);
        }
    }
    const globalAddToCart = (service, serviceDate, serviceTIme) => {
        const data = {
            "id": service?.id,
            "planType": service?.planType,
            "name": service?.name,
            "serviceName": service?.serviceName,
            "serviceImage": service?.serviceImage,
            "durationInHours": service?.durationInHours,
            "price": service?.price,
            "serviceCategoryLocationId": service?.serviceCategoryLocationId,
            "planFeatures": [],
            "serviceDate": serviceDate,
            "serviceTime": serviceTIme
        }
        if (cartArray?.length > 0) {
            var isAlreadyAdded = cartArray.filter(item => item.id == data.id);
            if (isAlreadyAdded?.length > 0) {
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "14px" }}>
                   Item already added to cart!
                </p>
                );
                return
            }
            var spreadItem = [...cartArray, data];
            setCartArray(spreadItem)
            updateCartSum(spreadItem);
            localStorage.setItem(KOBO_CART, JSON.stringify(spreadItem));
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Added to Cart</p>);
        }
        else {
            var newitem = [data]
            setCartArray(newitem)
            updateCartSum(newitem);
            localStorage.setItem(KOBO_CART, JSON.stringify(newitem));
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Added to Cart</p>);
        }
    }

    const removeFromCart = (data) => {
        const updatedCart = cartArray.filter(item => item.id !== data.id);
        setCartArray(updatedCart);
        updateCartSum(updatedCart);
        localStorage.setItem(KOBO_CART, JSON.stringify(updatedCart));
    };
    const removeFromCartRegular = (data) => {
        const updatedCart = cartArrayRegular.filter(item => item.id !== data.id);
        setcartArrayRegular(updatedCart);
        updateCartSumRegular(updatedCart);
        localStorage.setItem(KOBO_CART_FISH_SHOP, JSON.stringify(updatedCart));
    };
    const handleLocationPropmpt = () => {
        
    }
    const updateCartSum = (data) => {
        
        if (data?.length > 0){
            setcartTotal(data?.reduce((sum, item) => sum + item?.price, 0))
            return;
        }
        setcartTotal(Array.isArray(data) ? data.reduce((sum, item) => sum + (item?.price || 0), 0) : 0);
        localStorage.removeItem(KOBO_SERVICE_LOCATION);
        localStorage.removeItem(KOBO_CART);
    }
    const updateCartSumRegular = (data) => {

        if (data?.length > 0) {
            setcartTotalRegular(data?.reduce((sum, item) => sum + item?.price, 0))
            return;
        }
        setcartTotalRegular(Array.isArray(data) ? data.reduce((sum, item) => sum + (item?.price || 0), 0) : 0);
        localStorage.removeItem(KOBO_CART_FISH_SHOP);
    }
    const resolveNavDisplay = (data) => {
       
        if(data?.includes("userindex")){
            alert("if")
            setshowSideNav(false)

        }
        else{
            alert("else")
            // setshowSideNav(true)
            $(`#${'site-main-mobile-menu'}`).animate({ width: 400 }, 400);
        }
    }
    useEffect(() => {
        if(userPayload != null){
            fetchUserDetails()
            fetchUserUserSubscription()
        }
    }, [cartTotal])
    return (
 <>
           
            <UserContext.Provider value={{
                cartArray,
                setCartArray,
                globalAddToCart,
                userPayload,
                removeFromCart,
                firstName,
                lastName,
                cartTotal,
                logisticsPrice,
                userProfile,
                userPayload,
                userSubscription,
                subscriptionStatus,
                resolveNavDisplay,
                showSideNav,
                cartTotalRegular,
                cartArrayRegular,
                globalAddToCartRegular,
                removeFromCartRegular
            }}>
                {/* <ToastContainer
                    position="top-center"
                    theme="colored"
                /> */}
                {children}
            </UserContext.Provider>
 </>
    );

};

export { UserProvider, UserContext };