import { Link, useLocation } from 'react-router-dom';
import kobologo from '../../assets/images/kobologo.jpeg';
import koboL1 from '../../assets/images/koboL1.png';
import koboL2 from '../../assets/images/koboL2.png';
import { useEffect, useState, useContext } from "react";
import { UserContext } from '../../context/userProvider';
import $ from "jquery"
import { Avatar, Badge } from 'antd';
import { activeSessionCheck } from '../../util/auth';
import { enquireScreen } from 'enquire-js';


const RealEstateHeader = () => {
    const userContext = useContext(UserContext);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    let url = location?.pathname;

    const toggleMobileMenuVisibility = () => {
        // const url = location.pathname; // Now this will always have the latest path
        //const url = window.location.pathname;
        // console.log(path)
        // console.log(url);
        if (url.includes("userindex")) {
            $("#kobo-side-menu").toggle('slow');
        } else {
            $(`#${'site-main-mobile-menu'}`).animate({ width: 400 }, 400);
        }
    };
    const hotNavigate = () => {
        window.location.href = "/userindex"
    }
    const dashNav = () => {
        // const url = location.pathname; // Now this will always have the latest path
        //const url = window.location.pathname;
        // console.log(path)

        $("#kobo-side-menu").toggle('slow');

    };

    const closeMobileMenu = () => {
        const menuElement = document.getElementById('site-main-mobile-menu');
        menuElement.style.width = '0px';
    }
    useEffect(() => {
        //url = location?.pathname;
        enquireScreen((b) => {
            setIsMobile(b)
        });
    }, [])


    return (
        <>

            {/* Header Section Start */}
            <div className="header-section header-shadow sticky-header section">
                <div className="header-inner" style={{ position: "fixed", width: "100%", zIndex: 99 }}>
                    <div className="container position-relative">
                        <div className="row justify-content-between align-items-center">
                            {/* Header Logo Start */}
                            <div className="col-xl-3 col-auto">
                                <div className="header-logo">
                                    <Link to={"/"}>
                                        <span className='outfit-700 logo-nav-text' style={{ color: "#076fcc", display: "inline-flex", alignItems: "center" }}>
                                            <img className="kobo-logo-nav" src={koboL2} alt="Kobokist Logo" />

                                            Kobo<span style={{ color: "#ff9f2f" }}>Kist</span></span>
                                        {/* <img className="dark-logo kobo-logo-nav" src={kobologo} alt="Kobokist Logo" /> */}
                                        {/* <img className="light-logo kobo-logo-nav" src={kobologo} alt="Kobokist Logo" /> */}
                                    </Link>
                                </div>
                            </div>
                            {/* Header Logo End */}
                            {/* Header Main Menu Start */}
                            <div className="col d-none d-xl-block position-static">
                                <nav className="site-main-menu menu-hover-1">
                                    <ul>
                                        <li className="has-children position-static">
                                            <Link to={"/"}><span className="menu-text">Home</span></Link>
                                            {/* <span className="menu-toggle"><i className="far fa-angle-down" /></span> */}
                                            {/* <ul className="mega-menu">
                                                <li>
                                                    <ul>
                                                        <li><a href="index.html"><span className="menu-text">KoboKist Education <span className="badge">Hot</span></span></a></li>
                                                        <li><a href="index-2.html"><span className="menu-text">Course Portal</span></a></li>
                                                        <li><a href="index-3.html"><span className="menu-text">Distant Learning <span className="badge">Hot</span></span></a></li>
                                                        <li><a href="index-4.html"><span className="menu-text">Multimedia Pedagogy</span></a></li>
                                                        <li><a href="index-5.html"><span className="menu-text">Modern Schooling</span></a></li>
                                                        <li><a href="index-6.html"><span className="menu-text">Remote Training</span></a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li><a href="index-7.html"><span className="menu-text">Health Coaching</span></a></li>
                                                        <li><a href="index-8.html"><span className="menu-text">Gym Coaching</span></a></li>
                                                        <li><a href="index-9.html"><span className="menu-text">Business</span></a></li>
                                                        <li><a href="index-10.html"><span className="menu-text">Artist <span className="badge primary">New</span></span></a></li>
                                                        <li><a href="index-11.html"><span className="menu-text">Kitchen Coach <span className="badge primary">New</span></span></a></li>
                                                        <li><a href="index-12.html"><span className="menu-text">Motivation <span className="badge primary">New</span></span></a></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item-50">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/images/menu/mega-menu.jpg" alt="menu-image" /></a></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </li>
                                        <li className="has-children">
                                            <Link to="/properties"><span className="menu-text">Properties</span></Link>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        </li>
                                        <li className="has-children">
                                            <Link to="#"><span className="menu-text">About Us</span></Link>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        </li>


                                        {/* <li className="has-children">
                                            <Link to="/subscription-plans"><span className="menu-text">Subscriptions</span></Link>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        </li>
                                        */}

                                    </ul>
                                </nav>
                            </div>
                            {/* Header Main Menu End */}
                            {/* Header Right Start */}
                            <div className="col-xl-3 col-auto">
                                <div className="header-right">
                                    <div className="inner">
                                        {/* Header Login Start */}
                                      
                                       

                                       
                                        <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">

                                            <button className="toggle" onClick={() => toggleMobileMenuVisibility()}>
                                                <i className="icon-top" />
                                                <i className="icon-middle" />
                                                <i className="icon-bottom" />
                                            </button>
                                            {/* } */}

                                        </div>
                                        {/* Header Mobile Menu Toggle End */}
                                    </div>
                                </div>
                            </div>
                            {/* Header Right End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Header Section End */}
            <div id="site-main-mobile-menu" className="site-main-mobile-menu">
                <div className="site-main-mobile-menu-inner">
                    <div className="mobile-menu-header">
                        <div className="mobile-menu-logo">
                            <a href="#"><img style={{ width: "66px" }} src={koboL2} alt="" /></a>
                        </div>
                        <div className="mobile-menu-close">
                            <button className="toggle" onClick={() => closeMobileMenu()}>
                                <i className="icon-top" />
                                <i className="icon-bottom" />
                            </button>
                        </div>
                    </div>
                    <div className="mobile-menu-content" style={{ background: "#223c70" }}>
                        <nav className="kobo-site-mobile-menu">
                            <ul>
                                <li className="has-children position-static">
                                    <Link to="/"><span className="menu-text">Home</span></Link>
                                    {/* <span className="menu-toggle"><i className="far fa-angle-down" /></span> */}
                                </li>
                                {/* {activeSessionCheck() ?
                                    <li className="has-children position-static">
                                        <a onClick={() => hotNavigate()} href="#"><span className="menu-text">Dashboard</span></a>
                                    </li> :
                                    <li className="has-children position-static">
                                        <Link to="/login-register"><span className="menu-text">Login/Register</span></Link>
                                    </li>
                                } */}
                                <li className="has-children position-static">
                                    <Link to="/properties"><span className="menu-text">Properties</span></Link>
                                </li>
                                <li className="has-children position-static">
                                    <Link to=""><span className="menu-text">Our Mission</span></Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RealEstateHeader;