import { Link } from 'react-router-dom';
import kobologo from '../../assets/images/kobologo.jpeg';
import koboL1 from '../../assets/images/koboL1.png';
import koboL2 from '../../assets/images/koboL2.png';
import { enquireScreen } from "enquire-js";
import { useEffect, useState, useContext } from "react";
import { UserContext } from '../../context/userProvider';
import { activeLocationCheck, CATFISH_ROUTE_INDEX } from '../../util/helpers';
import { activeSessionCheck } from '../../util/auth';



const CatFishHeader = () => {
    const [isMobile, setIsMobile] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        enquireScreen((b) => {
            setIsMobile(b)
        });

    }, [])
    return (
        <>
            {/* <div className="container-fluid fixed-top"> */}
                <div className="container-fluid ">
                <div className="container topbar bg-primary d-lg-block">
                    <div className="d-flex justify-content-between">
                        <div className="top-info ps-2">
                           {!isMobile ? 
                                <small className="me-3"><i className="fa fa-map-marker me-2 text-white" /> <a href="#" className="text-white">47, Along Liberty Road, Oke-Ado, Ibadan, Oyo state</a></small>
                                : null   
                        }
                            <small className="me-3">&nbsp; &nbsp;<i className="fa fa-envelope me-2 text-white" />&nbsp; <a href="#" className="text-white">kobokistltd@gmail.com</a></small>
                        </div>
                       {!isMobile ? 
                            <div className="top-link pe-2">
                                <Link to="/privacy-policy" className="text-white"><small className="text-white mx-2">Privacy Policy</small>/</Link>
                                <Link to="/terms-of-use" className="text-white"><small className="text-white mx-2">Terms of Use</small></Link>
                                {/* <a href="#" className="text-white"><small className="text-white ms-2">Sales and Refunds</small></a> */}
                            </div> : null   
                    }
                    </div>
                </div>
                <div className="container px-0">
                    <nav className="navbar navbar-light bg-white navbar-expand-xl">
                        <Link to="/" className="navbar-brand">
                            {/* <h1 className="text-primary display-6">Fruitables</h1> */}
                            {/* <Link to={"/"}> */}
                                <span className='adlam-display-regular-700 logo-nav-text mt-2'>
                                <img className="kobo-logo-n mt-2" src={koboL1} alt="Kobokist Logo" />

                                    {/* Kobo<span style={{ color: "#dd5809" }}>Kist</span> */}
                                    </span>
                                {/* <img className="dark-logo kobo-logo-nav" src={kobologo} alt="Kobokist Logo" /> */}
                                {/* <img className="light-logo kobo-logo-nav" src={kobologo} alt="Kobokist Logo" /> */}
                            {/* </Link> */}
                        </Link>
                        {isMobile && <Link to="/fish-shop-cart" style={{ position: "absolute", right: "80px", top: "15px" }}>
                            <i className="fa fa-shopping-bag fa-2x" />
                            <span className="position-absolute text-dark bg-secondary rounded-circle d-flex align-items-center justify-content-center  px-1" style={{ top: '-5px', left: '15px', height: '20px', minWidth: '20px' }}>{userContext?.cartArrayRegular?.length > 0 ? userContext?.cartArrayRegular?.length : 0}</span>
                        </Link>}
                        <button style={{position:"absolute", right:"10px", top:"15px"}} className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="fa fa-bars text-primary" />
                        </button>
                        <div style={isMobile? {marginTop:"5em", zIndex:"99"} : null} className="collapse navbar-collapse bg-white" id="navbarCollapse">
                            <div className="navbar-nav mx-auto">
                                <Link to="/catfish-shop" className="nav-item nav-link active">Home</Link>
                                <Link to="/catfish-shop-list" className="nav-item nav-link">Shop</Link>
                                {/* <a href="shop-detail.html" className="nav-item nav-link">Shop Detail</a> */}
                                {/* <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                    <div className="dropdown-menu m-0 bg-secondary rounded-0">
                                        <a href="cart.html" className="dropdown-item">Cart</a>
                                        <a href="chackout.html" className="dropdown-item">Chackout</a>
                                        <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                                        <a href="404.html" className="dropdown-item">404 Page</a>
                                    </div>
                                </div> */}
                                <a href="#" className="nav-item nav-link">Contact</a>
                            </div>
                            <div className="d-flex m-3 me-0" style={{gap:"12px"}}>
                                <button className="btn-search btn border border-secondary btn-md-square rounded-circle bg-white me-4" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fas fa-search text-primary" /></button>
                                {!isMobile && <Link to="/fish-shop-cart" className="position-relative me-4 my-auto">
                                    <i className="fa fa-shopping-bag fa-2x" />
                                    <span className="position-absolute text-dark bg-secondary rounded-circle d-flex align-items-center justify-content-center  px-1" style={{ top: '-5px', left: '15px', height: '20px', minWidth: '20px' }}>{userContext?.cartArrayRegular?.length > 0 ? userContext?.cartArrayRegular?.length : 0}</span>
                                </Link>}
                                {activeSessionCheck() ? 
                                    <Link to={"/fish-shop-userindex"} className="my-auto"
                                        state={{
                                            some: {
                                                displayProps: 'active',
                                            }
                                        }}
                                    >
                                        <i className="fas fa-user fa-2x" />
                                    </Link>
                                    :
                                    <Link to={"/login-register"} className="my-auto"
                                        state={{
                                            some: {
                                                displayProps: CATFISH_ROUTE_INDEX,
                                            }
                                        }}
                                    >
                                        <i className="fas fa-user fa-2x" />
                                    </Link>    
                            }
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}

export default CatFishHeader;