import React from 'react';
import imgplaceholder from "../../assets/images/image_placeholder.png"

const IDCard = ({person}) => {
   
 
    return (
        <div
            style={{
                maxWidth: '400px',
                margin: '20px auto',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                backgroundColor: '#f7f7f7',
                textAlign: 'center'
            }}
            className="vtm-id-card"
        >
            <div style={{ marginBottom: '15px' }}>
                <img
                    src={imgplaceholder}
                    alt="Avatar"
                    style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: '2px solid #007bff'
                    }}
                />
            </div>
            <h3 style={{ margin: '0 0 10px', fontSize:"21px", color: '#007bff', width: "240px" }}>{person?.name?.length > 22 ? person?.name.substring(0,22) + "..." : person?.name}</h3>
            <p style={{ margin: '0 0 5px', fontWeight: 'bold' }}>{person.service}</p>
            <p style={{ margin: '0 0 15px', fontSize: '14px', color: '#555' }}>Service Code: {person?.serviceAllocationCode}</p>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '14px',
                    color: '#555',
                    marginBottom: '15px'
                }}
            >
                <div>
                    <p style={{ margin: '0' }}>Designation:</p>
                    <p style={{ margin: '0', fontWeight: 'bold' }}>{person?.designation}</p>
                </div>
                <div>
                    <p style={{ margin: '0' }}>Contact:</p>
                    <p style={{ margin: '0', fontWeight: 'bold' }}>{person.contact}</p>
                </div>
            </div>

            <p style={{ fontSize: '12px', color: '#999' }}>
                &nbsp;
            </p>
            <p style={{ fontSize: '12px', color: '#999' }}>
                Verify the service code upon arrival.
            </p>
           
        </div>
    );
};

// Sample person data



export default IDCard
