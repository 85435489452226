import React, { useEffect, useContext, useState } from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import { Link } from "react-router-dom";
import $ from "jquery"
import EmptyCart from "./empty-cart";
import { UserContext } from "../../context/userProvider";
import { nairaFormat } from "../../util/helpers";
import { baseContentURL } from "../../util/endpoint";


const OurMotivation = () => {
    const userContext = useContext(UserContext);


    useEffect(() => {

        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return (
        <div className=" section section-padding-bottom mt-5">
            <div className="our-motivation container">
                <h1>Our Motivation</h1>
                <p>
                    At Kobokist Limited, our motivation is driven by a deep commitment to enriching lives through the diverse range of services we offer. We believe in the power of convenience and accessibility, which is why we’ve created a platform that caters to various aspects of daily life, from finding the perfect home to providing essential services and connecting people to the best event venues.
                </p>
                <p>
                    Our passion for excellence inspires us to go above and beyond in everything we do. Whether it’s delivering fresh and smoked catfish, ensuring your home is clean and comfortable, or helping you create memorable events, we strive to exceed your expectations every step of the way.
                </p>
                <p>
                    We are motivated by the belief that our work makes a difference in the lives of our customers. We take pride in offering services that not only meet the needs of today but also anticipate the demands of tomorrow. Our goal is to build lasting relationships with our customers, grounded in trust, reliability, and exceptional service.
                </p>
                <p>
                    At the heart of Kobokist Limited is a desire to innovate and continually improve. We are inspired by the challenge of finding new and better ways to serve you, and we are committed to maintaining the highest standards of quality in everything we do.
                </p>
                <p>
                    Every project we undertake, every service we offer, is fueled by our dedication to making life simpler, more enjoyable, and more fulfilling for our customers. This is what drives us—this is our motivation.
                </p>
            </div>


        </div>

    )
}
export default withLayout(UserLayout)(OurMotivation)