import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import justclean from "../../assets/images/clean.jpg"
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { ToastContainer, toast } from 'react-toastify';
import { states } from "../../util/statestore";
import { FillSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Endpoint, { baseContentURL } from "../../util/endpoint";
import { activeLocationCheck, addToCart, cacheServiceLocation, KOBO_SERVICE_LOCATION, SUBSCRIPTION_ROUTE_PROPS } from "../../util/helpers";
import { UserContext } from "../../context/userProvider";
require("../../assets/css/pricing-plan.css")



const ServiceDetails = (props) => {
    const location = useLocation();
    const { routeProps } = location.state?.some || {};
    const { serviceitem, locationName } = location.state?.some || {};
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [incomingProps, setIncomingProps] = useState(props?.location?.state);
    const [pricingArr, setpricingArr] = useState([]);
    const userContext = useContext(UserContext);
    const [selectedStateValue, setselectedStateValue] = useState('')
    const [selectedStateName, setselectedStateName] = useState('')
    const [selectedLgaValue, setselectedLgaValue] = useState('')
    const [selectedLgaName, setselectedLgaName] = useState('')
    const [lgaArr, setLgaArr] = useState([])
    const [serviceCategoryArr, setserviceCategoryArr] = useState([])
    const [fetching, setfetching] = useState(true)
    const [selectedPlan, setselectedPlan] = useState({})
    const [serviceDate, setserviceDate] = useState("")
    const [serviceTime, setserviceTime] = useState("")
    const [cachedLocation, setcachedLocation] = useState(JSON.parse(localStorage.getItem(KOBO_SERVICE_LOCATION)));

    const [spinning, setSpinning] = useState(false);
    const [percent, setPercent] = useState(0);

    const showLoader = () => {
        setSpinning(true);
    };

    const handleRequestServiceAsync = () => {
      
        if (serviceDate == null || serviceDate == "" || serviceDate == null || serviceDate == ""){
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                Select prefered date/time for service before continuing
            </p>)
            return;
        }
        setSpinning(true);
        setIsModalOpen(false);
        const payload = {
            "serviceDate": serviceDate,
            "serviceTime": serviceTime,
            "userId": userContext?.userPayload?.userId,
            "serviceCategoryId": serviceitem?.id
        }
        Endpoint.requestServiceViaSubscription(payload)
        .then((res) => {
            console.log(res?.data, "reqqq")
            setSpinning(false);
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                  Your service request was successful
                </p>)
            setTimeout(() => {
                window.location.href = "/userindex"
            }, 5000);
        })
        .catch((err) => {
            setSpinning(false);
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                {err?.data?.message}
            </p>, 
            {
                autoClose: 10000
            })
            console.log(err)
        })
    }
    const handleStateLocationSelect = (e) => {
        setpricingArr([])
        setLgaArr([])

        const selectedIndex = e.target.selectedIndex;
        const value = e.target.value;
        const text = e.target.options[selectedIndex].text;
        setselectedStateValue(value);
        setselectedStateName(text)

        setselectedLgaValue('');
        setselectedLgaName('')

        let filter = states?.filter(x => x.name == value);
        if (filter) {
            setLgaArr(filter[0].lgas)
        }
    }

    const handleLGALocationSelect = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const value = e.target.value;
        const text = e.target.options[selectedIndex].text;
        setselectedLgaValue(value);
        setselectedLgaName(text)
        fetchServicePlans(text)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleSelectPlan = (data) => {
        var filter = pricingArr.filter((x) => x.id == data);
        console.log(filter)
        setselectedPlan(filter[0])
    }
    const handleAddToCart = (item) => {
        // console.log(activeLocationCheck())
        // return activeLocationCheck()
        //addToCart(item)
        // if (cachedLocation != null && cachedLocation?.lga != selectedLgaName){
        //     toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
        //         Your current cart is set to <span className="outfit-500">{cachedLocation?.state}, {cachedLocation?.lga}</span>. All items in cart must be for this location. To change the location, please clear your cart and start a new request.
        //         </p>);
        //         return
        // }
        userContext.globalAddToCart(selectedPlan, serviceDate, serviceTime)
        setIsModalOpen(false)
        // toast.success(<p className="outfit-500 text-white" style={{fontSize:"15px"}}>Added to Cart</p>);
        if (cachedLocation != null && cachedLocation?.lga != null){

        }
        else{
            cacheServiceLocation(selectedStateName, selectedLgaName);
        }
        
        // activeLocationCheck()
    }
    const closeLocationPropmpt = () => {
            $("#locationAlert").hide()
            $("#addAlert").fadeIn("slow")
    }
    const handleLocationPropmpt = () => {
        if (cachedLocation != null && cachedLocation?.lga != selectedLgaName) {
            $("#addAlert").hide()
            $("#locationAlert").fadeIn("slow")
        //     toast.error(<p className="outfit-500 text-white" style={{ fontSize: "14px" }}>
        //         Your current cart is set to <span className="outfit-500">{cachedLocation?.state}, {cachedLocation?.lga}</span>. All items in cart must be for this location. To change the location, please clear your cart and start a new request.
        //     </p>,
            
        //         {
        //             autoClose: 18000
        //         }    
        // );
        //     return
        }
    }
    const fetchServicePlans = (data) => {
        setpricingArr([])

        Endpoint.getServicePlansByLocations(data || cachedLocation?.lga, serviceitem?.id)
            .then((res) => {
                console.log(res?.data)
                setpricingArr(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // const fetchServicePlans = () => {
    //     Endpoint.getServicePlansByLocations(locationName, serviceitem?.id)
    //     .then((res) => {
    //         console.log(res?.data)
    //         setpricingArr(res?.data)
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })
    // }
    const handleServiceDate = (e) => {
        console.log(e.target.value)
        setserviceDate(e.target.value)
    }
    const handleServiceTime = (e) => {
        console.log(e.target.value)
        setserviceTime(e.target.value)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(routeProps, "routeProps")
        console.log(serviceitem, "serviceitem")
        fetchServicePlans()
        // activeLocationCheck()
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 1000);

        enquireScreen((b) => {
            setIsMobile(b)
        });
    }, [cachedLocation]);
   
    return (
        <div className="">
            <Spin 
            spinning={spinning} 
            indicator={<LoadingOutlined spin />} size="large"
            className="text-white"
            fullscreen />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                theme="colored"
                />
            {/* <Modal footer={[]} title={<h3 className="outfit-500" style={{ color:"#464444"}}>Service Plans</h3>} width={!isMobile ? 1000 : null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
               
                <div style={{marginTop:"2em"}}>
                    <main className="main">
                        
                        <div className="cards" id="cards">
                           {pricingArr && pricingArr?.map((x, i) => {
                            return(
                                <div className="price-card" id="price-card">
                                    <div className="price-card--top">
                                        <h3>{x?.name}</h3>
                                        <h5>₦ <span>{x?.price}</span><span className="price-text">/{x?.durationInHours}</span></h5>
                                        <hr />
                                    </div>
                                    <div className="price-card--bottom">
                                        <ul>
                                            {x?.planFeatures && x?.planFeatures?.map((p, t) => {
                                                return(
                                                    <li className="outfit-400">
                                                        <i className="fa fa-check" />{p}
                                                    </li>
                                                )
                                            })}
                                           
                                           
                                        </ul>
                                        <button type="button" onClick={() => handleAddToCart(x)} className="btn" id="btn">Try it</button>
                                    </div>
                                </div>
                            )
                           })}
                           
                        </div>
                    </main>


                </div>

            </Modal> */}
           
            <Modal footer={[]}  open={isModalOpen} onOk={handleOk} closeIcon={false} onCancel={handleCancel}>

                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="card">
                            {/* <div className="card-header bg-primary text-white text-center" style={{ background:"#a1a1a1"}}>
                                <h4 className="text-white">Select and Add Service to Cart</h4>
                                </div> */}
                                {routeProps == SUBSCRIPTION_ROUTE_PROPS ? 
                                <div class="alert alert-info outfit-400" role="alert" id="addAlert">
                                    Requesting this service will count towards your subscription plan. If you prefer to pay separately and not use your subscription credits, please click on ‘Find a Professional’ in the top navigation. Otherwise, continue with this request.
                                </div> : 
                                <div class="alert alert-info outfit-400" role="alert" id="addAlert">
                                    Select and Add Service to Cart
                                </div>
                            }
                            
                            
                            
                            <div className="alert alert-danger" role="alert" id="locationAlert" style={{display:"none"}}>
                                <i onClick={() => closeLocationPropmpt()} type="button" className="fa fa-times btn-close" style={{fontSize:"16px", float:"right"}}/>
                               <div className="outfit-400">
                                   
                                    Your current cart is set to <span className="outfit-500">{cachedLocation?.state}, {cachedLocation?.lga}</span>. All items in cart must be for this location. To change the location, please clear your cart and start a new request.
                               </div>
                            </div>
                                <div className="card-body">
                                    <form>
                             {routeProps == SUBSCRIPTION_ROUTE_PROPS ? 
                            <></>:
                                        !activeLocationCheck() ?
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="serviceType" style={{ fontSize: "14px" }} className="form-label">Select State</label>
                                            <select onChange={(e) => handleStateLocationSelect(e)} className="form-select outfit-400" id="serviceType" required style={{ border: "1px solid #d6d6d6", fontSize: "14px" }}>
                                                <option selected disabled>Choose a state...</option>
                                                {states.map((x, i) => {
                                                    return (
                                                        <option value={x.name}>{x.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="serviceType" style={{ fontSize: "14px" }} className="form-label">Select Lga</label>
                                            <select onChange={(e) => handleLGALocationSelect(e)} className="form-select outfit-400" id="serviceType" required style={{ border: "1px solid #d6d6d6", fontSize: "14px" }}>
                                                <option selected disabled>Choose a lga...</option>
                                                {lgaArr && lgaArr?.map((x, i) => {
                                                    return (
                                                        <option value={x}>{x}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </>
                                    :

                                    <div className="row">
                                        <div className="col-8">
                                            <div className="mb-4">
                                                <p className="loca-show">State: <span className="outfit-500">{cachedLocation?.state == null ? selectedStateName : cachedLocation?.state}</span></p>
                                                <p className="loca-show" style={{ marginTop: "-12px" }}>Lga: <span className="outfit-500">{cachedLocation?.lga == null ? selectedLgaName : cachedLocation?.lga}</span></p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right" style={{ marginTop: "-42px" }}>
                                            <p onClick={() => handleLocationPropmpt()} className="change-loca">Change location <i className="fa fa-location" /></p>
                                        </div>
                                    </div>
                                    }
                            

                                   {routeProps == SUBSCRIPTION_ROUTE_PROPS ? 
                                <></>
                                :
                                        <div className="mb-3">
                                            <label htmlFor="serviceType" className="form-label " style={{ fontSize: "14px" }}>Select Service Plan</label>
                                            <select onChange={(e) => handleSelectPlan(e.target.value)} className="form-select outfit-400" id="serviceType" required style={{ border: "1px solid #d6d6d6" }}>
                                                <option style={{ fontSize: "14px" }} selected disabled>Choose a plan...</option>
                                                {pricingArr && pricingArr.map((x) => {
                                                    return (
                                                        <option value={x?.id}>{x?.name}-{x?.price} | {x?.durationInHours}</option>
                                                    )
                                                })}

                                                {/* <option value="nanny">Nanny</option>
                                            <option value="chef">Chef</option>
                                            <option value="plumbing">Plumbing</option>
                                            <option value="electrician">Electrician</option> */}
                                            </select>
                                        </div>   
                                }
                                        <div className="mb-3">
                                        <label htmlFor="serviceDate" style={{ fontSize: "14px" }} className="form-label">Preferred Date</label>
                                        <input onChange={(e) => handleServiceDate(e)} style={{ fontSize: "14px" }} type="date" className="form-control" id="serviceDate" required />
                                        </div>
                                        <div className="mb-3">
                                        <label htmlFor="serviceTime" style={{ fontSize: "14px" }} className="form-label">Preferred Time</label>
                                        <input onChange={(e) => handleServiceTime(e)} style={{fontSize:"14px"}} type="time" className="form-control" id="serviceTime" required />
                                        </div>
                                        <div className="text-center">
                                       {routeProps == SUBSCRIPTION_ROUTE_PROPS ? 
                                           <>
                                                <button onClick={() => handleRequestServiceAsync()} type="button" className="btn btn-primary btn-sm" style={{ height: "3em" }}>Confirm service request</button>
                                                {/* <Button onClick={showLoader}>Show fullscreen</Button> */}
                                                
                                           </>
                                            :
                                            <button onClick={() => handleAddToCart()} type="button" className="btn btn-primary btn-sm" style={{ height: "3em" }}>Add to Cart</button>   
                                    }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>
            <div>
                <div>
                    {/* Page Title Section Start */}
                    <div className="page-title-section section">
                        <div className="page-title mt-3 mb-4">
                            <div className="container">
                                <h1 className="title" style={{fontWeight:"700"}}>Service Details</h1>
                            </div>
                        </div>
                        <div className="page-breadcrumb mt-4 mb-4">
                            <div className="container">
                                <ul className="breadcrumb">
                                    <li><a className="outfit-500">Home/ </a></li>
                                    <li className="current outfit-500"> Service Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Page Title Section End */}
                    {/* Product Details Section Start */}
                    <div className="section section-padding-bottom-80 mt-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="single-product-image shadow">
                                        <img className="" src={baseContentURL + serviceitem?.imageUrl} alt="book" style={{ 
                                            border:"15px solid #0a41c11a", 
                                            // borderRadius:"25px"
                                            }}/>
                                        <div className="product-badges">
                                            <span className="onsale">{serviceitem?.badgeText}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="single-product-content">
                                        <h3 className="title mt-4">Hire a {serviceitem?.name}</h3>
                                        <span className="prices">
                                            {/* <span className="outfit-400"> for as low as </span> */}
                                            {serviceitem?.serviceCategoryType == 1 && <span className="price-new outfit-400">{serviceitem?.subCaption}</span>}
                                            {/* <span className="price-old outfit-400">{serviceitem?.subCaption}</span> */}
                                        </span>
                                        {/* <div className="product-ratings">
                                            <span className="star-rating">
                                                <span className="rating-active">ratings</span>
                                            </span>
                                            <a href="#reviews" className="review-link">(<span className="count">2</span> customer reviews)</a>
                                        </div> */}
                                        <div className="product-description">
                                            <p>

                                                {serviceitem?.description}
                                            </p>
                                        </div>
                                        {/* <div className="quantity-button-wrapper">
                                            <div className="quantity">
                                                <label>Quantity</label>
                                                <input type="number" defaultValue={1} min={1} />
                                            </div>
                                            <p className="stock in-stock"><i className="fa fa-check-circle" style={{ color:"#38cb89"}}/> 19 service person available</p>
                                        </div> */}
                                        {/* {serviceitem?.serviceCategoryType == 2 && <span className="price-new outfit-400">{serviceitem?.subCaption}</span>} */}

                                       {serviceitem?.serviceCategoryType == 1 && 
                                            <div className="product-action">
                                                {routeProps == SUBSCRIPTION_ROUTE_PROPS ?
                                                    <button className="btn btn-primary btn-hover-secondary" onClick={showModal}>
                                                        Request this service &nbsp;<i className="fa fa-hand-point-right" />
                                                    </button>
                                                    :
                                                    <button className="btn btn-primary btn-hover-secondary" onClick={showModal}>Add to cart <i className="fa fa-shopping-cart" /></button>
                                                }

                                                <button className="btn btn-wishlist btn-box"><i className="fa fa-heart" /></button>
                                                {/* <button className="btn btn-compare btn-box"><i className="fa fa-random" /></button> */}
                                            </div>
                                       }
                                        {serviceitem?.serviceCategoryType == 2 &&
                                            <div className="product-action">
                                                <a href="tel:+2348056096611" className="btn btn-primary btn-hover-secondary text-white">Call us &nbsp;<i className="fa fa-phone" /></a>
                                                <button className="btn btn-wishlist btn-box"><i className="fa fa-heart" /></button>
                                            </div>
                                        }
                                        <div className="product-meta">
                                            {/* <div className="meta-item">
                                                <h6>Sku:</h6>
                                                <div className="meta-content">
                                                    <span className="sku">MCB-001</span>
                                                </div>
                                            </div> */}
                                            <div className="meta-item">
                                                <h6>Category:</h6>
                                                <div className="meta-content">
                                                    {serviceitem?.serviceCategoryType == 1 && <a href="#" rel="tag">{serviceitem?.name}</a>}
                                                    {serviceitem?.serviceCategoryType == 2 && <a href="#" rel="tag">Services On-The-Go</a>}
                                                </div>
                                            </div>
                                           
                                        </div>
                                        {/* <div className="entry-product-share">
                                            <h6>Share:</h6>
                                            <div className="author-social-networks">
                                                <div className="inner">
                                                    <a className="hint--bounce hint--top hint--primary" aria-label="Facebook"  target="_blank">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                    <a className="hint--bounce hint--top hint--primary" aria-label="Twitter"  target="_blank">
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                    <a className="hint--bounce hint--top hint--primary" aria-label="Linkedin"  target="_blank">
                                                        <i className="fab fa-linkedin" />
                                                    </a>
                                                    
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Product Details Section End */}
                    {/* Single Products Information Section Start */}
                    <div className="single-product-information section section-padding-bottom">
                        <div className="container">
                            {/* <div className="row">
                                <div className="col-12">
                                    <ul className="nav product-info-tab-list justify-content-center">
                                        <li><a className="active" data-toggle="tab" href="#tab-description">Description</a></li>
                                        <li><a data-toggle="tab" href="#tab-reviews">Reviews (2)</a></li>
                                    </ul>
                                    <div className="tab-content product-infor-tab-content">
                                        <div className="tab-pane fade show active" id="tab-description">
                                            <div className="row">
                                                <div className="offset-lg-2 col-lg-8">
                                                    <div className="description-list">
                                                        <ul>
                                                            <li>-</li>
                                                            <li>-</li>
                                                           
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default withLayout(UserLayout)(ServiceDetails);