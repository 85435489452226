export const KOBO_CART = 'KOBO_CART'
export const KOBO_CART_FISH_SHOP = 'KOBO_CART_FISH_SHOP'
export const KOBO_SERVICE_LOCATION = 'KOBO_SERVICE_LOCATION'
export const SUBSCRIPTION_ROUTE_PROPS = 'SUBSCRIPTION_ROUTE_PROPS'
export const CATFISH_ROUTE_INDEX = '/fish-shop-userindex'
export const PAYMENT_ROUTE_KEY = 'PAYMENT_ROUTE_KEY'
export const CAT_FISH_SHOP_PAYMENT = '/fish-shop-userindex'
export const INSTALLED_APP = 'INSTALLED_APP'


export const addToCart = (data) => {
    var arrList = JSON.parse(localStorage.getItem(KOBO_CART));
    if(arrList?.length > 0){
        var spreadItem = [arrList, data];
        localStorage.setItem(KOBO_CART, JSON.stringify(spreadItem));
        return;
    }
    var newItem = [data];
    localStorage.setItem(KOBO_CART, JSON.stringify(newItem));
}
export function clearCart() {
    localStorage.removeItem(KOBO_CART);
    localStorage.removeItem(KOBO_SERVICE_LOCATION);
}

export function nairaFormat(num, dp = 0) {
    return currencyFormat(num, '₦', dp)
}
export function currencyFormat(base_denomination, symbol = '₦', dp = 0) {
    // base_denomination = parseFloat(base_denomination).toFixed(2)
    return symbol + (base_denomination).toFixed(dp)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function cacheServiceLocation(state, lga) {
    const cacheObj = {
        state: state,
        lga: lga
    }
    localStorage.setItem(KOBO_SERVICE_LOCATION, JSON.stringify(cacheObj));
}

export function triggerAppInstalledFunction(data) {
    localStorage.setItem(INSTALLED_APP, JSON.stringify(data));
}

export function activeLocationCheck() {
    const location = localStorage.getItem(KOBO_SERVICE_LOCATION);
    if (location != null) {
        return true;
    }
    return false;
}