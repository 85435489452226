import { Link, useLocation } from 'react-router-dom';
import kobologo from '../../assets/images/kobologo.jpeg';
import koboL1 from '../../assets/images/koboL1.png';
import koboL2 from '../../assets/images/koboL2.png';
import { useEffect, useState, useContext } from "react";
import { UserContext } from '../../context/userProvider';
import $ from "jquery"
import { Avatar, Badge } from 'antd';
import { activeSessionCheck } from '../../util/auth';
import { enquireScreen } from 'enquire-js';


const MeetingGroundSide = () => {
    const userContext = useContext(UserContext);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    let url = location?.pathname;

    const toggleMobileMenuVisibility = () => {
        // const url = location.pathname; // Now this will always have the latest path
        //const url = window.location.pathname;
        // console.log(path)
        // console.log(url);
        if (url.includes("userindex")) {
            $("#kobo-side-menu").toggle('slow');
        } else {
            $(`#${'site-main-mobile-menu'}`).animate({ width: 400 }, 400);
        }
    };
    const hotNavigate = () => {
        window.location.href = "/userindex"
    }
    const dashNav = () => {
        // const url = location.pathname; // Now this will always have the latest path
        //const url = window.location.pathname;
        // console.log(path)

        $("#kobo-side-menu").toggle('slow');

    };

    const closeMobileMenu = () => {
        const menuElement = document.getElementById('site-main-mobile-menu');
        menuElement.style.width = '0px';
    }
    useEffect(() => {
        //url = location?.pathname;
        enquireScreen((b) => {
            setIsMobile(b)
        });
    }, [])

    const toggleSideMenu = () => {

        $("#drawer-kobo-meeting").toggle("slow")

        // setSpinning(!spinning)
        return
        const sideMenu = document.querySelector('.landing-side-menu');
        const body = document.querySelector('body');

        if (sideMenu.classList.contains('open')) {
            sideMenu.classList.remove('open');
            body.classList.remove('no-scroll');
            sideMenu.style.display = "none";
        } else {
            sideMenu.style.display = "block";
            sideMenu.classList.add('open');
            body.classList.add('no-scroll');
        }
    }
    return (
        <>
            <div id="drawer-kobo-meeting">
                <div class="landing-side-menu">
                    <div class="vtm-side-menu-header">
                        <h2 style={{ fontWeight: "200" }} className='text-white'>Kobokist Ltd</h2>
                        <button class="vtm-close-btn" onClick={() => toggleSideMenu()}>✖</button>
                    </div>
                    <ul class="vtm-menu-items">
                        <li><a href="#">Home</a></li>
                        <hr style={{ backgroundColor: "#ffffff24" }} />
                        <li><Link to="/properties">Properties</Link></li>
                        <hr style={{ backgroundColor: "#ffffff24" }} />

                        <li><a href="#">About Us</a></li>
                        <hr style={{ backgroundColor: "#ffffff24" }} />

                        <li><a href="#">Contact</a></li>
                        <hr style={{ backgroundColor: "#ffffff24" }} />

                    </ul>

                    {/* <h3 className='text-white outfit-500 mt-5' style={{fontSize:"15px"}}>Other Businesses</h3>
                    <hr style={{ backgroundColor:"#ffffff3b"}}/>
                    <ul class="vtm-menu-items">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Services</a></li>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul> */}
                    <div class="vtm-side-menu-footer text-white">
                        <p className='text-white' style={{ fontWeight: "200" }}>&copy; 2024 KoboKist</p>
                    </div>

                </div>
            </div>

        </>
    );
}

export default MeetingGroundSide;