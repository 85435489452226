import React, { useEffect } from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import { Link } from "react-router-dom";
import $ from "jquery"


const EmptyCart = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 4000);
    }, [])
    return (
        <>
            <div>
                <div className="section section-padding-bottom mt-3">
                    <div className="container">
                        <div className="cart-empty-content">
                            <span className="icon"><i className="fa fa-shopping-cart" /></span>
                            <h5 className="title">Your cart is currently empty.</h5>
                            {/* <p className="outfit-400">You may check out all the available services and add to cart for processing.</p> */}
                            <Link to={"/catfish-shop-list"} className="btn btn-primary btn-hover-secondary">Return to Fish-Shop</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default EmptyCart;