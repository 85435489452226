import { useState, useContext, useEffect } from "react";
import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import $ from "jquery"
import { USER_KEY, logOutUser } from "../../util/auth";
import { UserContext } from "../../context/userProvider";
import Endpoint from "../../util/endpoint";
import { nairaFormat } from "../../util/helpers";
import { Tag } from "antd";
import { FillSpinner } from "react-spinners-kit";
import UserServiceAllocation from "./service-allocation";
import UserSubscription from "./subscription";
import UserProfile from "./account-details";
import { enquireScreen } from 'enquire-js';
import { Link } from "react-router-dom";

const ACCOUNT_DETAILS = "ACCOUNT_DETAILS"
const DASHBOARD = "DASHBOARD"
const SERVICE_ADDRESS = "SERVICE_ADDRESS"
const SERVICE_REQUESTS = "SERVICE_REQUESTS"
const SERVICE_ALLOCATION_DETAILS = "SERVICE_ALLOCATION_DETAILS"
const SUBSCRIPTION_DETAILS = "SUBSCRIPTION_DETAILS"
const UserDashboard = () => {
    const userContext = useContext(UserContext);
    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem(USER_KEY)))
    const [displayIndicator, setdisplayIndicator] = useState(DASHBOARD)
    const [firstName, setFirstName] = useState(userProfile?.fullName?.split(' ')[0]);
    const [lastName, setlastName] = useState(userProfile?.fullName?.split(' ')[1]);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageSize, setpageSize] = useState(5);
    const [totalPageCount, settotalPageCount] = useState(0);
    const [serviceRequestList, setserviceRequestList] = useState([]);
    const [serviceRequestDetails, setserviceRequestDetails] = useState({});
    const [isMobile, setIsMobile] = useState(false);

    const handleIndicator = (data) => {
        isMobile && $("#kobo-side-menu").toggle('slow') 
        setdisplayIndicator(data)
    }

    const handleLogout = () => {
        $("#preloader").fadeIn("slow")
        logOutUser()
    }

    const fetchServiceRequests = () => {
        // if (pageNumber == totalPageCount){
        //     return;
        // }
        Endpoint.getServiceRequestList(pageNumber, pageSize)
        .then((res) => {
            // console.log(res?.data)
            settotalPageCount(res?.data?.totalPages)
            setserviceRequestList(res?.data?.data)

            $("#preloader").delay(450).fadeOut("slow")
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleParseServiceDetails = (indicator, data) => {
        console.log(data)
        setserviceRequestDetails(data)
        setdisplayIndicator(indicator)
    }
    const handleNextPage = () => {
        if (pageNumber == totalPageCount) {
            return;
        }
        $("#preloader").fadeIn("slow")
        setpageNumber(pageNumber + 1);
        fetchServiceRequests()
    }
    const handlePreviousPage = () => {
        $("#preloader").fadeIn("slow")
        setpageNumber(pageNumber-1)
        fetchServiceRequests()
    }
useEffect(() => {
    window.scrollTo(0, 0);
    enquireScreen((b) => {
        setIsMobile(b)
    });
   
    
    fetchServiceRequests();
},[])
    return (
        <div className="App">
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <div>
                <div id="page">
                 
                    {/* Page Title Section Start */}
                    <div className="page-title-section section">
                        {/* <div className="page-title">
                            <div className="container">
                                <h1 className="title outfit-700">My account</h1>
                            </div>
                        </div> */}
                        {/* <div className="page-breadcrumb mt-2">
                            <div className="container">
                                <ul className="breadcrumb">
                                    <li><a href="index.html">Home/</a></li>
                                    <li className="current">My account</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    {/* Page Title Section End */}
                    {/*My Account section start*/}
                    <div className="my-account-section section section-padding-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        {/* My Account Tab Menu Start */}
                                        <div className="col-lg-3 col-12" id="kobo-side-menu">
                                            <div className="myaccount-tab-menu nav" role="tablist">
                                                <a href="#" onClick={() => handleIndicator(DASHBOARD)} className={displayIndicator == DASHBOARD && "active"} data-toggle="tab"><i className="fa fa-tachometer" />
                                                    Dashboard</a>
                                                <a href="#" data-toggle="tab" onClick={() => handleIndicator(SERVICE_REQUESTS)} className={displayIndicator == SERVICE_REQUESTS || displayIndicator == SERVICE_ALLOCATION_DETAILS ? "active" : ""}><i className="fa fa-cart-arrow-down" /> Service Requests</a>
                                                <a href="#" data-toggle="tab" onClick={() => handleIndicator(SUBSCRIPTION_DETAILS)} className={displayIndicator == SUBSCRIPTION_DETAILS ? "active" : ""}><i className="fa fa-credit-card" /> Subscription   
                                                
                                                    {userContext?.subscriptionStatus && 
                                                        <Tag color={"green"} className="outfit-500 ml-2" style={{ fontSize: "9px" }}>
                                                            Active
                                                        </Tag>
                                                    }
                                                
                                                    </a>
                                                {/* <a href="#" onClick={() => handleIndicator(SERVICE_ADDRESS)} className={displayIndicator == SERVICE_ADDRESS && "active"} data-toggle="tab"><i className="fa fa-map-marker" /> address</a> */}
                                                <a href="#" className={displayIndicator == ACCOUNT_DETAILS && "active"} onClick={() => handleIndicator(ACCOUNT_DETAILS)} data-toggle="tab"><i className="fa fa-user" /> Profile
                                                    &nbsp; 
                                                    {userContext?.userProfile?.userStatus == 0 && <span className="danger-badge">Unverified</span>}
                                                    {userContext?.userProfile?.userStatus == 1 && <span className="pending-badge">Processing</span>}
                                                    {userContext?.userProfile?.userStatus == 2 && <span className="success-badge">verified</span>}
                                                </a>
                                                
                                                {isMobile && <Link to="/home-services" data-toggle="tab"><i className="fa fa-home" />home
                                                </Link>}
                                                <a href="#" onClick={() => handleLogout()}><i className="fa fa-sign-out" /> Logout</a>
                                            </div>
                                        </div>
                                        {/* My Account Tab Menu End */}
                                        {/* My Account Tab Content Start */}
                                        <div className="col-lg-9 col-12">
                                            <div className="tab-content" id="myaccountContent">
                                                {/* Single Tab Content Start */}
                                                {displayIndicator == DASHBOARD && 
                                                <div className="tab-pane fade show active" id="dashboad" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3 className="outfit-600">Dashboard</h3>
                                                        <div className="welcome mb-20">
                                                            <p>Hello, <strong className="outfit-700">{userProfile?.fullName}</strong></p>
                                                        </div>
                                                           
                                                            {userContext?.userProfile?.userStatus == 2 && 
                                                            
                                                                <p className="mb-0">Welcome to your account dashboard! Here, you can effortlessly manage your recent service requests, update your billing and service addresses, and securely change your password. Keep your account details up-to-date to ensure a smooth and seamless experience.
                                                                </p>
                                                            }
                                                            {userContext?.userProfile?.userStatus == 0 && 
                                                                <p className="mb-0"> Your account is currently unverified. To ensure a smooth experience, please navigate to 'Account Details' and complete the verification process. Once verified, you'll be able to start requesting services
                                                                </p>
                                                            }
                                                           
                                                           
                                                    </div>
                                                </div>}
                                                {/* Single Tab Content End */}
                                                {/* Single Tab Content Start */}
                                              {displayIndicator == SERVICE_REQUESTS && 
                                                    <div className="tab-pane fade show active" id="orders" role="tabpanel">
                                                        <div className="myaccount-content">
                                                            <h3>Service Requests</h3>
                                                            <div className="myaccount-table table-responsive text-center">
                                                                <table className="table table-bordered outfit-400">
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            {/* <th>No</th> */}
                                                                            <th>Name</th>
                                                                            <th>Service Date</th>
                                                                            <th>Status</th>
                                                                            <th>Total</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="text-left">
                                                                       {serviceRequestList && serviceRequestList.map((x, i) => {
                                                                        return(
                                                                            <tr>
                                                                                {/* <td>{i+1}</td> */}
                                                                                <td>{x?.serviceName}</td>
                                                                                <td>{x?.serviceDate}</td>
                                                                                <td>
                                                                                    <Tag className="outfit-500" color={x?.servicedStatus == 0 ? "volcano" : x?.servicedStatus == 1 ? "geekblue" : x?.servicedStatus == 2 ? "green" : x?.servicedStatus == 3 ? "" : "" }>
                                                                                        {x?.status}
                                                                                    </Tag>
                                                                                </td>
                                                                                <td>{x?.amount > 0 ? nairaFormat(x?.amount, 2) : 0}</td>
                                                                                <td><a href="#" onClick={() => handleParseServiceDetails(SERVICE_ALLOCATION_DETAILS, x)} className="ht-btn black-btn">View</a></td>
                                                                            </tr>
                                                                        )
                                                                       })}
                                                                       
                                                                    </tbody>
                                                                </table>
                                                                <button onClick={() => handleNextPage()} style={{height:"40px", float:"right"}} type="button" className="btn btn-primary ">Next &nbsp; <i className="fa fa-arrow-right"/></button>
                                                                {pageNumber > 1 && 
                                                                    <button onClick={() => handlePreviousPage()} style={{ height: "40px", float: "right" }} type="button" className="btn btn-primary mr-2 "><i className="fa fa-arrow-left" /> &nbsp; Previous</button>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                              }
                                                {
                                                    displayIndicator == SERVICE_ALLOCATION_DETAILS &&
                                                    <div className="tab-pane fade show active" id="" role="tabpanel">
                                                        <div className="myaccount-content">
                                                                <UserServiceAllocation visibilityProps={handleIndicator}  serviceInfo={serviceRequestDetails}/>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    displayIndicator == SUBSCRIPTION_DETAILS &&
                                                    <div className="tab-pane fade show active" id="" role="tabpanel">
                                                        <div className="myaccount-content">
                                                            <UserSubscription serviceInfo={serviceRequestDetails} />
                                                        </div>
                                                    </div>
                                                }
                                                {/* Single Tab Content End */}
                                                {/* Single Tab Content Start */}
                                                <div className="tab-pane fade" id="download" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3>Downloads</h3>
                                                        <div className="myaccount-table table-responsive text-center">
                                                            <table className="table table-bordered">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th>Product</th>
                                                                        <th>Date</th>
                                                                        <th>Expire</th>
                                                                        <th>Download</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Mostarizing Oil</td>
                                                                        <td>Aug 22, 2018</td>
                                                                        <td>Yes</td>
                                                                        <td><a href="#" className="ht-btn black-btn">Download File</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Katopeno Altuni</td>
                                                                        <td>Sep 12, 2018</td>
                                                                        <td>Never</td>
                                                                        <td><a href="#" className="ht-btn black-btn">Download File</a></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Single Tab Content End */}
                                                {/* Single Tab Content Start */}
                                                <div className="tab-pane fade" id="payment-method" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3>Payment Method</h3>
                                                        <p className="saved-message">You Can't Saved Your Payment Method yet.</p>
                                                    </div>
                                                </div>
                                                {/* Single Tab Content End */}
                                                {/* Single Tab Content Start */}
                                                {displayIndicator == SERVICE_ADDRESS && 
                                                <div className="tab-pane fade show active" id="address-edit" role="tabpanel">
                                                    <div className="myaccount-content text-left">
                                                        <h3>Billing Address</h3>
                                                        <address>
                                                                <p><strong>{userContext?.userProfile?.firstName} {userContext?.userProfile?.lastName}</strong></p>
                                                            <p>
                                                                    {userContext?.userProfile?.homeAddress} <br />
                                                                    {userContext?.userProfile?.phone}
                                                                </p>
                                                                <p>Mobile: {userContext?.userProfile?.phone}</p>
                                                        </address>
                                                        <a href="#" className="ht-btn black-btn d-inline-block edit-address-btn"><i className="fa fa-edit" />Edit Address</a>
                                                    </div>
                                                </div>}
                                                {/* Single Tab Content End */}
                                                {/* Single Tab Content Start */}
                                                {displayIndicator == ACCOUNT_DETAILS && 
                                                        <UserProfile/>
                                                
                                                }
                                               
                                                {/* Single Tab Content End */}
                                            </div>
                                        </div>
                                        {/* My Account Tab Content End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*My Account section end*/}
                    
                    {/* Scroll Top Start */}
                    <a href="#" className="scroll-top" id="scroll-top">
                        <i className="arrow-top fal fa-long-arrow-up" />
                        <i className="arrow-bottom fal fa-long-arrow-up" />
                    </a>
                    {/* Scroll Top End */}
                </div>
                <div id="site-main-mobile-menu" className="site-main-mobile-menu">
                    <div className="site-main-mobile-menu-inner">
                        <div className="mobile-menu-header">
                            <div className="mobile-menu-logo">
                                <a href="index.html"><img src="assets/images/logo/dark-logo.png" alt="" /></a>
                            </div>
                            <div className="mobile-menu-close">
                                <button className="toggle">
                                    <i className="icon-top" />
                                    <i className="icon-bottom" />
                                </button>
                            </div>
                        </div>
                        <div className="mobile-menu-content">
                            <nav className="site-mobile-menu">
                                <ul>
                                    <li className="has-children position-static">
                                        <a href="#"><span className="menu-text">Home</span></a>
                                        <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        <ul className="mega-menu">
                                            <li>
                                                <ul>
                                                    <li><a href="index.html"><span className="menu-text">KoboKist Education <span className="badge">Hot</span></span></a></li>
                                                    <li><a href="index-2.html"><span className="menu-text">Course Portal</span></a></li>
                                                    <li><a href="index-3.html"><span className="menu-text">Distant Learning <span className="badge">Hot</span></span></a></li>
                                                    <li><a href="index-4.html"><span className="menu-text">Multimedia Pedagogy</span></a></li>
                                                    <li><a href="index-5.html"><span className="menu-text">Modern Schooling</span></a></li>
                                                    <li><a href="index-6.html"><span className="menu-text">Remote Training</span></a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    <li><a href="index-7.html"><span className="menu-text">Health Coaching</span></a></li>
                                                    <li><a href="index-8.html"><span className="menu-text">Gym Coaching</span></a></li>
                                                    <li><a href="index-9.html"><span className="menu-text">Business</span></a></li>
                                                    <li><a href="index-10.html"><span className="menu-text">Artist <span className="badge primary">New</span></span></a></li>
                                                    <li><a href="index-11.html"><span className="menu-text">Kitchen Coach <span className="badge primary">New</span></span></a></li>
                                                    <li><a href="index-12.html"><span className="menu-text">Motivation <span className="badge primary">New</span></span></a></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-50">
                                                <ul>
                                                    <li><a href="#"><img src="assets/images/menu/mega-menu.jpg" alt="menu-image" /></a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="has-children">
                                        <a href="#"><span className="menu-text">Pages</span></a>
                                        <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        <ul className="sub-menu">
                                            <li><a href="start-here.html"><span className="menu-text">Start Here</span></a></li>
                                            <li><a href="success-story.html"><span className="menu-text">Success Story</span></a></li>
                                            <li><a href="about-me.html"><span className="menu-text">About me</span></a></li>
                                            <li><a href="about-us-1.html"><span className="menu-text">About us 01</span></a></li>
                                            <li><a href="about-us-2.html"><span className="menu-text">About us 02</span></a></li>
                                            <li><a href="about-us-3.html"><span className="menu-text">About us 03</span></a></li>
                                            <li><a href="contact-me.html"><span className="menu-text">Contact me</span></a></li>
                                            <li><a href="contact-us.html"><span className="menu-text">Contact us</span></a></li>
                                            <li><a href="purchase-guide.html"><span className="menu-text">Purchase Guide</span></a></li>
                                            <li><a href="privacy-policy.html"><span className="menu-text">Privacy Policy</span></a></li>
                                            <li><a href="terms-of-service.html"><span className="menu-text">Terms of Service</span></a></li>
                                        </ul>
                                    </li>
                                    <li className="has-children">
                                        <a href="#"><span className="menu-text">Courses</span></a>
                                        <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        <ul className="sub-menu">
                                            <li><a href="courses-grid-1.html"><span className="menu-text">Courses Grid 01</span></a></li>
                                            <li><a href="courses-grid-2.html"><span className="menu-text">Courses Grid 02</span></a></li>
                                            <li><a href="courses-grid-3.html"><span className="menu-text">Courses Grid 03</span></a></li>
                                            <li><a href="membership-levels.html"><span className="menu-text">Membership Levels</span></a></li>
                                            <li><a href="become-a-teacher.html"><span className="menu-text">Become a Teacher</span></a></li>
                                            <li><a href="profile.html"><span className="menu-text">Profile</span></a></li>
                                            <li><a href="checkout.html"><span className="menu-text">Checkout</span></a></li>
                                            <li className="has-children">
                                                <a href="course-details-sticky-feature-bar.html"><span className="menu-text">Single Layout</span></a>
                                                <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                                <ul className="sub-menu">
                                                    <li><a href="course-details-free.html"><span className="menu-text">Free Course</span></a></li>
                                                    <li><a href="course-details-sticky-feature-bar.html"><span className="menu-text">Sticky Features Bar</span></a></li>
                                                    <li><a href="course-details-standard-sidebar.html"><span className="menu-text">Standard Sidebar</span></a></li>
                                                    <li><a href="course-details-no-sidebar.html"><span className="menu-text">No Sidebar</span></a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="has-children">
                                        <a href="#"><span className="menu-text">Event</span></a>
                                        <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        <ul className="sub-menu">
                                            <li><a href="event.html"><span className="menu-text">Event</span></a></li>
                                            <li><a href="zoom-meetings.html"><span className="menu-text">Zoom Meetings</span></a></li>
                                            <li><a href="event-details.html"><span className="menu-text">Event Details</span></a></li>
                                            <li><a href="zoom-event-details.html"><span className="menu-text">Zoom Meeting Details</span></a></li>
                                        </ul>
                                    </li>
                                    <li className="has-children">
                                        <a href="#"><span className="menu-text">Blog</span></a>
                                        <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        <ul className="sub-menu">
                                            <li><a href="blog-grid.html"><span className="menu-text">Blog Grid</span></a></li>
                                            <li><a href="blog-masonry.html"><span className="menu-text">Blog Masonry</span></a></li>
                                            <li><a href="blog-classic.html"><span className="menu-text">Blog Classic</span></a></li>
                                            <li><a href="blog-list.html"><span className="menu-text">Blog List</span></a></li>
                                        </ul>
                                    </li>
                                    <li className="has-children">
                                        <a href="#"><span className="menu-text">Shop</span></a>
                                        <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        <ul className="sub-menu">
                                            <li><a href="shop.html"><span className="menu-text">Shop Left Sidebar</span></a></li>
                                            <li><a href="shop-right-sidebar.html"><span className="menu-text">Shop Right Sidebar</span></a></li>
                                            <li><a href="shopping-cart.html"><span className="menu-text">Cart</span></a></li>
                                            <li><a href="shopping-cart-empty.html"><span className="menu-text">Cart Empty</span></a></li>
                                            <li><a href="wishlist.html"><span className="menu-text">Wishlist</span></a></li>
                                            <li><a href="product-details.html"><span className="menu-text">Single Product</span></a></li>
                                            <li><a href="my-account.html"><span className="menu-text">My Account</span></a></li>
                                            <li><a href="login-register.html"><span className="menu-text">Login Register</span></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    );
}

export default withLayout(UserLayout)(UserDashboard);