import React, { useEffect, useContext, useState } from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import { Link } from "react-router-dom";
import $ from "jquery"
import EmptyCart from "./empty-cart";
import { UserContext } from "../../context/userProvider";
import { nairaFormat } from "../../util/helpers";
import { baseContentURL } from "../../util/endpoint";


const Membership = () => {
    const userContext = useContext(UserContext);


    useEffect(() => {

        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return (
        <div className=" section section-padding-bottom mt-5">
            <div className="membership-terms container">
                <h1 className="mb-4">Membership</h1>
                <p>
                    Any user, subscriber, customer, client, whatever term that may be used, who transacts with Kobokist Ltd, whether one-time or recurrent, or a monthly subscriber, shall be referred to as a member.
                </p>
                <p>
                    All members must adhere to our terms and conditions, privacy policy, and membership terms of use for each of Kobokist Ltd businesses.
                </p>
                <p>
                    All members should refer to the terms and agreement of Kobokist Luxury Homes before, during, and after subscription. All members shall adhere to our home services terms of use. One of Kobokist's most important terms of use for our home services is identity verification. All members must go through identity verification for proper documentation of members and absolute accountability of our employees. We highly prohibit our members from directly contacting our employees for off-duty assignments. Kobokist prides herself on the safety of both our members and employees and will prefer to monitor all activities through services ordered directly on Kobokist's website or through a call or other means of formal communication (as the case may be).
                </p>
                <p>
                    Kobokist shall be responsible for every act or conduct of its employee done in the usual and ordinary course of their employment. Kobokist shall be liable for all its covenants and obligations under this membership terms Agreement or arising out of any existing law governing service companies in Nigeria.
                </p>
                <p>
                    Without prejudice to the foregoing, Kobokist shall not be liable to any claim by the member arising from or attributable to any criminal act of its employee or agent which occurred outside the usual course of their employment and is not connected to the purpose of this Agreement.
                </p>
            </div>



        </div>

    )
}
export default withLayout(UserLayout)(Membership)