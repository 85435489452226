import React, {useContext, useEffect} from 'react';
import { useLocation } from "react-router-dom";

import { Link } from 'react-router-dom';
import Endpoint from '../../util/endpoint';
import { FillSpinner } from 'react-spinners-kit';
import $ from "jquery"
import { CAT_FISH_SHOP_PAYMENT, clearCart, KOBO_CART_FISH_SHOP, PAYMENT_ROUTE_KEY } from '../../util/helpers';

const PaymentSuccess = () => {
    const location = useLocation();

    const trxref = new URLSearchParams(location.search).get("trxref");
    const routeKey = localStorage.getItem(PAYMENT_ROUTE_KEY);

    const confirmPayment = () => {
        console.log(trxref, "trddd")
        Endpoint.validateAndUpdatePayment(trxref)
        .then((res) => {
            console.log(res?.data)
            $("#preloader").delay(450).fadeOut("slow")
            if (routeKey == CAT_FISH_SHOP_PAYMENT) {
                localStorage.removeItem(KOBO_CART_FISH_SHOP);
                localStorage.removeItem(PAYMENT_ROUTE_KEY);
            }
            else {
                clearCart();
            }
        })
        .catch((err) => {
            $("#preloader").delay(450).fadeOut("slow")
            alert("Oops! something went wrong and your payment could not be verified at this time")
            console.log(err);
        })
    }
    const handleResolveRoute = () => {
        if (routeKey == CAT_FISH_SHOP_PAYMENT){
            window.location.href = CAT_FISH_SHOP_PAYMENT;
        }
        else{
            window.location.href = "/userindex";
        }

    }
    useEffect(() => {
        confirmPayment()
    }, [])
    return (
 <>
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card text-center">
                            <div className="card-body">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100"
                                    height="100"
                                    fill="green"
                                    className="bi bi-check-circle"
                                    viewBox="0 0 16 16"
                                    style={{ marginBottom: '20px', animation: 'checkmark 1s ease-in-out', animationIterationCount: 'infinite', animationDuration: "2s" }}
                                >
                                    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" />
                                    <path d="M10.97 4.97a.235.235 0 0 1 .326 0l.708.708a.235.235 0 0 1 0 .326L8.475 9.53a.235.235 0 0 1-.326 0L5.646 7.03a.235.235 0 0 1 0-.326l.708-.708a.235.235 0 0 1 .326 0L8 7.293l2.97-2.97z" />
                                </svg>
                                <h1 className="" style={{ fontSize: "30px" }}>Payment Successful</h1>
                                <p className="card-text" style={{ fontSize: '15px' }}>
                                    Your transaction was successful! A service professional will be dispatched to your location shortly. You will receive a call soon to confirm the details.
                                </p>
                                <a onClick={() => handleResolveRoute()} className="btn btn-primary btn-sm" style={{ marginTop: '20px', height: "38px" }}>
                                    Go to dashboard
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 </>
    );
};

// Adding keyframes for animation
const styleSheet = document.styleSheets[0];
const keyframes =
    `@keyframes checkmark {
    0% { opacity: 0; transform: scale(0.5); }
    50% { opacity: 0.5; transform: scale(1.2); }
    100% { opacity: 1; transform: scale(1); }
  }`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default PaymentSuccess;
