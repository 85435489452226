import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import bgHomeService from "../../assets/images/servicecollage2.png"
import about1 from "../../assets/images/about/about01/about1.jpg"
import hero1 from "../../assets/images/cato.jpg"
import hero2 from "../../assets//images/cato2.png"
import shape1 from "../../assets/images/shape-animation/about-shape-1.png"
import shape2 from "../../assets/images/shape-animation/nwesletter-shape-2.png"
import { enquireScreen } from "enquire-js";
import { UserContext } from '../../context/userProvider';
import { FillSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import CatFishHeader from "../../layouts/user/catfishheader";
import CatfishLayout from "../../layouts/user/catfishindex";
import Endpoint, { baseContentURL } from "../../util/endpoint";
import { KOBO_CART_FISH_SHOP, nairaFormat } from "../../util/helpers";
import { ToastContainer, toast } from 'react-toastify';


require("../../assets/css/catfish.css")


const CatFishShopIndex = () => {
    const { pathname } = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    const userContext = useContext(UserContext);
    const [fishArr, setfishArr] = useState([]);


    const fetchShopList = () => {
        Endpoint.getFishShopList()
            .then((res) => {
                setfishArr(res?.data)
                console.log(res?.data, "fish")
                $("#preloader").delay(450).fadeOut("slow")
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleAddToCart = (item) => {
        console.log(item, "added")
        userContext.globalAddToCartRegular(item, KOBO_CART_FISH_SHOP)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchShopList()
        enquireScreen((b) => {
            setIsMobile(b)
        });

        // setTimeout(() => {
        //     $("#preloader").delay(450).fadeOut("slow")
        // }, 4000);
    }, [pathname])
    return (
        <div className="">
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <div>
               {/* <CatFishHeader/> */}
                {/* Navbar End */}
                {/* Modal Search Start */}
                <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content rounded-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body d-flex align-items-center">
                                <div className="input-group w-75 mx-auto d-flex">
                                    <input type="search" className="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                                    <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Search End */}
                {/* Hero Start */}
                <div className="container-fluid py-5 mb-5 hero-header">
                    <div className="container py-3">
                        <div className="row g-5 align-items-center">
                            <div className="col-md-12 col-lg-7">
                                {/* <h4 className="mb-3 text-secondary" style={{fontSize:"23px"}}>The <span className='outfit-700 logo-nav-text' style={{ color: "#076fcc", display: "inline-flex", alignItems: "center" }}>
                                    Kobo<span style={{ color: "#e57b3c" }}>Kist</span></span></h4> */}
                                {!isMobile && <h4 className="mb-3 text-secondary" style={{ fontSize: "23px" }}>&nbsp;</h4>}
                                <h1 className="mb-2 text-primary outfit-700 kobo-fish-her0-text"><span style={{ color:"#f28c3b"}}>Kobokist</span> Fish-Shop</h1>
                                <h1 className="text outfit-700 display-2-kobo" style={{ color:"#605f77"}}>The finest selection of fresh, sustainably farmed catfish.</h1>
                                {/* <p className="outfit-500">Perfect for every meal, our premium quality catfish is packed with flavor and nutrition</p> */}
                                <div className="position-relative mx-auto">
                                    <input style={{border:"1px solid grey"}} className="mt-4 form-control border-2 border-secondary kobo-w75 py-3 px-4 rounded-pill" type="text" placeholder="Search" />
                                    <button type="submit" className="btn btn-primary border-2 border-secondary py-3 px-4 position-absolute rounded-pill text-white h-100" style={isMobile ? { top: 0, right: '0' } : { top: 0, right: '25%' }}>Submit</button>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5 mt-3">
                                <div id="carouselId" className="carousel slide position-relative" data-bs-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                       
                                        <div className="carousel-item rounded">
                                            <img src={hero2} className="img-fluid w-100 h-100 rounded" alt="Second slide" style={{border:"5px solid orange"}} />
                                            <a href="#" className="btn px-4 py-2 text-white rounded">Fresh</a>
                                        </div>
                                        <div className="carousel-item active rounded">
                                            <img src={hero1} className="img-fluid w-100 h-100 bg-secondary rounded" alt="First slide" style={{ border: "5px solid orange" }} />
                                            <a href="#" className="btn px-4 py-2 text-white rounded">Sizable</a>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true" />
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Hero End */}
                {/* Featurs Section Start */}
                <div className="container-fluid featurs py-5">
                    <div className="container py-5">
                        <div className="row g-4 justify-content-center">
                            {/* <div className="col-md-6 col-lg-3 mb-3">
                                <div className="featurs-item text-center rounded bg-light p-4">
                                    <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                        <i className="fas fa-car-side fa-3x text-white" />
                                    </div>
                                    <div className="featurs-content text-center">
                                        <h5>Home Delivery</h5>
                                        <p className="mb-0">Free on order over ₦300</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-3 mb-3">
                                <div className="featurs-item text-center rounded bg-light p-4">
                                    <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                        <i className="fas fa-user-shield fa-3x text-white" />
                                    </div>
                                    <div className="featurs-content text-center">
                                        <h5>Secured Payment</h5>
                                        <p className="mb-0">100% security payment</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-3 mb-3">
                                <div className="featurs-item text-center rounded bg-light p-4">
                                    <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                        <i className="fas fa-exchange-alt fa-3x text-white" />
                                    </div>
                                    <div className="featurs-content text-center">
                                        <h5>30 Day Return</h5>
                                        <p className="mb-0">30 day money guarantee</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-3 mb-3">
                                <div className="featurs-item text-center rounded bg-light p-4">
                                    <div className="featurs-icon btn-square rounded-circle bg-secondary mb-5 mx-auto">
                                        <i className="fa fa-phone-alt fa-3x text-white" />
                                    </div>
                                    <div className="featurs-content text-center">
                                        <h5>24/7 Support</h5>
                                        <p className="mb-0">Support every time fast</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Featurs Section End */}
                {/* Fruits Shop Start*/}
                <div className="container-fluid fruite py-5">
                    <div className="container py-5">
                        <div className="tab-class text-center">
                            <div className="row g-4 mb-5">
                                <div className="col-lg-4 text-start">
                                    <h1>Our Catfish Pricing</h1>
                                </div>
                                {/* <div className="col-lg-8 text-end">
                                    <ul className="nav nav-pills d-inline-flex text-center mb-5">
                                        <li className="nav-item">
                                            <a className="d-flex m-2 py-2 bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                                                <span className="text-dark" style={{ width: '130px' }}>All Products</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="d-flex py-2 m-2 bg-light rounded-pill" data-bs-toggle="pill" href="#tab-2">
                                                <span className="text-dark" style={{ width: '130px' }}>Vegetables</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="d-flex m-2 py-2 bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                                                <span className="text-dark" style={{ width: '130px' }}>Fruits</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="d-flex m-2 py-2 bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                                                <span className="text-dark" style={{ width: '130px' }}>Bread</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="d-flex m-2 py-2 bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                                                <span className="text-dark" style={{ width: '130px' }}>Meat</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="tab-content">
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="row g-4">
                                                {fishArr && fishArr?.map((x, i) => {
                                                    return (
                                                        <div className="col-md-6 col-lg-4 col-xl-3 mb-3">
                                                            <div className="rounded position-relative fruite-item">
                                                                <div className="fruite-img">
                                                                    <img src={baseContentURL + x?.imageUrl} className="img-fluid w-100 rounded-top" alt="" />
                                                                </div>
                                                                <div className="text-white bg-secondary px-3 py-1 rounded position-absolute outfit-400" style={{ top: '10px', left: '10px' }}>{x?.name}</div>
                                                                <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                                    <h4>{x?.extraInfo}</h4>
                                                                    {/* <h5 style={{marginTop:"-11px"}}>{x?.extraInfo}</h5> */}

                                                                    <p>{x?.description}</p>
                                                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                                                        <p className="text-dark fs-5 fw-bold mb-0">{x?.price > 0 ? nairaFormat(x?.price, 2) : x?.price} / kg</p>
                                                                        <Link to={"/catfish-shop-list"} type="button"  className="btn border btn-primary btn-sm rounded-pill px-3 text-white mt-2"><i className="fa fa-shopping-bag me-2 text-white" /> &nbsp; Go to Shop</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div id="tab-2" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="row g-4">
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-5.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Grapes</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-2.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Raspberries</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-3" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="row g-4">
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-1.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Oranges</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-6.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Apple</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-4" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="row g-4">
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-5.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Grapes</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-4.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Apricots</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-5" className="tab-pane fade show p-0">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="row g-4">
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-3.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Banana</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-2.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Raspberries</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3">
                                                    <div className="rounded position-relative fruite-item">
                                                        <div className="fruite-img">
                                                            <img src="img/fruite-item-1.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                                        </div>
                                                        <div className="text-white bg-secondary px-3 py-1 rounded position-absolute" style={{ top: '10px', left: '10px' }}>Fruits</div>
                                                        <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                            <h4>Oranges</h4>
                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                                            <div className="d-flex justify-content-between flex-lg-wrap">
                                                                <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                                                <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <div className="container-fluid service py-5">
                    <div className="container py-5">
                        <div className="row g-4 justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <a href="#">
                                    <div className="service-item bg-secondary rounded border border-secondary">
                                        <img src="img/featur-1.jpg" className="img-fluid rounded-top w-100" alt="" />
                                        <div className="px-4 rounded-bottom">
                                            <div className="service-content bg-primary text-center p-4 rounded">
                                                <h5 className="text-white">Fresh Apples</h5>
                                                <h3 className="mb-0">20% OFF</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="#">
                                    <div className="service-item bg-dark rounded border border-dark">
                                        <img src="img/featur-2.jpg" className="img-fluid rounded-top w-100" alt="" />
                                        <div className="px-4 rounded-bottom">
                                            <div className="service-content bg-light text-center p-4 rounded">
                                                <h5 className="text-primary">Tasty Fruits</h5>
                                                <h3 className="mb-0">Free delivery</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="#">
                                    <div className="service-item bg-primary rounded border border-primary">
                                        <img src="img/featur-3.jpg" className="img-fluid rounded-top w-100" alt="" />
                                        <div className="px-4 rounded-bottom">
                                            <div className="service-content bg-secondary text-center p-4 rounded">
                                                <h5 className="text-white">Exotic Vegitable</h5>
                                                <h3 className="mb-0">Discount 30₦</h3>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container-fluid vesitable py-5">
                    <div className="container py-5">
                        <h1 className="mb-0">Fresh Organic Vegetables</h1>
                        <div className="owl-carousel vegetable-carousel justify-content-center">
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-6.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Parsely</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-1.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Parsely</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦4.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-3.png" className="img-fluid w-100 rounded-top bg-light" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Banana</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦7.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-4.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Bell Papper</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦7.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-5.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Potatoes</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦7.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-6.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Parsely</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦7.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-5.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Potatoes</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦7.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-primary rounded position-relative vesitable-item">
                                <div className="vesitable-img">
                                    <img src="img/vegetable-item-6.jpg" className="img-fluid w-100 rounded-top" alt="" />
                                </div>
                                <div className="text-white bg-primary px-3 py-1 rounded position-absolute" style={{ top: '10px', right: '10px' }}>Vegetable</div>
                                <div className="p-4 rounded-bottom">
                                    <h4>Parsely</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod te incididunt</p>
                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                        <p className="text-dark fs-5 fw-bold mb-0">₦7.99 / kg</p>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container-fluid banner bg-secondary my-5">
                    <div className="container py-5">
                        <div className="row g-4 align-items-center">
                            <div className="col-lg-6">
                                <div className="py-4">
                                    <h1 className="display-3 text-white">Fresh Exotic Fruits</h1>
                                    <p className="fw-normal display-3 text-dark mb-4">in Our Store</p>
                                    <p className="mb-4 text-dark">The generated Lorem Ipsum is therefore always free from repetition injected humour, or non-characteristic words etc.</p>
                                    <a href="#" className="banner-btn btn border-2 border-white rounded-pill text-dark py-3 px-5">BUY</a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="position-relative">
                                    <img src="img/baner-1.png" className="img-fluid w-100 rounded" alt="" />
                                    <div className="d-flex align-items-center justify-content-center bg-white rounded-circle position-absolute" style={{ width: '140px', height: '140px', top: 0, left: 0 }}>
                                        <h1 style={{ fontSize: '100px' }}>1</h1>
                                        <div className="d-flex flex-column">
                                            <span className="h2 mb-0">50₦</span>
                                            <span className="h4 text-muted mb-0">kg</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container-fluid py-5">
                    <div className="container py-5">
                        <div className="text-center mx-auto mb-5" style={{ maxWidth: '700px' }}>
                            <h1 className="display-4">Bestseller Products</h1>
                            <p>Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-6 col-xl-4">
                                <div className="p-4 rounded bg-light">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src="img/best-product-1.jpg" className="img-fluid rounded-circle w-100" alt="" />
                                        </div>
                                        <div className="col-6">
                                            <a href="#" className="h5">Organic Tomato</a>
                                            <div className="d-flex my-3">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                            <h4 className="mb-3">3.12 ₦</h4>
                                            <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div className="p-4 rounded bg-light">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src="img/best-product-2.jpg" className="img-fluid rounded-circle w-100" alt="" />
                                        </div>
                                        <div className="col-6">
                                            <a href="#" className="h5">Organic Tomato</a>
                                            <div className="d-flex my-3">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                            <h4 className="mb-3">3.12 ₦</h4>
                                            <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div className="p-4 rounded bg-light">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src="img/best-product-3.jpg" className="img-fluid rounded-circle w-100" alt="" />
                                        </div>
                                        <div className="col-6">
                                            <a href="#" className="h5">Organic Tomato</a>
                                            <div className="d-flex my-3">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                            <h4 className="mb-3">3.12 ₦</h4>
                                            <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div className="p-4 rounded bg-light">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src="img/best-product-4.jpg" className="img-fluid rounded-circle w-100" alt="" />
                                        </div>
                                        <div className="col-6">
                                            <a href="#" className="h5">Organic Tomato</a>
                                            <div className="d-flex my-3">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                            <h4 className="mb-3">3.12 ₦</h4>
                                            <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div className="p-4 rounded bg-light">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src="img/best-product-5.jpg" className="img-fluid rounded-circle w-100" alt="" />
                                        </div>
                                        <div className="col-6">
                                            <a href="#" className="h5">Organic Tomato</a>
                                            <div className="d-flex my-3">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                            <h4 className="mb-3">3.12 ₦</h4>
                                            <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div className="p-4 rounded bg-light">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src="img/best-product-6.jpg" className="img-fluid rounded-circle w-100" alt="" />
                                        </div>
                                        <div className="col-6">
                                            <a href="#" className="h5">Organic Tomato</a>
                                            <div className="d-flex my-3">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                            <h4 className="mb-3">3.12 ₦</h4>
                                            <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3">
                                <div className="text-center">
                                    <img src="img/fruite-item-1.jpg" className="img-fluid rounded" alt="" />
                                    <div className="py-4">
                                        <a href="#" className="h5">Organic Tomato</a>
                                        <div className="d-flex my-3 justify-content-center">
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h4 className="mb-3">3.12 ₦</h4>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3">
                                <div className="text-center">
                                    <img src="img/fruite-item-2.jpg" className="img-fluid rounded" alt="" />
                                    <div className="py-4">
                                        <a href="#" className="h5">Organic Tomato</a>
                                        <div className="d-flex my-3 justify-content-center">
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h4 className="mb-3">3.12 ₦</h4>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3">
                                <div className="text-center">
                                    <img src="img/fruite-item-3.jpg" className="img-fluid rounded" alt="" />
                                    <div className="py-4">
                                        <a href="#" className="h5">Organic Tomato</a>
                                        <div className="d-flex my-3 justify-content-center">
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h4 className="mb-3">3.12 ₦</h4>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-3">
                                <div className="text-center">
                                    <img src="img/fruite-item-4.jpg" className="img-fluid rounded" alt="" />
                                    <div className="py-2">
                                        <a href="#" className="h5">Organic Tomato</a>
                                        <div className="d-flex my-3 justify-content-center">
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star text-primary" />
                                            <i className="fas fa-star" />
                                        </div>
                                        <h4 className="mb-3">3.12 ₦</h4>
                                        <a href="#" className="btn border border-secondary rounded-pill px-3 text-primary"><i className="fa fa-shopping-bag me-2 text-primary" /> Add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="container-fluid py-5">
                    <div className="container">
                        <div className="bg-light p-5 rounded">
                            <div className="row g-4 justify-content-center">
                                <div className="col-md-6 col-lg-6 col-xl-3">
                                    <div className="counter bg-white rounded p-5">
                                        <i className="fa fa-users text-secondary" />
                                        <h4>satisfied customers</h4>
                                        <h1>1963</h1>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-3">
                                    <div className="counter bg-white rounded p-5">
                                        <i className="fa fa-users text-secondary" />
                                        <h4>quality of service</h4>
                                        <h1>99%</h1>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-3">
                                    <div className="counter bg-white rounded p-5">
                                        <i className="fa fa-users text-secondary" />
                                        <h4>quality certificates</h4>
                                        <h1>33</h1>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-3">
                                    <div className="counter bg-white rounded p-5">
                                        <i className="fa fa-users text-secondary" />
                                        <h4>Available Products</h4>
                                        <h1>789</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="container-fluid testimonial py-5">
                    <div className="container py-5">
                        <div className="testimonial-header text-center">
                            <h4 className="text-primary">Our Testimonial</h4>
                            <h1 className="display-5 mb-5 text-dark">Our Client Saying!</h1>
                        </div>
                        <div className="owl-carousel testimonial-carousel">
                            <div className="testimonial-item img-border-radius bg-light rounded p-4">
                                <div className="position-relative">
                                    <i className="fa fa-quote-right fa-2x text-secondary position-absolute" style={{ bottom: '30px', right: 0 }} />
                                    <div className="mb-4 pb-4 border-bottom border-secondary">
                                        <p className="mb-0">Lorem Ipsum is simply dummy text of the printing Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center flex-nowrap">
                                        <div className="bg-secondary rounded">
                                            <img src="img/testimonial-1.jpg" className="img-fluid rounded" style={{ width: '100px', height: '100px' }} alt="" />
                                        </div>
                                        <div className="ms-4 d-block">
                                            <h4 className="text-dark">Client Name</h4>
                                            <p className="m-0 pb-3">Profession</p>
                                            <div className="d-flex pe-5">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-item img-border-radius bg-light rounded p-4">
                                <div className="position-relative">
                                    <i className="fa fa-quote-right fa-2x text-secondary position-absolute" style={{ bottom: '30px', right: 0 }} />
                                    <div className="mb-4 pb-4 border-bottom border-secondary">
                                        <p className="mb-0">Lorem Ipsum is simply dummy text of the printing Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center flex-nowrap">
                                        <div className="bg-secondary rounded">
                                            <img src="img/testimonial-1.jpg" className="img-fluid rounded" style={{ width: '100px', height: '100px' }} alt="" />
                                        </div>
                                        <div className="ms-4 d-block">
                                            <h4 className="text-dark">Client Name</h4>
                                            <p className="m-0 pb-3">Profession</p>
                                            <div className="d-flex pe-5">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-item img-border-radius bg-light rounded p-4">
                                <div className="position-relative">
                                    <i className="fa fa-quote-right fa-2x text-secondary position-absolute" style={{ bottom: '30px', right: 0 }} />
                                    <div className="mb-4 pb-4 border-bottom border-secondary">
                                        <p className="mb-0">Lorem Ipsum is simply dummy text of the printing Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center flex-nowrap">
                                        <div className="bg-secondary rounded">
                                            <img src="img/testimonial-1.jpg" className="img-fluid rounded" style={{ width: '100px', height: '100px' }} alt="" />
                                        </div>
                                        <div className="ms-4 d-block">
                                            <h4 className="text-dark">Client Name</h4>
                                            <p className="m-0 pb-3">Profession</p>
                                            <div className="d-flex pe-5">
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                                <i className="fas fa-star text-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>




        </div>
    );
}

// export default CatFishShopIndex;
export default withLayout(CatfishLayout)(CatFishShopIndex);
