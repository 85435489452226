import event1 from '../../assets/images/event1.jpeg';
import event2 from '../../assets/images/event2.jpeg';
import mt1 from '../../assets/images/mt1.jpg';
import mt2 from '../../assets/images/mt2.jpg';
import mt3 from '../../assets/images/mt3.jpg';
import mt4 from '../../assets/images/mt4.jpg';
import mt5 from '../../assets/images/mt5.jpeg';
import mt6 from '../../assets/images/mt6.jpg';
import eventbanner from '../../assets/images/eventbanner.jpeg';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BankOutlined, BulbOutlined, FacebookFilled, HomeOutlined, InstagramFilled, LinkedinFilled, QuestionCircleOutlined, RightCircleOutlined, StarOutlined, TruckOutlined } from '@ant-design/icons';
import { FillSpinner } from "react-spinners-kit";
import { useEffect, useState } from 'react';
import { ConfigProvider, Button, Spin } from 'antd';
import { TinyColor } from '@ctrl/tinycolor';
import $ from "jquery"
// import FancyCarousel from '../../components/fancy-carousel';
import CarouselSlide from '../../components/carousel';
import { Link } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';
import ScrollAnimation from 'react-animate-on-scroll';
import MeetingGroundSide from '../../layouts/user/meeting-ground-side';
import MeetingGroundFooter from '../../layouts/user/meeting-ground-footer';


require("../../assets/css/meeting-ground.css")



const MeetingGround = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showCarousel, setshowCarousel] = useState(false);

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [installable, setInstallable] = useState(false);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [spinning, setSpinning] = useState(false);

    
   
    const handleCloseClick = () => {
        setShowInstallPrompt(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        enquireScreen((b) => {
            setIsMobile(b)
        });

setTimeout(() => {
    $("#preloader-meeting").delay(450).fadeOut("slow")

}, 5000);
       

    }, []);

    
    const closeSideMenu = () => {
        $("#preloader-meeting").delay(450).fadeOut("slow")
    }
    


    return (
     <>
            <MeetingGroundSide/>
            <div id="preloader-meeting">
                <div id="status">
                    <FillSpinner color="#fff" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600",  }}><span className='' style={{ color: "#fff" }}>Kobo<span style={{ color: "#fff" }}>Kist</span></span></p>
                    {/* <p className='loader-text' style={{ fontWeight: "400", fontSize: "13px" }}><span className='' style={{ color: "#fff" }}>Kobo<span style={{ color: "#fff" }}>Kist Meeting-Ground</span></span></p> */}
                </div>
            </div>
            <div className="site-wrap" id="home-section">
  <div className="site-mobile-menu site-navbar-target">
    <div className="site-mobile-menu-header">
      <div className="site-mobile-menu-close mt-3">
        <span className="icon-close2 js-menu-toggle" />
      </div>
    </div>
    <div className="site-mobile-menu-body" />
  </div>
  <header className="site-navbar site-navbar-target" role="banner">
    <div className="container">
      <div className="row align-items-center position-relative">
        <div className="col-lg-4">
          <nav className="site-navigation text-right ml-auto " role="navigation">
            <ul className="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                                        <li className="active"><a href="/meeting-ground" className="nav-link">Home</a></li>
              {/* <li><a href="#" className="nav-link">Contact</a></li>
                                        <li><a href="#" className="nav-link">&nbsp; &nbsp; &nbsp;</a></li> */}
            </ul>
          </nav>
        </div>
        <div className="col-lg-4 text-center">
          <div className="site-logo">
            <a href="#" className='outfit-500 '>Kobokist</a>
          </div>
          <div className="ml-auto toggle-button d-inline-block d-lg-none"><a href="#" className="site-menu-toggle py-5 js-menu-toggle text-white"><span className="icon-menu h3 text-white" /></a></div>
        </div>
        <div className="col-lg-4">
          <nav className="site-navigation text-left mr-auto" role="navigation">
            <ul className="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                                        <li><a href="#" className="nav-link">&nbsp; &nbsp; &nbsp;</a></li>
                      <li><Link to="/meeting-ground-list" className="nav-link">Meeting Grounds</Link></li>
              <li><a href="#" className="nav-link">Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <div className="ftco-blocks-cover-1">
                    <div className="ftco-cover-1 overlay" style={{ backgroundImage: `url(${event1})`}}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-12 text-center">
            <h1 className='outfit-500'>KOBOKIST Meeting Grounds</h1>
                                    <p className='outfit-400' style={{fontSize:"16px"}}>Discover the Perfect Venue for Your Events
                                      {/* : Book Our Premium Meeting Grounds with Ease */}
                                      </p>
                    <Link to="/meeting-ground-list" style={{ background:"#168b9a"}} className='btn btn-primary'>Book Now!</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container site-section">
    <div className="row justify-content-center text-center mb-5">
      <div className="col-md-6">
        <span className="text-primary outfit-400">What We do</span>
                            <h2 className="mb-4">Exceptional Spaces for Every Occasion</h2>
        <p>
                                At KoboKist, we offer premium meeting grounds tailored to your needs, ensuring every event is a success. Whether it's a business meeting, conference, or special gathering, our venues provide the perfect setting.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="feature-1">
          <img src={mt4} alt="Image" className="img-fluid" />
          {/* <div className="feature-1-contents">
            <h2>Title Name Here</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, officiis quo dicta molestias debitis.</p>
            <p><a href="#" className="more">Learn More</a></p>
          </div> */}
        </div>
      </div>
      <div className="col-lg-4">
        <div className="feature-1">
          <img src={mt2} alt="Image" className="img-fluid" />
          {/* <div className="feature-1-contents">
            <h2>Title Name Here</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, officiis quo dicta molestias debitis.</p>
            <p><a href="#" className="more">Learn More</a></p>
          </div> */}
        </div>
      </div>
      <div className="col-lg-4">
        <div className="feature-1">
          <img src={mt3} alt="Image" className="img-fluid" />
          {/* <div className="feature-1-contents">
            <h2>Title Name Here</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, officiis quo dicta molestias debitis.</p>
            <p><a href="#" className="more">Learn More</a></p>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div className="site-section section-2">
    <div className="container">
      <div className="row justify-content-center text-center mb-5 section-2-title">
        <div className="col-md-6 mt-5">
          {/* <span className="text-primary outfit-500">Our Features</span> */}
                                <h2 className="mb-4 ">Unmatched Venues for Memorable Events</h2>
                                <p className='text-white'>
                                    KoboKist delivers outstanding event spaces that cater to all your unique requirements. From intimate gatherings to large-scale conferences, our venues are designed to elevate your experience. Host your next event with us and make it unforgettable.
                                </p>
        </div>
      </div>
    </div>
    <div className="container site-section" style={{padding:"7px"}}>
      <div className="row align-items-stretch feature-2">
        <div className="col-lg-9 feature-2-img">
          <img src={mt6} alt="Image" className="img-fluid" />
        </div>
                            <div className="col-lg-3 feature-2-contents pl-lg-5">
                                <div className="fixed-content">
                                    <span className="caption">01.</span>
                                    <h3 className="mb-5">Elegant Ambiance</h3>
                                    <p className='text-white'>Experience a refined atmosphere designed to impress. Our hall offers a blend of luxury and comfort, perfect for any event.</p>
                                    <p className='text-white'>With sophisticated décor and attentive service, your guests will enjoy a truly memorable occasion.</p>
                                </div>
                            </div>

      </div>
    </div>
    <div className="container site-section">
      <div className="row align-items-stretch feature-2">
        <div className="col-lg-9 feature-2-img order-lg-2">
          <img src={mt2} alt="Image" className="img-fluid" />
        </div>
        <div className="col-lg-3 feature-2-contents pr-lg-5">
                                <div className="fixed-content">
                                    <span className="caption">02.</span>
                                    <h3 className="mb-5">State-of-the-Art Facilities</h3>
                                    <p className='text-white'>Our event hall is equipped with cutting-edge technology to meet all your event needs. From sound systems to lighting, we have it all.</p>
                                    <p className='text-white'>Enhance your experience with our top-tier audiovisual equipment and enjoy seamless presentations.</p>
                                </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div className="container site-section">
    <div className="row justify-content-center text-center mb-5">
      <div className="col-md-6">
        <span className="text-primary">Design for the future</span>
        <h2 className="mb-4">The technology for the future generation</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis provident eius ratione velit, voluptas laborum nemo quas ad necessitatibus placeat?</p>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-7">
        <div className="row">
          <div className="col-lg-6 mr-auto pr-lg-5">
            <div className="feature-3">
              <h3>Creative Technology</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores assumenda sequi magnam totam alias minima.</p>
            </div>
          </div>
          <div className="col-lg-6 ml-auto pl-lg-5">
            <div className="feature-3">
              <h3>World-class Quality</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores assumenda sequi magnam totam alias minima.</p>
            </div>
          </div>
          <div className="col-lg-6 mr-auto pr-lg-5">
            <div className="feature-3">
              <h3>Deep Resources</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores assumenda sequi magnam totam alias minima.</p>
            </div>
          </div>
          <div className="col-lg-6 ml-auto pl-lg-5">
            <div className="feature-3">
              <h3>World-class Quality</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores assumenda sequi magnam totam alias minima.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  <div className="site-section section-4">
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-md-7">
          <blockquote className="testimonial-1">
            <span className="quote quote-icon-wrap"><span className="icon-format_quote" /></span>
                                    <p>At KoboKist, we believe in turning your event dreams into reality. Our expert team is here to guide you every step of the way, ensuring an experience that exceeds your expectations.</p>
            {/* <cite><span className="text-black">Mercy Igho</span> — <span className="text-muted">CEO and Co-Founder</span></cite> */}
          </blockquote>
        </div>
      </div>
    </div>
  </div>
  
 <MeetingGroundFooter/>
</div>

     </>
    );



}

export default MeetingGround;