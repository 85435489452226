import kobologo from '../../assets/images/kobologo.jpeg';
import collage1 from '../../assets/images/collage-6.png';
// import hero1 from '../../assets/img/hero-1.png';
import hero1 from '../../assets/images/hero-1.jpg';
import logo22 from '../../assets/img/logoo8.png';
import about from '../../assets/img/about.png';
import st1 from '../../assets/images/st1.png';
import st2 from '../../assets/images/st2.png';
import st3 from '../../assets/images/st3.png';
import st4 from '../../assets/images/st4.png';
import compp from '../../assets/images/compp.jpeg';
import portfolio2 from '../../assets/img/portfolio2.png';
import portfolio3 from '../../assets/img/portfolio3.png';
import portfolio4 from '../../assets/img/portfolio4.png';
import realo from '../../assets/images/realo.jpg';
import testimonial from '../../assets/img/testimonial.png';
import bg1 from '../../assets/images/bg_1.jpg';
import bg3 from '../../assets/images/properties/banner-011.jpg';
import bg4 from '../../assets/images/properties/hero_bg_1.jpg';
import bg5 from '../../assets/images/properties/rl1.jpg';
import bg6 from '../../assets/images/properties/rl3.jpg';
import bg7 from '../../assets/images/alt.png';
import wys from '../../assets/images/wys.jpg';
import qua from '../../assets/images/qua.jpg';
import avv from '../../assets/images/avv.jpg';
import comm from '../../assets/images/comm.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BankOutlined, BulbOutlined, FacebookFilled, HomeOutlined, InstagramFilled, LinkedinFilled, QuestionCircleOutlined, RightCircleOutlined, StarOutlined, TruckOutlined } from '@ant-design/icons';
import { FillSpinner } from "react-spinners-kit";
import { useEffect, useState } from 'react';
import { ConfigProvider, Button, Spin, Modal } from 'antd';
import { TinyColor } from '@ctrl/tinycolor';
import $ from "jquery"
// import FancyCarousel from '../../components/fancy-carousel';
import CarouselSlide from '../../components/carousel';
import { Link } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';
import ScrollAnimation from 'react-animate-on-scroll';
import LandingSide from '../../layouts/user/landing-side';
import { INSTALLED_APP, triggerAppInstalledFunction } from '../../util/helpers';
import UserFooter from '../../layouts/user/footer';


// require("../../assets/css/main.css")
require("../../assets/css/style.css")
require("../../assets/css/landing.css")
require("../../assets/vendor/themify-icons/themify-icons.css")
// require("../../assets/vendor/owl-carousel/owl.theme.default.min.css")
// require("../../assets/vendor/owl-carousel/owl.carousel.min.css")


const ongoingObj = {
        name: "Emrald Estate",
        id: 1,
        location: "Enugu, Enugu State",
        // img: portfolio3,
    img: "https://images.deccanherald.com/deccanherald%2Fimport%2Fsites%2Fdh%2Ffiles%2Farticle_images%2F2020%2F05%2F19%2F622063-1125381429-1499720127.jpg?w=1200&ar=40%3A21&auto=format%2Ccompress&ogImage=true&mode=crop&enlarge=true&overlay=false&overlay_position=bottom&overlay_width=100",
        price: "450,000",
    description: "Transforming Vision into Reality: Explore the Projects We're Currently Developing. These initiatives are at the heart of our innovation and dedication, driving us towards new milestones and breakthroughs."
    }

const complObj = {
    name: "Emrald Estate",
    id: 1,
    location: "Enugu, Enugu State",
    img: compp,
    price: "450,000",
    description: "Celebrating Success: Discover the Projects We've Successfully Completed. Each of these achievements reflects our commitment to excellence and showcases our ability to deliver outstanding results."
}

const LandingThree = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showCarousel, setshowCarousel] = useState(false);

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [installable, setInstallable] = useState(false);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);
    const [iosPrompt, setiosPrompt] = useState(false);
    // const [isIOS, setIsIOS] = useState(false);
    const [spinning, setSpinning] = useState(false);


    const handleOk = () => {
        setiosPrompt(false);
    };
    const handleCancel = () => {
        setiosPrompt(false);
    };
    const handleInstallAppPrompt = () => {
       setShowInstallPrompt(true);
    }
    // useEffect(() => {
    //     window.addEventListener('beforeinstallprompt', (e) => {
    //         // Prevent the mini-infobar from appearing on mobile
    //         e.preventDefault();
    //         // Stash the event so it can be triggered later.
    //         setDeferredPrompt(e);
    //         // Update UI to notify the user they can install the PWA
    //         setInstallable(true);
    //     });

    //     return () => {
    //         window.removeEventListener('beforeinstallprompt', (e) => {
    //             setDeferredPrompt(null);
    //             setInstallable(false);
    //             setShowInstallPrompt(false);

    //         });
    //     };
    // }, []);

    const handleInstallClick = () => {
        setShowInstallPrompt(false);
        if (isIOS()) {
            setiosPrompt(true)
            // alert("For the best experience with our PWA, please use Safari to install the app on your home screen.");
            return;
        }
        console.log(deferredPrompt)
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                    triggerAppInstalledFunction(choiceResult.outcome)
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
                
                setInstallable(false);
            });
        }
    };
    const handleCloseClick = () => {
        setShowInstallPrompt(false);
    };
    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };
    useEffect(() => {
        const isAppInstalled = localStorage.getItem(INSTALLED_APP)
        console.log(isAppInstalled, "isAppInstalled")
        enquireScreen((b) => {
            setIsMobile(b)
        });
        setTimeout(() => {
            $("#fadeInText").delay(450).fadeIn("slow")
        }, 6000);
        setTimeout(() => {
            setshowCarousel(true)
        }, 10000);
        setTimeout(() => {
            $("#typewriter_body").delay(450).fadeOut("slow")
            // setshowCarousel(true)
        }, 11000);
    //     if (isAppInstalled != "accepted") {
    //     setTimeout(() => {
    //         setShowInstallPrompt(true);
            
    //     }, 20000);
    // }
        }, []);

        useEffect(() => {

            const isAppInstalled = localStorage.getItem(INSTALLED_APP)
            console.log(isAppInstalled, "isAppInstalled")

            const userAgent = window.navigator.userAgent.toLowerCase();
            // setIsIOS(/iphone|ipad|ipod/.test(userAgent));

            // if(isAppInstalled != "accepted"){
            

                const handleBeforeInstallPrompt = (e) => {
                    e.preventDefault();
                    setDeferredPrompt(e);
                    // setTimeout(() => {
                    //     setShowInstallPrompt(true);
                    // }, 17000);
                };

                window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

                return () => {
                    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
                };
            // }
        }, []);
    const closeSideMenu = () => {
        $("#drawer-kobo").delay(450).fadeOut("slow")
    }
    const toggleSideMenu = () => {
        
        $("#drawer-kobo").toggle("slow")

        // setSpinning(!spinning)
        return
        const sideMenu = document.querySelector('.landing-side-menu');
        const body = document.querySelector('body');

        if (sideMenu.classList.contains('open')) {
            sideMenu.classList.remove('open');
            body.classList.remove('no-scroll');
            sideMenu.style.display = "none";
        } else {
            sideMenu.style.display = "block";
                sideMenu.classList.add('open');
                body.classList.add('no-scroll');
        }
    }


    return (
        <>
            <LandingSide installFunc={handleInstallAppPrompt}/>
            <Modal  open={iosPrompt} onOk={handleOk} closeIcon={false} onCancel={handleCancel}>
                <p className='outfit-400' style={{ fontSize: "16px" }}>For the best experience with our app, iPhone users can uniquely install it on their home screen using <span style={{fontWeight:"700"}}>Safari</span>. Just follow these steps:</p>
                <div className='row'>
                    <div className='col-12 mb-5'>
                        <img src={st1}/>
                        <p  className='outfit-400 mt-2'>1. Tap the share icon at the bottom of the screen </p>
                    </div>
                    <div className='col-12 mb-5'>
                        <hr style={{ backgroundColor: "#ddd" }} />
                        <img src={st2} className='mt-3' />
                        <p style={{fontSize:"16px"}} className='outfit-500 mt-2'>2. Scroll down the menu and tap on "Add to Home Screen."</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <hr style={{ backgroundColor: "#ddd" }} />
                        <img src={st3} className='mt-3' />
                        <p className='outfit-500 mt-2'>3. Tap "Save" in the top-right corner to save the app to your home screen</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <hr style={{ backgroundColor: "#ddd" }} />
                        <img src={st4} className='mt-3' />
                        <p className='outfit-500 mt-2'>4. The app icon will now appear on your home screen. Tap it to launch the app directly.</p>
                    </div>
                </div>
            </Modal>
            {showInstallPrompt && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <div style={{
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '8px',
                        textAlign: 'center',
                        maxWidth: '400px',
                        width: '80%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}>
                        <h2 style={{ margin: '0 0 10px' }}>Install Our App</h2>
                        <p style={{ margin: '0 0 20px' }}>
                            Get the best experience by installing the KoboKist mobile app on your device.
                        </p>
                        <button
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#007bff',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                marginBottom: '10px',
                                marginRight: '10px',
                            }}
                            onClick={() => handleInstallClick()}
                        >
                            Install App
                        </button>
                        <button
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#6c757d',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleCloseClick()}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
            
            {/* <div id="preloaderBg"> */}
            {/* <div className='landing-side-menu'> */}
               
                <div id='typewriter_body'>
                    <div className='typewriter_container'>
                        <div>
                        <h1 className='typewriter_h1' style={{marginTop:"-10vh"}}>Welcome To KoboKist Limited</h1>
                        <p id='fadeInText' className='writer_p' style={{ color: "#999", textAlign: "center" }}>Your Partner in Real Estate and Home Services Excellence...</p>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            {/* </div> */}
            <div className='homebody'>
                
                {/*================End Offcanvus Menu Area =================*/}
                {/*================Canvus Menu Area =================*/}
                <div className="canvus_menu">
                    <div className="container">
                        <div className="float-right">
                            <div className="toggle_icon" title="Menu Bar" onClick={() => toggleSideMenu()}>
                                <span />
                            </div>
                        </div>
                    </div>
                </div>
                {/*================End Canvus Menu Area =================*/}
                <header>
                    <div className="hero">
                        <a className="navbar-brand" href="#">
                            <img src={logo22} alt="" style={isMobile ? {height:"70px", width:"70px"} : {width:"100px", height:"100px"}} />
                        </a>
                       
                        
                        <Carousel showArrows={true} transitionTime={1500} interval={5000} showThumbs={false} showStatus={true} autoPlay={showCarousel} stopOnHover={true} swipeable={false} infiniteLoop={true} >
                            <div className="item">
                                <div className="hero__slide">
                                    <img src={isMobile ? wys : bg7} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1 className='oswald-360'>Kobokist Limited</h1>
                                        <p className='text-land-default' style={isMobile ? { fontWeight: "400", fontSize: "14px" } : { fontWeight: "400", fontSize: "15px" }}>
                                            Welcome to Kobokist, where your dream home becomes a reality. Discover the perfect place to call home with us. Your journey to finding the ideal living space starts here.
                                        </p>
                                        <Link className="btn btn-dream btn--leftBorder btn--rightBorder" to="/properties">Get started</Link>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="hero__slide">
                                    <img src={bg3} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1 className='oswald-360' style={{fontSize:"2.1em"}}>Find Your Dream Home</h1>
                                        <p className='text-land-default' style={isMobile ? { fontWeight: "400", fontSize: "14px" } : { fontWeight: "400", fontSize: "15px" }}>Explore our curated collection of exquisite properties tailored to fit your lifestyle. From modern apartments to serene suburban homes, find your perfect match today.</p>
                                        <Link className="btn btn-dream btn--leftBorder btn--rightBorder" to="/properties">Get started</Link>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="hero__slide">
                                    <img src={isMobile ? bg7 : wys} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1 className='oswald-360'>Invest in Your Future</h1>
                                        <p className='text-land-default' style={isMobile ? { fontWeight: "400", fontSize: "14px" } : { fontWeight: "400", fontSize: "15px" }}>Unlock the potential of real estate investment. Our expert team is here to guide you through every step of the process, ensuring you make informed decisions that secure your financial future.</p>
                                        <Link className="btn btn-dream btn--leftBorder btn--rightBorder" to="/properties">Get started</Link>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="hero__slide">
                                    <img src={qua} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1 className='oswald-360'>Unmatched Quality</h1>
                                        <p className='text-land-default' style={isMobile ? { fontWeight: "400", fontSize: "14px" } : { fontWeight: "400", fontSize: "15px" }}>Our properties are built to the highest standards, offering you a seamless blend of elegance, functionality, and sustainability. Experience the difference with us.</p>
                                        <a className="btn btn-dream btn--leftBorder btn--rightBorder" href="#/">Get started</a>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="hero__slide">
                                    <img src={bg2} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1 className='oswald-360'>Kobokist Limited</h1>
                                        <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>If you are looking at blank cassettes on the web Lorem ipsum dolor sit amet, consectetur adipisicing  eiusmod tempor incididunt.</p>
                                        <a className="btn btn-dream btn--leftBorder btn--rightBorder" href="#/">Details</a>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div> */}
                        </Carousel>
                        
                        


                        {/* <div className="owl-carousel owl-theme heroCarousel">
                            <div className="item">
                                <div className="hero__slide">
                                    <img src={hero1} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1 className='oswald-360'>Kobokist Limited</h1>
                                        <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>If you are looking at blank cassettes on the web Lorem ipsum dolor sit amet, consectetur adipisicing  eiusmod tempor incididunt.</p>
                                        <a className="btn btn-dream btn--leftBorder btn--rightBorder" href="#/">Details</a>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="hero__slide">
                                    <img src={bg1} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1>Dream Heaven City</h1>
                                        <p>If you are looking at blank cassettes on the web Lorem ipsum dolor sit amet, consectetur adipisicing  eiusmod tempor incididunt.</p>
                                        <a className="btn btn--leftBorder btn--rightBorder" href="#/">Details</a>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="hero__slide">
                                    <img src={bg2} alt="" />
                                    <div className="hero__slideContent text-center">
                                        <h1>Dream Heaven City</h1>
                                        <p>If you are looking at blank cassettes on the web Lorem ipsum dolor sit amet, consectetur adipisicing  eiusmod tempor incididunt.</p>
                                        <a className="btn btn--leftBorder btn--rightBorder" href="#/">Details</a>
                                        <span className="hero__slideContent--right" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </header>
                <section className="about section-margin" style={{marginBottom:"9rem"}}>
                    <div className="container">
                        <div className="row align-items-center">
                            
                            <div className="col-md-5">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="about__img text-center text-md-left mb-5 mb-md-0">
                                    <img className="img-fluid" src={about} alt="" />
                                    <a href="#/" className="about__img__date text-center">
                                        <h3 className='oswald-360'>13</h3>
                                        <p style={{ fontWeight: "400", fontSize: "15px" }}>Year(s) <br /> of Creativity</p>
                                    </a>
                                </div>
                                </ScrollAnimation>
                            </div>
                           
                            <div className="col-md-7 pl-xl-5">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="section-intro">
                                    <h4 className="section-intro__title">About our Company</h4>
                                    <h2 className="section-intro__subtitle oswald-360">We've been creating <br /> Since 2011</h2>
                                </div>
                                <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>At Kobokist, we pride ourselves on transforming dreams into reality with our exceptional real estate offerings. If you're searching for the perfect home, look no further. Our properties combine luxury, comfort, and functionality, ensuring you find a space that meets your every need.</p>
                                <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Our commitment to excellence and customer satisfaction sets us apart. With years of experience in the industry, we provide a diverse range of properties that cater to various lifestyles and preferences. Whether you're looking for a modern apartment in the city or a serene suburban home, we have the perfect match for you.</p>
                                {/* <a className="btn btn-dream btn--rightBorder mt-4" href="#/">Read More</a> */}
                                </ScrollAnimation>
                            </div>
                           
                        </div>
                    </div>
                </section>

                <section className="tips tips-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* <div className="hero__slideContent text-center">
                                <h1 className='oswald-360'>Find Your Dream Home</h1>
                                <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Explore our curated collection of exquisite properties tailored to fit your lifestyle. From modern apartments to serene suburban homes, find your perfect match today.</p>
                                <a className="btn btn-dream btn--leftBorder btn--rightBorder" href="#/">Details</a>
                                <span className="hero__slideContent--right" />
                            </div> */}
                          
                            <div className="col-lg-7 mb-4 mb-lg-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <h2 className="section-intro__subtitle oswald-360">Your Dream, Our Mission</h2>
                                <p style={{ fontWeight: "400", fontSize: "15px" }}>
                                    At <span style={{fontWeight:"900"}}>KoboKist</span>, we are dedicated to transforming your real estate dreams into reality. With a passion for excellence and a commitment to personalized service, we strive to exceed your expectations at every turn. Whether you're looking for the perfect home, a dynamic office space, or a prime commercial property, our team of experts is here to guide you every step of the way. We believe in creating lasting relationships built on trust, integrity, and unparalleled expertise. Your vision is our drive, and your satisfaction is our ultimate goal.
                                </p>
                                </ScrollAnimation>
                            </div>
                           

                            <div className="col-lg-5 text-center text-lg-right">
                                <a className="btn btn-dream btn-dark btn--leftBorder btn--rightBorder" href="#/">Our Drive</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="overview pt-5 justify-content-center">
                    <div className="container justify-content-center">
                        <div className="row justify-content-center">
                          
                            <div className="col-sm-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="media align-items-center overview__single">
                                    <span className="overview__single__icon"><i className="ti-crown" /></span>
                                    <div className="media-body">
                                        <h3 className='oswald-500' >40+</h3>
                                        <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Projects undertaken</p>
                                    </div>
                                </div>
                                </ScrollAnimation>

                            </div>
                            
                            <div className="col-sm-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="media align-items-center overview__single">
                                    <span className="overview__single__icon"><i className="ti-face-smile" /></span>
                                    <div className="media-body">
                                        <h3 className='oswald-500'>80+</h3>
                                        <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Happy Clients</p>
                                    </div>
                                </div>
                                </ScrollAnimation>

                            </div>
                           
                            <div className="col-sm-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="media align-items-center overview__single">
                                    <span className="overview__single__icon"><i className="ti-user" /></span>
                                    <div className="media-body">
                                        <h3 className='oswald-500'>90+</h3>
                                        <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Real professionals</p>
                                    </div>
                                </div>
                                </ScrollAnimation>
                            </div>
                            
                           
                            {/* <div className="col-sm-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="media align-items-center overview__single">
                                    <span className="overview__single__icon"><i className="ti-gift" /></span>
                                    <div className="media-body">
                                        <h3 className='oswald-500'>2000+</h3>
                                        <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Exceeded expec...</p>
                                    </div>
                                </div>
                                </ScrollAnimation>
                            </div> */}
                           
                        </div>
                    </div>
                </section>
                <section className="portfolio section-margin">
                    <div className="container">
                        <div className="section-intro">
                            <h4 className="section-intro__title">OUR PORTFOLIO</h4>
                            <h2 className="section-intro__subtitle bottom-border oswald-360">What We Are About</h2>
                        </div>
                        <div className="row align-items-end pb-md-5 mb-4">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <div className="portfolio__img">
                                    <img className="img-fluid" src={realo} alt="" />
                                </div>
                            </div>
                            <div className="col-md-5 mb-5 pl-xl-5">
                                <ScrollAnimation animateIn='fadeInUp'
                                >

                              
                                <h4 className="section-intro__title left-border">Residential Living</h4>
                                <h2 className="section-intro__subtitle small oswald-360">Luxury Residential Living</h2>
                                <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Discover your dream home with our exclusive residential properties. Designed for comfort and elegance, our homes offer a perfect blend of style and functionality..</p>
                                {/* <a className="btn btn-dream btn--rightBorder mt-3" href="#/">Read More</a> */}
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row align-items-end pb-md-5 mb-4">
                            <div className="col-md-5 mb-5 pr-xl-5 order-2 order-md-1">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <h4 className="section-intro__title left-border">Commercial</h4>
                                <h2 className="section-intro__subtitle small oswald-360">Prime Commercial Real Estate</h2>
                                    <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>
                                        At Kobokist,we do not only bring you luxury homes and strategically located lots to key into! We also have  a team of experts,who meticulously design and build your dream home/estate. Turning your vision into reality. Contact us to discuss your building contracting needs!
</p>
                                {/* <a className="btn btn-dream btn--rightBorder mt-3" href="#/">Read More</a> */}
                            </ScrollAnimation>
                            </div>
                            <div className="col-md-7 mb-4 mb-md-0 order-1 order-md-2">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="portfolio__img">
                                    <img className="img-fluid" src={comm} alt="" />
                                </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                        {/* <div className="row align-items-end pb-md-5 mb-4">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="portfolio__img">
                                    <img className="img-fluid" src={portfolio3} alt="" />
                                </div>
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-5 mb-5 pl-xl-5">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <h4 className="section-intro__title left-border">Businesses</h4>
                                <h2 className="section-intro__subtitle small oswald-360">Office Spaces for Modern Businesses</h2>
                                <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>We provide innovative and flexible office spaces that cater to the dynamic needs of modern businesses. Our properties are designed to foster productivity and collaboration.</p>
                                <a className="btn btn-dream btn--rightBorder mt-3" href="#/">Read More</a>
                                </ScrollAnimation>
                            </div>
                        </div> */}
                        {/* <div className="row align-items-end pb-md-5 mb-4">
                            <div className="col-md-5 mb-5 pr-xl-5 order-2 order-md-1">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <h4 className="section-intro__title left-border">Colourful & catchy</h4>
                                <h2 className="section-intro__subtitle small oswald-360">Colorful Exterior Taste</h2>
                                <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>Transform your property's curb appeal with our vibrant and stylish exterior designs. We bring a splash of color and creativity to every project, making your property stand out beautifully.</p>
                                <a className="btn btn-dream btn--rightBorder mt-3" href="#/">Read More</a>
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-7 order-1 order-md-2 mb-4 mb-md-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="portfolio__img">
                                    <img className="img-fluid" src={portfolio4} alt="" />
                                </div>
                                </ScrollAnimation>
                            </div>
                        </div> */}
                        <div className="row align-items-end pb-md-5 mb-4">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <div className="portfolio__img">
                                        <img className="img-fluid" src={ongoingObj?.img} alt="" />
                                </div>
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-5 mb-5 pl-xl-5">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                <h4 className="section-intro__title left-border">Businesses</h4>
                                <h2 className="section-intro__subtitle small oswald-360">Ongoing Projects</h2>
                                    <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>
                                        {ongoingObj?.description}
                                        </p>
                                    <Link className="btn btn-dream btn--rightBorder mt-3" 
                                        to="/properties"
                                        state={{
                                            some: {
                                                routeProps: "Ongoing Projects",
                                                serviceitem: ongoingObj,
                                                // locationName: selectedLgaName
                                            }
                                        }}
                                    >See More</Link>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row align-items-end pb-md-5 mb-4">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                    <div className="portfolio__img">
                                        <img className="img-fluid" src={complObj?.img} alt="" />
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className="col-md-5 mb-5 pl-xl-5">
                                <ScrollAnimation animateIn='fadeInUp'
                                >
                                    <h4 className="section-intro__title left-border">Businesses</h4>
                                    <h2 className="section-intro__subtitle small oswald-360">Completed Projects</h2>
                                    <p className='text-color-landing' style={{ fontWeight: "400", fontSize: "15px" }}>
                                        {complObj?.description}
                                        </p>
                                    <Link className="btn btn-dream btn--rightBorder mt-3"
                                        to="/properties"
                                        state={{
                                            some: {
                                                routeProps: "Completed Projects",
                                                serviceitem: complObj,
                                                // locationName: selectedLgaName
                                            }
                                        }}
                                    >See More</Link>
                                </ScrollAnimation>
                            </div>
                        </div>
                        {/* <div className="text-center pt-2">
                            <button className="btn btn-dream btn--rightBorder btn--leftBorder">Learn More</button>
                        </div> */}
                    </div>
                </section>
                <section className="we-offer-area text-center bg-gray section-services">
                    <ScrollAnimation animateIn='fadeInUp'
                    >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="site-heading text-center">
                                    <h2>Other <span>Businesses</span></h2>
                                    <h4>Other businesses designed to enhance your lifestyle and meet your diverse needs.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row our-offer-items less-carousel">
                            {/* Single Item */}
                            <Link to={"home-services"} className="col-md-4 col-sm-6 equal-height">
                                <div className="item">
                                    {/* <i className="fas fa-pen-fancy" /> */}
                                    <i className="fa-solid fa-house-circle-check"/>
                                    <h4>Home Services</h4>
                                    <p>
                                        Experience top-notch home services tailored to meet your needs. From cleaning to repairs, our professional team ensures your home is in perfect condition.
                                    </p>
                                </div>
                            </Link>
                            {/* End Single Item */}
                            {/* Single Item */}
                                {/* /meeting-ground */}
                                <Link to={"/meeting-ground"} className="col-md-4 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fa-solid fa-school-flag"/>
                                    <h4>Meeting Grounds</h4>
                                    <p>
                                            Book the perfect meeting grounds for your next event. Explore our top locations and ensure your gatherings are a success. 
                                            <br />
                                            <br />
                                    </p>
                                </div>
                                </Link>
                            {/* End Single Item */}
                            {/* Single Item */}
                            <Link to={"catfish-shop"} className="col-md-4 col-sm-6 equal-height">
                                <div className="item">
                                    {/* <i className="fas fa-tasks" /> */}
                                    <i className="fa-solid fa-fish"/>
                                    <h4>Catfish Farming</h4>
                                    <p>
                                        Dive into sustainable aquaculture with our state-of-the-art catfish farming. Providing high-quality, fresh produce straight from our farm to your table.
                                    </p>
                                </div>
                            </Link>
                            {/* End Single Item */}
                            {/* Single Item */}
                            {/* <div className="col-md-4 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fas fa-tachometer-alt" />
                                    <h4>Porject Impliment</h4>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </div>
                            </div> */}
                            {/* End Single Item */}
                            {/* Single Item */}
                            {/* <div className="col-md-4 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fas fa-recycle" />
                                    <h4>Software Update</h4>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </div>
                            </div> */}
                            {/* End Single Item */}
                            {/* Single Item */}
                            {/* <div className="col-md-4 col-sm-6 equal-height">
                                <div className="item">
                                    <i className="fas fa-headset" />
                                    <h4>24/7 Support</h4>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </div>
                            </div> */}
                            {/* End Single Item */}
                        </div>
                    </div>
                    </ScrollAnimation>
                </section>

                <section className="testimonial section-margin homebody">
                    <div className="container">
                        <div className="section-intro">
                            <h4 className="section-intro__title">OUR Testimonial</h4>
                            <h2 className="section-intro__subtitle bottom-border oswald-360">What People Say About Us</h2>
                        </div>
                        <div className="owl-carousel owl-theme testimonialCarousel">
                            <div className="item">
                                <div className="media testimonial__slide">
                                    <img className="img-fluid mr-4" src={avv} width={120} height={125} alt="" />
                                    <div className="media-body">
                                        <blockquote style={{ fontWeight: "400", fontSize: "15px" }} className='outfit-400 text-color-landing'>
                                           "Finding our dream home was a seamless experience thanks to this incredible KoboKist real estate team. Their expertise and dedication were evident from the first meeting, and they made sure we felt supported every step of the way”
                                        </blockquote>
                                        <h3 className='oswald-360'>Emeka John</h3>
                                        {/* <p style={{ fontWeight: "400", fontSize: "15px" }}>Google Android</p> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="media testimonial__slide">
                                    <img className="img-fluid mr-4" src="img/testimonial.png" width={120} height={125} alt="" />
                                    <div className="media-body">
                                        <blockquote>
                                            “If you are looking at blank cassettes on the web lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt amet.”
                                        </blockquote>
                                        <h3>Falcon Astle</h3>
                                        <p>Google Android</p>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="media testimonial__slide">
                                    <img className="img-fluid mr-4" src="img/testimonial.png" width={120} height={125} alt="" />
                                    <div className="media-body">
                                        <blockquote>
                                            “If you are looking at blank cassettes on the web lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt amet.”
                                        </blockquote>
                                        <h3>Falcon Astle</h3>
                                        <p>Google Android</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="item">
                                <div className="media testimonial__slide">
                                    <img className="img-fluid mr-4" src="img/testimonial.png" width={120} height={125} alt="" />
                                    <div className="media-body">
                                        <blockquote>
                                            “If you are looking at blank cassettes on the web lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt amet.”
                                        </blockquote>
                                        <h3>Falcon Astle</h3>
                                        <p>Google Android</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="item">
                                <div className="media testimonial__slide">
                                    <img className="img-fluid mr-4" src="img/testimonial.png" width={120} height={125} alt="" />
                                    <div className="media-body">
                                        <blockquote>
                                            “If you are looking at blank cassettes on the web lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt amet.”
                                        </blockquote>
                                        <h3>Falcon Astle</h3>
                                        <p>Google Android</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <UserFooter/>
                {/* <footer className="footer footer-bg">
                    <div className="container">
                       
                        <div className="d-sm-flex justify-content-between footer__bottom top-border">
                            <p>
                                <a href="#" target="_blank">KoboKist 2024</a>
                                </p>
                           
                        </div>
                    </div>
                </footer> */}
            </div>

        </>
    );

    
    
}

export default LandingThree;