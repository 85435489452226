import React, { useEffect, useContext, useState } from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import { Link } from "react-router-dom";
import $ from "jquery"
import EmptyCart from "./empty-cart";
import { UserContext } from "../../context/userProvider";
import { nairaFormat } from "../../util/helpers";
import { baseContentURL } from "../../util/endpoint";


const TermsOfUse = () => {
    const userContext = useContext(UserContext);


    useEffect(() => {

        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return (
            <div className=" section section-padding-bottom mt-5">
                <div className="container">
                    <h1 className="">Terms and Conditions of Use for Kobokist Limited</h1>
                    <p><strong>Effective Date:</strong> 1st of September, 2024</p>

                    <p>
                        Welcome to Kobokist Limited! These Terms and Conditions ("Terms") govern your use of our website, platform, and services offered through kobokist.com (the "Site"). By accessing or using our Site, you agree to comply with and be bound by these Terms. If you disagree with these Terms, please do not use our services.
                    </p>

                    <h2>1. Introduction</h2>
                    <p>
                        Kobokist Limited ("we," "us," "our") is a multi-service platform that offers real estate services, an e-commerce shop for the sale of catfish (smoked and fresh), home services, and event venue bookings. These services are available through our website and mobile application.
                    </p>

                    <h2>2. Use of the Site</h2>
                    <h3>Eligibility:</h3>
                    <p>
                        By using the Site, you represent that you are at least 18 years old and capable of entering into a legally binding contract.
                    </p>
                    <h3>Account Registration:</h3>
                    <p>
                        To access certain features of our services, you may need to register an account. You agree to provide accurate and complete information and to keep this information up to date.
                    </p>
                    <h3>User Responsibilities:</h3>
                    <p>
                        You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                    </p>

                    <h2>3. Services Offered</h2>
                    <h3>Real Estate:</h3>
                    <p>
                        Kobokist Limited offers real estate services, including property listings, sales, and rentals. All property transactions are subject to separate agreements between buyers, sellers, and renters.
                    </p>
                    <h3>E-commerce (Catfish Sales):</h3>
                    <p>
                        We provide an online shop where customers can purchase smoked and fresh catfish. Orders, payment, and delivery details will be handled through our platform.
                    </p>
                    <h3>Home Services:</h3>
                    <p>
                        We offer various home services, including home cleaning, home chef, lock-smith, makeup and gele tying, hair braiding, babysitter/nanny services, manicure/pedicure, driver/car wash, grocery/errand services, and on-the-go car assistance.
                    </p>
                    <h3>Event Venue Booking:</h3>
                    <p>
                        Our platform allows users to book venues for events. Availability, pricing, and terms for venue bookings are provided on the Site.
                    </p>

                    <h2>4. Payment Terms</h2>
                    <h3>Pricing:</h3>
                    <p>
                        All prices for products and services are listed on the Site. Prices are subject to change without notice.
                    </p>
                    <h3>Payments:</h3>
                    <p>
                        Payments for all products and services must be made through the payment methods provided on the Site. By placing an order or booking a service, you authorize us to charge the payment method you have chosen.
                    </p>
                    <h3>Refunds and Cancellations:</h3>
                    <p>
                        Refund policies vary depending on the service or product. Please review specific refund and cancellation policies for each service on the Site.
                    </p>

                    <h2>5. User Conduct</h2>
                    <h3>Prohibited Activities:</h3>
                    <p>
                        You agree not to engage in any illegal activities, abuse, or fraudulent use of the Site. This includes but is not limited to misrepresentation of information, unauthorized use of the platform, or activities that harm or disrupt the services offered.
                    </p>
                    <h3>User Content:</h3>
                    <p>
                        You may be allowed to post reviews, comments, and other content on the Site. You retain ownership of your content but grant Kobokist Limited a non-exclusive, royalty-free, perpetual, and irrevocable license to use, modify, and distribute your content.
                    </p>

                    <h2>6. Intellectual Property</h2>
                    <p>
                        All content, trademarks, logos, and other intellectual property on the Site are the property of Kobokist Limited or its licensors. You may not use, reproduce, or distribute any content from the Site without our express permission.
                    </p>

                    <h2>7. Limitation of Liability</h2>
                    <p>
                        Kobokist Limited is not liable for any direct, indirect, incidental, or consequential damages arising out of your use of the Site or services provided. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses.
                    </p>

                    <h2>8. Indemnification</h2>
                    <p>
                        You agree to indemnify and hold harmless Kobokist Limited, its affiliates, and its respective officers, directors, employees, and agents from any claims, liabilities, damages, or expenses arising from your use of the Site or services.
                    </p>

                    <h2>9. Termination</h2>
                    <p>
                        We reserve the right to terminate or suspend your account and access to the Site at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties.
                    </p>

                    <h2>10. Governing Law</h2>
                    <p>
                        These Terms are governed by and construed in accordance with the laws of Nigeria, without regard to its conflict of law principles.
                    </p>

                    <h2>11. Changes to the Terms</h2>
                    <p>
                        Kobokist Limited reserves the right to update or modify these Terms at any time. Changes will be effective immediately upon posting on the Site. Your continued use of the Site after changes are posted constitutes your acceptance of the new Terms.
                    </p>

                    <h2>12. Contact Information</h2>
                    <p>
                        If you have any questions or concerns about these Terms, please contact us at:
                    </p>
                    <p>
                        Kobokist Limited<br />
                        47, Along Liberty Road, Oke-Ado, Ibadan, Oyo state<br />
                        Email: <a href="mailto:kobokistltd@gmail.com">kobokistltd@gmail.com</a>
                    </p>
                </div>
         </div>

    )
}
export default withLayout(UserLayout)(TermsOfUse)