import React, { useState, useRef } from 'react';
import { CameraOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Endpoint from '../../util/endpoint';
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';



require("../../assets/css/facial.css")

const FacialVerification = ({ visibilityProps, userId, bvn, nin, userDetails }) => {
    const [image, setImage] = useState(null);
    const [isCaptured, setIsCaptured] = useState(false);
    const [showCaptureButton, setshowCaptureButton] = useState(false);
    const [spinning, setSpinning] = useState(false);

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    function isNullOrEmpty(value) {
        return value === undefined || value === null || value === "";
    }

    const initiateKYCVerification = () => {
        setSpinning(true)
        // return
        const payload = {
            "userId": userId,
            "nin": !isNullOrEmpty(nin) ? nin : "",
            "bvn": !isNullOrEmpty(bvn) ? bvn : "",
            "facials": image
        }
        console.log(payload)
        // return
        Endpoint.requestKYCVerification(payload)
        .then((res) => {
            console.log(res?.data, "kyccc")
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "14px" }}>
                Verification request was successful. 
                <br/>
                {/* Please allow 12-24 hours for the processing to complete.  */}
                Processing typically takes between 3-4 hours
                You would get an email notification from us once complete.
                Thank you for your patience!
            </p>, {
                autoClose:10000
            });
            // setSpinning(false)
            setTimeout(() => {
                window.location.reload()
            }, 10000);
        })
        .catch((err) => {
            console.log(err)
            setSpinning(false)
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                {err?.data?.message}
            </p>);
        })
    }
    const handleGoBack = () => {
        if(showCaptureButton){
            stopCamera()
        }
        visibilityProps()
    }
    // Start the camera
    const startCamera = () => {
        setshowCaptureButton(true)
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((err) => {
                console.error("Error accessing the camera: ", err);
            });
    };

    // Capture the photo
    const capturePhoto = () => {
        const context = canvasRef.current.getContext('2d');
        // Draw the image from the video stream onto the canvas
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const imageData = canvasRef.current.toDataURL('image/png');
        setImage(imageData);
        setIsCaptured(true);
        stopCamera();
    };

    const retakePhoto = () => {
        setIsCaptured(false);
        // handleCaptureBtnVisibility()
        startCamera();
    };

    // Stop the camera
    const stopCamera = () => {
        const stream = videoRef?.current?.srcObject;
        const tracks = stream?.getTracks();

        tracks?.forEach((track) => {
            track.stop();
        });
        if (videoRef != null && videoRef.current != null){
            videoRef.current.srcObject = null;
        }
        
    };

    // Handle form submission or processing the image
    const handleSubmit = () => {
        if (image) {
            // Implement your facial verification logic here
            console.log("Image ready for verification:", image);
        }
    };

    return (
  <>
            <Spin
                spinning={spinning}
                indicator={<LoadingOutlined spin />} size="large"
                className="text-white"
                fullscreen />
            <div className="facial-verification-container">
                
                <div className='d-flex'>
                    <h2 onClick={() => handleGoBack()} style={{ fontSize: "15px", cursor: "pointer" }}> <span style={{ fontSize: "14px" }}><i className='fa fa-arrow-left text-left' /></span> &nbsp; Back to profile</h2>
                </div>
                <div className="capture-area">
                    {!isCaptured ? (
                        <>
                            <div className='row justify-content-center'>
                                <div className='col-12 col-md-6'>
                                    <video ref={videoRef} width="320" height="240" />
                                </div>
                            </div>
                            <p className='outfit-400 mt-2' style={{ fontSize: "14px", textAlign: "center", width: "100%", color: "#686868" }}>
                                Please ensure you are in a well-lit area before capturing your photo. Poor lighting may result in a failed verification.
                            </p>
                            {showCaptureButton &&
                                <button className="capture-button" onClick={capturePhoto}>
                                    <CameraOutlined style={{ fontSize: '18px' }} /> &nbsp;
                                    Capture Photo
                                </button>
                            }
                            {!showCaptureButton
                                &&
                                <button className="start-camera-button" style={{ fontSize: '15px' }} onClick={startCamera}>
                                    <UploadOutlined style={{ fontSize: '15px' }} /> &nbsp;
                                    Start Camera
                                </button>
                            }
                            <canvas ref={canvasRef} width="320" height="240" style={{ display: 'block' }} />
                        </>
                    ) : (
                        <div className="image-preview">
                            <img src={image} alt="Captured" />&nbsp;
                            <CheckCircleOutlined style={{ fontSize: '24px', color: 'green' }} />
                            <p>Image Captured!</p>
                                <button type='button' className="submit-button mr-3" onClick={() => initiateKYCVerification()}>
                                Submit for verification
                            </button>
                            <button className="submit-button" style={{ background: "#ff9900" }} onClick={retakePhoto}>
                                Retake
                            </button>
                        </div>
                    )}

                </div>
            </div>
  </>
    );
};

export default FacialVerification;
