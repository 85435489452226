import React, { useEffect } from 'react';
import withLayout from '../../layouts/withLayout';
import UserLayout from '../../layouts/user';

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])
    return (
        <div className='container' style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>&nbsp;</h1>

            <h2 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '10px' }}>About Us</h2>

            <p style={{ textAlign: 'justify' }}>
                At Kobokist, our mission is to ensure that every individual lives safely and comfortably while enjoying the
                epitome of luxury and satisfaction. We are dedicated to creating a living experience that is not only worthwhile
                but also aligns with our core values of safety, comfort, luxury, and satisfaction.
            </p>

            <h3 style={{ fontWeight: 'bold', marginTop: '20px' }}>What We Do</h3>

            <ul>
                <li style={{ marginBottom: '10px' }}>
                    <strong>Luxury Homes:</strong> We provide luxury homes for individuals across all classes, offering affordable
                    land and lots within serene estates that feature top-notch infrastructure and world-class recreational
                    amenities. We also handle construction needs through our contracting and consulting services.
                </li>

                <li style={{ marginBottom: '10px' }}>
                    <strong>Event Facilities:</strong> Our state-of-the-art facilities cater to diverse event needs, ensuring
                    exceptional experiences for our clients.
                </li>

                <li style={{ marginBottom: '10px' }}>
                    <strong>Home Services:</strong> We offer reliable home services, including roadside assistance, to meet your
                    needs safely and affordably.
                </li>

                <li style={{ marginBottom: '10px' }}>
                    <strong>Catfish Farming:</strong> Our large-scale catfish farming operations supply fresh and smoked catfish,
                    satisfying your culinary preferences with high-quality products.
                </li>
            </ul>

            <p style={{ textAlign: 'justify' }}>
                At Kobokist, customer satisfaction is our utmost priority. We are driven by the desire to bring your dreams to
                life and provide you with a luxurious living experience. Our commitment extends to the safety of our clients and
                employees, with all members and staff undergoing thorough ID verification.
            </p>

            <p style={{ textAlign: 'justify', fontWeight: 'bold', marginTop: '20px' }}>
                Join us in experiencing a blend of safety, comfort, luxury, and satisfaction with Kobokist—where your dreams are
                our daily inspiration.
            </p>
        </div>
    );
};

export default withLayout(UserLayout)(AboutUs);
