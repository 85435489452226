import React, {useEffect, useContext, useState} from "react";
import { Link } from "react-router-dom";
import $ from "jquery"
import EmptyCart from "./empty-cart";
import { UserContext } from "../../context/userProvider";
import { CAT_FISH_SHOP_PAYMENT, CATFISH_ROUTE_INDEX, KOBO_CART_FISH_SHOP, nairaFormat, PAYMENT_ROUTE_KEY } from "../../util/helpers";
import Endpoint, { baseContentURL } from "../../util/endpoint";
import CatFishHeader from "../../layouts/user/catfishheader";
import UserFooter from "../../layouts/user/footer";
import { Modal } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { FillSpinner } from "react-spinners-kit";
import CatfishFooter from "../../layouts/user/catfishfooter";


const FishShopCart = () => {
    const userContext = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [phone, setPhone] = useState(userContext?.userProfile?.phone);
    const [serviceaddress, setserviceaddress] = useState(userContext?.userProfile?.homeAddress);


    const handleRemoveFromCart = (item) => {
        userContext.removeFromCartRegular(item)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const processCartCheckout = () => {

        const payload = {
            "userId": userContext?.userProfile?.id,
            "serviceAddress": serviceaddress,
            "phone": phone,
            "requestedServices": userContext?.cartArrayRegular
        }
        if (phone == null || phone == "" || serviceaddress == null || serviceaddress == ""){
            alert("Make sure to enter a phone number and address")
            return;
        }
        $("#preloader").fadeIn("slow")
        Endpoint.fishShopCheckout(payload)
        .then((res) => {
            localStorage.setItem(PAYMENT_ROUTE_KEY, CAT_FISH_SHOP_PAYMENT);
            console.log(res?.data)
            setTimeout(() => {
                window.location.href = res?.data?.data?.authorization_url
            }, 1500);
        })
        .catch((err) => {
            console.log(err)
            $("#preloader").fadeOut("slow")
            toast.error(<p className="outfit-400 text-white" style={{ fontSize: "15px" }}>
                {err?.data?.message}
            </p>,
                {
                    autoClose: 10000
                }
            )
        })
    }
    useEffect(() => {
        // console.log(userContext?.userPayload, "userContext?.userPayload")
        // console.log(userContext?.cartArray, "userContext?.cartArray")
        
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return(
        <>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <Modal className="outfit-500"
                title="Payment Checkout" 
                closable
                footer={[<button onClick={() => processCartCheckout()} className="btn btn-primary btn-sm" style={{ height: "40px", background: "#2e61c8" }}>Process Payment</button>]} open={isModalOpen} onOk={handleOk} closeIcon={false} onCancel={handleCancel}>

                <div className="row justify-content-center mt-4">
                    
                        <div className=" col-12 mb-3">
                            <div className="text-left">
                            <label>Email Address</label>
                            <input value={userContext?.userProfile?.emailAddress} disabled name="email" id="current-pwd" placeholder="Enter Email Address" type="text" />
                            </div>
                        </div>
                    <div className=" col-12 mb-3">
                        <div className="text-left">
                            <label>Phone</label>
                            <input style={{ border: "1px solid #8080803b" }} onChange={(e) => setPhone(e.target.value)}  defaultValue={phone} className="bg-white"  name="phone" id="current-pwd" placeholder="Enter Phone" type="text" />
                        </div>
                    </div>
                    <div className=" col-12 mb-3">
                        <div className="text-left">
                            <label>Service Address</label>
                            <input style={{ border: "1px solid #8080803b" }} onChange={(e) => setserviceaddress(e.target.value)} defaultValue={serviceaddress} className="bg-white" name="address" id="current-pwd" placeholder="Enter Address" type="text" />
                        </div>
                    </div>
                   
                </div>
            </Modal>
        <CatFishHeader/>
            <div>
                {/* Page Title Section Start */}
                <div className="page-title-section section mt-3">
                    <div className="page-title">
                        <div className="container">
                            <h1 className="title">Cart</h1>
                        </div>
                    </div>
                    <div className="page-breadcrumb">
                        <div className="container">
                            <ul className="breadcrumb outfit-400">
                                <li><a href="index.html">Home/ </a></li>
                                <li className="current">Cart</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Page Title Section End */}
                {/*Cart section start*/}
                {userContext?.cartArrayRegular && userContext?.cartArrayRegular?.length > 0 ? 
                    <div className="cart-section section section-padding-bottom">
                        <div className="container faq-wrapper">
                            <div className="row">
                                <div className="col-12">
                                    {/* Cart Table */}
                                    <div className="cart-table table-responsive max-mb-30">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="pro-thumbnail">Image</th>
                                                    <th className="pro-title">Product</th>
                                                    <th className="pro-subtotal">Kg</th>
                                                    <th className="pro-subtotal">Price</th>
                                                    <th className="pro-remove">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userContext?.cartArrayRegular && userContext?.cartArrayRegular?.map((x, i) => {
                                                    return (
                                                        <tr>
                                                            <td className="pro-thumbnail outfit-500">
                                                                <a href="#"><img className="" src={baseContentURL + x?.imageUrl} alt="Product" /></a>
                                                            </td>
                                                            <td className="pro-title outfit-400" ><a href="#">{x?.name} {x?.extraInfo}</a></td>
                                                           
                                                            <td className="pro-subtotal outfit-400"><span>{x?.kg}</span></td>

                                                            {/* <td className="pro-price outfit-500"><span>10/07/2024</span></td> */}
                                                            {/* <td className="pro-title outfit-400">
                                                                <a href="#">{x?.name} {x?.durationInHours} </a>
                                                            </td> */}
                                                            <td className="pro-subtotal outfit-400"><span>{x?.price && nairaFormat(x?.price, 2)}</span></td>
                                                            <td className="pro-remove"><a href="#" onClick={() => handleRemoveFromCart(x)}><i className="fa fa-trash-alt" /></a></td>
                                                        </tr>
                                                    )
                                                })}




                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row max-mb-n30">
                                        <div className="col-lg-6 col-12 max-mb-30">
                                            <div className="discount-coupon">
                                                <h4>Discount Coupon Code</h4>
                                                <form action="#">
                                                    <div className="row max-mb-n30">
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <input type="text" placeholder="Coupon Code" />
                                                        </div>
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <button className="btn btn-primary btn-hover-secondary">Apply Code</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        {/* Cart Summary */}
                                        <div className="col-lg-6 col-12 max-mb-30 d-flex">
                                            <div className="cart-summary">
                                                <div className="cart-summary-wrap">
                                                    <h4>Cart Summary</h4>
                                                    
                                                    <p>Sub Total <span> {userContext?.cartTotalRegular && nairaFormat(userContext?.cartTotalRegular, 2)}</span></p>
                                                    <p>Logistics <span>{userContext?.logisticsPrice && nairaFormat(userContext?.logisticsPrice, 2)}</span></p>
                                                    <h2>Grand Total <span>{userContext?.cartTotalRegular && nairaFormat(userContext?.cartTotalRegular + userContext?.logisticsPrice, 2)}</span></h2>
                                                </div>
                                                <div className="cart-summary-button">
                                                    {userContext?.userPayload != null ?
                                                        <Link onClick={() => showModal()} className="mr-2">
                                                            <button className="btn btn-primary btn-hover-secondary">Checkout</button>
                                                        </Link> : 
                                                        <Link 
                                                            state={{
                                                                some: {
                                                                    displayProps: CATFISH_ROUTE_INDEX,
                                                                }
                                                            }}
                                                            to={"/login-register"} className="mr-2">
                                                            <button className="btn btn-primary btn-hover-secondary">Checkout</button>
                                                        </Link>
                                                }
                                                   

                                                    <button className="btn btn-primary btn-hover-secondary">Update Cart</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <EmptyCart/>
              }
                {/*Cart section end*/}
            </div>
<CatfishFooter/>
        </>
    )
}
export default FishShopCart;