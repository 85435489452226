import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import bgHomeService from "../../assets/images/servicecollage2.png"
import product1 from "../../assets/images/products/product/product-1.jpg"
import cookwoman from "../../assets/images/cookwoman.jpg"
import justclean from "../../assets/images/clean.jpg"
import gele from "../../assets/images/gelemakeup2.jpg"
import nanny2 from "../../assets/images/nanny2.jpg"
import yellogele from "../../assets/images/yellogele.png"
import gelemaka from "../../assets/images/gelemaka.jpg"
import collageuse from "../../assets/images/collageuse.png"
import deliveryman from "../../assets/images/deliveryman.png"
import braids from "../../assets/images/images.jpeg"
import side1 from "../../assets/images/products/widget/product-1.jpg"
import { FillSpinner, RotateSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { states } from "../../util/statestore";
import { ToastContainer, toast } from 'react-toastify';
import Endpoint, { baseContentURL } from "../../util/endpoint";




const MoreServices = () => {
    const { pathname } = useLocation();
    const location = useLocation();
    const { routeProps } = location.state?.some || {};
    const [selectedStateValue, setselectedStateValue] = useState('')
    const [selectedStateName, setselectedStateName] = useState('')
    const [selectedLgaValue, setselectedLgaValue] = useState('')
    const [selectedLgaName, setselectedLgaName] = useState('')
    const [lgaArr, setLgaArr] = useState([])
    const [serviceCategoryArr, setserviceCategoryArr] = useState([])
    const [fetching, setfetching] = useState(true)
    const navigate = useNavigate();


    const handleStateLocationSelect = (e) => {

        const selectedIndex = e.target.selectedIndex;
        const value = e.target.value;
        const text = e.target.options[selectedIndex].text;
        setselectedStateValue(value);
        setselectedStateName(text)

        setselectedLgaValue('');
        setselectedLgaName('')

        let filter = states?.filter(x => x.name == value);
        if(filter){
            setLgaArr(filter[0].lgas)
        }
    }

    const handleLGALocationSelect = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const value = e.target.value;
        const text = e.target.options[selectedIndex].text;
        setselectedLgaValue(value);
        setselectedLgaName(text)
        filterServicesByLocation(text)
    }
    const handleRouteToDetails = (item) => {
        console.log(item)
        // to = "/service-details"
        if(selectedLgaName == null || selectedLgaName == ""){
            toast.error(<p className="outfit-500 text-white text-center" style={{ fontSize: "13px" }}>Select location to get services available to your location before proceeding</p>);
            return;
        }

        // navigate("/service-details", {
        //     state: {
        //         some: {
        //             serviceItem: item,
        //         }
        //     }
        // });
    }

    const fetchAllServices = () => {
        Endpoint.allServiceCategories()
        .then((res) => {
            setserviceCategoryArr(res?.data)
            setfetching(false)
        })
        .catch((err) => {
            console.log(err, "err")
        })
    }

    const filterServicesByLocation = (data) => {
        setfetching(true)

        setserviceCategoryArr([])

        Endpoint.allServiceCategoriesByLocation(data)
            .then((res) => {
                setserviceCategoryArr(res?.data)
                setfetching(false)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    useEffect(() => {

        console.log(routeProps, "routeProps")
        window.scrollTo(0, 0);
        fetchAllServices()
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 4000);
    }, [pathname]);

    return (
        <div className="">
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            {/* <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1'><span className='adlam-display-regular-700' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div> */}
            <div>
                <div>
                    {/* Page Title Section Start */}
                    <div className="page-title-section section">
                        <div className="page-title mt-3">
                            <div className="container">
                                <h2 className="title">Hire a professional </h2>
                                <p className="text-center">
                                    {/* (Service Categories) */}
                                    {selectedLgaName != '' ?
                                        <p className="result-count">We found <span>{serviceCategoryArr?.length}</span> service(s) available within {selectedLgaName != '' ? selectedLgaName + "," : ""} {selectedStateName}</p> :
                                        <p className="result-count text-center">Filter to see service(s) available within your location</p>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="page-breadcrumb mt-4">
                            <div className="container">
                                <ul className="breadcrumb">
                                    <li><a className="outfit-500">Home/ </a></li>
                                    <li className="current outfit-500"> Service Categories</li>
                                </ul>
                            </div>
                        </div>
                    </div>
            
                    <div className="section section-padding-bottom">
                        <div className="container">
                            <div className="row max-mb-n50">
                                <div className="col-xl-9 col-lg-8 col-12 order-lg-2 max-mb-50">
                                    {/* Course Top Bar Start */}
                                    {/* <div className="row justify-content-between align-items-center max-mb-20">
                                        <div className="col-sm-auto col-12 max-mb-10">
                                        </div>
                                        <div className="col-sm-auto col-12 max-mb-10">
                                                    <select onChange={(e) => handleStateLocationSelect(e)} className="sort-by form-control outfit-600" style={{ backgroundColor: "#f5f5f5", border: "none" }}>
                                                        <option selected="selected">Select Location</option>
                                                {states.map((x, i) => {
                                                    return(
                                                        <option value={x.name}>{x.name}</option>
                                                    )
                                                })}
                                                    </select>
                                            {selectedStateValue != ''  ? 
                                                <select value={selectedLgaValue} onChange={(e) => handleLGALocationSelect(e)} className="sort-by form-control outfit-600 mt-1" style={{ backgroundColor: "#f5f5f5", border: "none" }}>
                                                    <option selected="">Filter by Lga</option>
                                                    {lgaArr && lgaArr?.map((x, i) => {
                                                        return(
                                                            <option value={x}>{x}</option>
                                                        )
                                                    })}
                                                </select> :
                                                null    
                                        
                                        }
                                           
                                             
                                        </div>
                                    </div> */}
                                    {fetching &&
                                        <center className="mb-5">
                                            <RotateSpinner color="#ababab" />

                                        </center>
                                    }
                                    <div className="row row-cols-xl-3 row-cols-sm-2 row-cols-1 max-mb-n40 ">
                                        
                                        
                                       
                                        {/* Product Start */}
                                        {!fetching && serviceCategoryArr && serviceCategoryArr?.map((x, i) => {
                                        return(
                                            <div className="col max-mb-40 "
                                                // style={{height:"100%"}}
                                                data-aos="fade-up">
                                                <div className="product" >
                                                    <div className="thumbnail"
                                                    // style={{ height: "77%" }}
                                                    >
                                                        {/* {!selectedLgaName ?

                                                            <Link
                                                                onClick={() => handleRouteToDetails(x)}
                                                                className="image">
                                                                <img src={baseContentURL + x?.imageUrl} alt="" />
                                                            </Link> : */}
                                                            <Link
                                                                className="image"
                                                                to={"/service-details"}
                                                                state={{
                                                                    some: {
                                                                        routeProps: routeProps,
                                                                        serviceitem: x,
                                                                        // locationName: selectedLgaName
                                                                    }
                                                                }}
                                                                ><img src={baseContentURL + x?.imageUrl} alt="" /></Link>
                                                                {/* } */}
                                                        {/* <a className="image"><img src={baseContentURL + x?.imageUrl} alt="" /></a> */}
                                                        <div className="actions">
                                                           
                                                            {/* <a href="#" className="action hintT-left hintT-primary" data-hint="Add to cart"><i className="fa fa-shopping-cart" /></a>
                                                            <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Add to wishlist"><i className="fa fa-heart" /></a>
                                                            <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Compare"><i className="fa fa-signal" /></a> */}
                                                        </div>
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="title"><Link href="#" className="text-black outfit-600">{x?.name}</Link></h3>
                                                        <span className="price outfit-600">{x?.subCaption}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                      })}
                                       

                                        {/* Product end */}
                                        {/* Product Start */}
                                        {/* <div className="col max-mb-40 "
                                            data-aos="fade-up">
                                            <div className="product">
                                                <div className="thumbnail" 
                                                >
                                                    <a  className="image"><img src={gelemaka} alt="" /></a>
                                                    <div className="actions">
                                                        <a  data-toggle="modal" className="action hintT-left hintT-primary" data-hint="Quick view"><i className="fa fa-search" /></a>
                                                        <a href="#" className="action hintT-left hintT-primary" data-hint="Add to cart"><i className="fa fa-shopping-cart" /></a>
                                                        <a  className="action hintT-left hintT-primary" data-hint="Add to wishlist"><i className="fa fa-heart" /></a>
                                                        <a  className="action hintT-left hintT-primary" data-hint="Compare"><i className="fa fa-signal" /></a>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h3 className="title"><Link href="#" className="text-black outfit-600">Makeup/Gele</Link></h3>
                                                    <span className="price outfit-600">N5,000.00/hr</span>
                                                </div>
                                            </div>
                                        </div> */}
                                       
                                        {/* <div className="col max-mb-40 "
                                            data-aos="fade-up">
                                            <div className="product">
                                                <div className="thumbnail"
                                                >
                                                    <a href="product-details.html" className="image"><img src={collageuse} alt="" /></a>
                                                    <div className="actions">
                                                        <a href="#" data-toggle="modal" className="action hintT-left hintT-primary" data-hint="Quick view"><i className="fa fa-search" /></a>
                                                        <a href="#" className="action hintT-left hintT-primary" data-hint="Add to cart"><i className="fa fa-shopping-cart" /></a>
                                                        <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Add to wishlist"><i className="fa fa-heart" /></a>
                                                        <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Compare"><i className="fa fa-signal" /></a>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h3 className="title"><Link href="#" className="text-black outfit-600">Chef/Cook</Link></h3>
                                                    <span className="price outfit-600">N5,000.00/hr</span>
                                                </div>
                                            </div>
                                        </div> */}
                                       
                                        {/* <div className="col max-mb-40 "
                                            data-aos="fade-up">
                                            <div className="product" >
                                                <div className="thumbnail"
                                                >
                                                    <a href="product-details.html" className="image"><img src={deliveryman} alt="" /></a>
                                                    <div className="actions">
                                                        <a href="#" data-toggle="modal" className="action hintT-left hintT-primary" data-hint="Quick view"><i className="fa fa-search" /></a>
                                                        <a href="#" className="action hintT-left hintT-primary" data-hint="Add to cart"><i className="fa fa-shopping-cart" /></a>
                                                        <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Add to wishlist"><i className="fa fa-heart" /></a>
                                                        <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Compare"><i className="fa fa-signal" /></a>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h3 className="title"><Link href="#" className="text-black outfit-600">Home Cleaning</Link></h3>
                                                    <span className="price outfit-600">N5,000.00/hr</span>
                                                </div>
                                            </div>
                                        </div> */}
{/*                                         
                                        <div className="col max-mb-40 "
                                            data-aos="fade-up">
                                            <div className="product">
                                                <div className="thumbnail"
                                                >
                                                    <a href="#" className="image"><img src={braids} alt="" /></a>
                                                    <div className="actions">
                                                        <a href="#" data-toggle="modal" className="action hintT-left hintT-primary" data-hint="Quick view"><i className="fa fa-search" /></a>
                                                        <a href="#" className="action hintT-left hintT-primary" data-hint="Add to cart"><i className="fa fa-shopping-cart" /></a>
                                                        <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Add to wishlist"><i className="fa fa-heart" /></a>
                                                        <a href="JavaScript:Void(0);" className="action hintT-left hintT-primary" data-hint="Compare"><i className="fa fa-signal" /></a>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h3 className="title"><Link href="#" className="text-black outfit-600">Home Cleaning</Link></h3>
                                                    <span className="price outfit-600">N5,000.00/hr</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* Product end */}
                                       
                                    </div>
                                    {/* Product Wrapper End */}
                                    {/* Pagination Start */}
                                    {/* <div className="row max-mt-50">
                                        <div className="col">
                                            <ul className="pagination center">
                                                <li><a href="#" className="prev">PREV</a></li>
                                                <li><a href="#" className="active">1</a></li>
                                                <li><a href="#">2</a></li>
                                                <li><a href="#" className="next">NEXT</a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* Pagination End */}
                                </div>
                                {/* Sidebar Widget Wrapper Start */}
                                <div className="col-xl-3 col-lg-4 col-12 order-lg-1 max-mb-50">
                                    <div className="sidebar-widget-wrapper pr-0">
                                        {/* Widget Search Start */}
                                        <div className="sidebar-widget">
                                            <h3 className="sidebar-widget-title">Search</h3>
                                            <div className="sidebar-widget-content">
                                                <div className="sidebar-widget-search">
                                                    <form action="#">
                                                        <input type="text" placeholder="Search..." />
                                                        <button><i className="fas fa-search" /></button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Widget Search End */}
                                        {/* Widget Price Filter Start */}
                                        {/* <div className="sidebar-widget">
                                            <h3 className="sidebar-widget-title">Filter by price</h3>
                                            <div className="sidebar-widget-content">
                                                <div className="sidebar-widget-price-filter">
                                                    <div className="price-range" data-min={0} data-max={100} data-from={0} data-to={100} />
                                                    <button className="btn btn-primary btn-hover-secondary btn-price-filter">Filter</button>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* Widget Price Filter End */}
                                        {/* Widget Product Start */}
                                        <div className="sidebar-widget">
                                            <h3 className="sidebar-widget-title">Popular services</h3>
                                            <div className="sidebar-widget-content">
                                                <div className="sidebar-widget-products">
                                                    <div className="widget-product">
                                                        <a href="product-details.html" className="thumbnail"><img src={side1} alt="" /></a>
                                                        <div className="info">
                                                            <h6 className="title"><a href="#" className="outfit-500">Home Cleaning</a></h6>
                                                            {/* <span className="rating"><span /></span> */}
                                                            <span className="price outfit-500"> <span className="old">N9,000.00</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="widget-product">
                                                        <a href="product-details.html" className="thumbnail"><img src={side1} alt="" /></a>
                                                        <div className="info">
                                                            <h6 className="title"><a href="#" className="outfit-500">Gele & Makeup</a></h6>
                                                            {/* <span className="rating"><span /></span> */}
                                                            <span className="price outfit-500"><span className="old">N9,000.00</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="widget-product">
                                                        <a href="product-details.html" className="thumbnail"><img src={side1} alt="" /></a>
                                                        <div className="info">
                                                            <h6 className="title"><a href="#" className="outfit-500">Cook/Chef</a></h6>
                                                            {/* <span className="rating"><span /></span> */}
                                                            <span className="price outfit-500"> <span className="old">N9,000.00</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="widget-product">
                                                        <a href="product-details.html" className="thumbnail"><img src={side1} alt="" /></a>
                                                        <div className="info">
                                                            <h6 className="title"><a href="#" className="outfit-500">Drivers</a></h6>
                                                            {/* <span className="rating"><span /></span> */}
                                                            <span className="price outfit-500"> <span className="old">N9,000.00</span></span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* Widget Product End */}
                                        {/* Widget Tags Start */}
                                        {/* <div className="sidebar-widget">
                                            <h3 className="sidebar-widget-title">Popular tags</h3>
                                            <div className="sidebar-widget-content">
                                                <div className="tagcloud">
                                                    <a href="#">business</a>
                                                    <a href="#">business administration</a>
                                                    <a href="#">computer</a>
                                                    <a href="#">Computer science</a>
                                                    <a href="#">development</a>
                                                    <a href="#">digital</a>
                                                    <a href="#">early education</a>
                                                    <a href="#">learning</a>
                                                    <a href="#">marketing</a>
                                                    <a href="#">online learning</a>
                                                    <a href="#">programming</a>
                                                    <a href="#">seo</a>
                                                    <a href="#">software</a>
                                                    <a href="#">teaching</a>
                                                    <a href="#">website</a>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* Widget Tags End */}
                                    </div>
                                </div>
                                {/* Sidebar Widget Wrapper End */}
                            </div>
                        </div>
                    </div>




                </div>

            </div>

        </div>
    );
}

export default withLayout(UserLayout)(MoreServices);