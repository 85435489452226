import { Link, useLocation } from 'react-router-dom';
import kobologo from '../../assets/images/kobologo.jpeg';
import koboL1 from '../../assets/images/koboL1.png';
import koboL2 from '../../assets/images/koboL2.png';
import { useEffect, useState, useContext } from "react";
import { UserContext } from '../../context/userProvider';
import $ from "jquery"
import { Avatar, Badge } from 'antd';
import { activeSessionCheck } from '../../util/auth';
import { enquireScreen } from 'enquire-js';


const UserHeader = () => {
    const userContext = useContext(UserContext);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    let url = location?.pathname;

    const toggleMobileMenuVisibility = () => {
       // const url = location.pathname; // Now this will always have the latest path
        //const url = window.location.pathname;
        // console.log(path)
        // console.log(url);
        if (url.includes("userindex")) {
            $("#kobo-side-menu").toggle('slow');
        } else {
            $(`#${'site-main-mobile-menu'}`).animate({ width: 400 }, 400);
        }
    };
    const hotNavigate = () => {
        window.location.href = "/userindex"
    }
    const dashNav = () => {
        // const url = location.pathname; // Now this will always have the latest path
        //const url = window.location.pathname;
        // console.log(path)
        
            $("#kobo-side-menu").toggle('slow');
       
    };

    const closeMobileMenu = () => {
        const menuElement = document.getElementById('site-main-mobile-menu');
        menuElement.style.width = '0px';
    }
    useEffect(() => {
        //url = location?.pathname;
        enquireScreen((b) => {
            setIsMobile(b)
        });
    }, [])


    return (
       <>
          
            {/* Header Section Start */}
            <div className="header-section header-shadow sticky-header section">
                <div className="header-inner" style={{position:"fixed", width:"100%", zIndex:99}}>
                    <div className="container position-relative">
                        <div className="row justify-content-between align-items-center">
                            {/* Header Logo Start */}
                            <div className="col-xl-3 col-auto">
                                <div className="header-logo">
                                    <Link to={"/"}>
                                        <span className='outfit-700 logo-nav-text' style={{ color: "#076fcc", display:"inline-flex", alignItems:"center" }}>
                                            <img  className="kobo-logo-nav" src={koboL2} alt="Kobokist Logo" />

                                            Kobo<span style={{ color: "#ff9f2f" }}>Kist</span></span> 
                                        {/* <img className="dark-logo kobo-logo-nav" src={kobologo} alt="Kobokist Logo" /> */}
                                        {/* <img className="light-logo kobo-logo-nav" src={kobologo} alt="Kobokist Logo" /> */}
                                    </Link>
                                </div>
                            </div>
                            {/* Header Logo End */}
                            {/* Header Main Menu Start */}
                            <div className="col d-none d-xl-block position-static">
                                <nav className="site-main-menu menu-hover-1">
                                    <ul>
                                        <li className="has-children position-static">
                                            <Link to={"/home-services"}><span className="menu-text">Home</span></Link>
                                            {/* <span className="menu-toggle"><i className="far fa-angle-down" /></span> */}
                                            {/* <ul className="mega-menu">
                                                <li>
                                                    <ul>
                                                        <li><a href="index.html"><span className="menu-text">KoboKist Education <span className="badge">Hot</span></span></a></li>
                                                        <li><a href="index-2.html"><span className="menu-text">Course Portal</span></a></li>
                                                        <li><a href="index-3.html"><span className="menu-text">Distant Learning <span className="badge">Hot</span></span></a></li>
                                                        <li><a href="index-4.html"><span className="menu-text">Multimedia Pedagogy</span></a></li>
                                                        <li><a href="index-5.html"><span className="menu-text">Modern Schooling</span></a></li>
                                                        <li><a href="index-6.html"><span className="menu-text">Remote Training</span></a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li><a href="index-7.html"><span className="menu-text">Health Coaching</span></a></li>
                                                        <li><a href="index-8.html"><span className="menu-text">Gym Coaching</span></a></li>
                                                        <li><a href="index-9.html"><span className="menu-text">Business</span></a></li>
                                                        <li><a href="index-10.html"><span className="menu-text">Artist <span className="badge primary">New</span></span></a></li>
                                                        <li><a href="index-11.html"><span className="menu-text">Kitchen Coach <span className="badge primary">New</span></span></a></li>
                                                        <li><a href="index-12.html"><span className="menu-text">Motivation <span className="badge primary">New</span></span></a></li>
                                                    </ul>
                                                </li>
                                                <li className="menu-item-50">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/images/menu/mega-menu.jpg" alt="menu-image" /></a></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </li>
                                        <li className="has-children">
                                            <Link to="/more-services"><span className="menu-text">Find a professional</span></Link>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                            {/* <ul className="sub-menu">
                                                <li><a href="start-here.html"><span className="menu-text">Start Here</span></a></li>
                                                <li><a href="success-story.html"><span className="menu-text">Success Story</span></a></li>
                                                <li><a href="about-me.html"><span className="menu-text">About me</span></a></li>
                                                <li><a href="about-us-1.html"><span className="menu-text">About us 01</span></a></li>
                                                <li><a href="about-us-2.html"><span className="menu-text">About us 02</span></a></li>
                                                <li><a href="about-us-3.html"><span className="menu-text">About us 03</span></a></li>
                                                <li><a href="contact-me.html"><span className="menu-text">Contact me</span></a></li>
                                                <li><a href="contact-us.html"><span className="menu-text">Contact us</span></a></li>
                                                <li><a href="purchase-guide.html"><span className="menu-text">Purchase Guide</span></a></li>
                                                <li><a href="privacy-policy.html"><span className="menu-text">Privacy Policy</span></a></li>
                                                <li><a href="terms-of-service.html"><span className="menu-text">Terms of Service</span></a></li>
                                            </ul> */}
                                        </li>
                                        {/* <li className="has-children">
                                            <a href="#"><span className="menu-text">Courses</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                            <ul className="sub-menu">
                                                <li><a href="courses-grid-1.html"><span className="menu-text">Courses Grid 01</span></a></li>
                                                <li><a href="courses-grid-2.html"><span className="menu-text">Courses Grid 02</span></a></li>
                                                <li><a href="courses-grid-3.html"><span className="menu-text">Courses Grid 03</span></a></li>
                                                <li><a href="membership-levels.html"><span className="menu-text">Membership Levels</span></a></li>
                                                <li><a href="become-a-teacher.html"><span className="menu-text">Become a Teacher</span></a></li>
                                                <li><a href="profile.html"><span className="menu-text">Profile</span></a></li>
                                                <li><a href="checkout.html"><span className="menu-text">Checkout</span></a></li>
                                                <li className="has-children">
                                                    <a href="course-details-sticky-feature-bar.html"><span className="menu-text">Single Layout</span></a>
                                                    <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                                    <ul className="sub-menu">
                                                        <li><a href="course-details-free.html"><span className="menu-text">Free Course</span></a></li>
                                                        <li><a href="course-details-sticky-feature-bar.html"><span className="menu-text">Sticky Features Bar</span></a></li>
                                                        <li><a href="course-details-standard-sidebar.html"><span className="menu-text">Standard Sidebar</span></a></li>
                                                        <li><a href="course-details-no-sidebar.html"><span className="menu-text">No Sidebar</span></a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li> */}
                                        {!activeSessionCheck() && 
                                            <li className="has-children">
                                                <Link to="/login-register"><span className="menu-text">SignIn/Register</span></Link>

                                                {/* <ul className="sub-menu">
                                                <li><a href="event.html"><span className="menu-text">Event</span></a></li>
                                                <li><a href="zoom-meetings.html"><span className="menu-text">Zoom Meetings</span></a></li>
                                                <li><a href="event-details.html"><span className="menu-text">Event Details</span></a></li>
                                                <li><a href="zoom-event-details.html"><span className="menu-text">Zoom Meeting Details</span></a></li>
                                            </ul> */}
                                            </li>
                                        }
                                        <li className="has-children">
                                            <Link to="/subscription-plans"><span className="menu-text">Subscriptions</span></Link>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        </li>
                                        {/* <li className="has-children">
                                            <a href="#"><span className="menu-text">Blog</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                            <ul className="sub-menu"
                                                <li><a href="blog-grid.html"><span className="menu-text">Blog Grid</span></a></li>
                                                <li><a href="blog-masonry.html"><span className="menu-text">Blog Masonry</span></a></li>
                                                <li><a href="blog-classic.html"><span className="menu-text">Blog Classic</span></a></li>
                                                <li><a href="blog-list.html"><span className="menu-text">Blog List</span></a></li>
                                            </ul>
                                        </li> */}
                                        {/* <li className="has-children">
                                            <a href="#"><span className="menu-text">Our Mission</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down" /></span>
                                        </li> */}
                                    </ul>
                                </nav>
                            </div>
                            {/* Header Main Menu End */}
                            {/* Header Right Start */}
                            <div className="col-xl-3 col-auto">
                                <div className="header-right">
                                    <div className="inner">
                                        {/* Header Login Start */}
                                       {/* {activeSessionCheck() &&
                                            <div className="header-login">
                                                <Link
                                                    to="/login-register"><i className="fa fa-user-circle" /></Link>
                                            </div> 
                                       
                                    } */}
                                       
                                        {/* <div className="header-login">
                                            <Link to="/service-cart"><i className="fa fa-shopping-cart" /></Link>

                                        </div> */}
                                        <Link to={"/service-cart"} className='mr-3'>
                                            <Badge 
                                            count={userContext?.cartArray?.length > 0 ? userContext?.cartArray?.length : 0} 
                                            // size='small'
                                            // count={2}
                                            overflowCount={10}
                                                // size='small'
                                            >
                                                {/* <Avatar shape="square" size="large" /> */}
                                                <i className="fa fa-shopping-cart" style={{ fontSize: "23px" }} />
                                            </Badge>
                                        </Link>
                                        {/* Header Login End */}
                                        {/* Header Search Start */}
                                        {activeSessionCheck() &&
                                            <div className="header-login">
                                                <Link to={"/userindex"} className='outfit-500'>{userContext?.userProfile?.firstName?.length > 7 ? userContext?.userProfile?.firstName.substring(0, 6) + "..." : userContext?.userProfile?.firstName}  &nbsp;<i className="fa fa-user-circle" /></Link>
                                            </div>

                                        }
                                        {/* <div className="header-search">
                                            <button className="header-search-toggle"><i className="far fa-search" /></button>
                                            <div className="header-search-form">
                                                <form action="#">
                                                    <input type="text" placeholder="Search..." />
                                                    <button><i className="fas fa-search" /></button>
                                                </form>
                                            </div>
                                        </div> */}
                                        {/* Header Search End */}
                                        {/* Header Mobile Menu Toggle Start */}
                                        <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                                            {/* {url?.includes("userindex")
                                            ?
                                                <button className="toggle" onClick={() => dashNav()}>
                                                    <i className="icon-top" />
                                                    <i className="icon-middle" />
                                                    <i className="icon-bottom" />
                                                </button>
                                                : */}
                                                <button className="toggle" onClick={() => toggleMobileMenuVisibility()}>
                                                    <i className="icon-top" />
                                                    <i className="icon-middle" />
                                                    <i className="icon-bottom" />
                                                </button>
                                            {/* } */}
                                           
                                        </div>
                                        {/* Header Mobile Menu Toggle End */}
                                    </div>
                                </div>
                            </div>
                            {/* Header Right End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Header Section End */}
            <div id="site-main-mobile-menu" className="site-main-mobile-menu">
                <div className="site-main-mobile-menu-inner">
                    <div className="mobile-menu-header">
                        <div className="mobile-menu-logo">
                            <a href="#"><img style={{width:"66px"}} src={koboL2} alt="" /></a>
                        </div>
                        <div className="mobile-menu-close">
                            <button className="toggle" onClick={() => closeMobileMenu()}>
                                <i className="icon-top" />
                                <i className="icon-bottom" />
                            </button>
                        </div>
                    </div>
                    <div className="mobile-menu-content" style={{ background:"#223c70"}}>
                        <nav className="kobo-site-mobile-menu">
                            <ul>
                                <li className="has-children position-static">
                                    <Link to="/home-services"><span className="menu-text">Home</span></Link>
                                    {/* <span className="menu-toggle"><i className="far fa-angle-down" /></span> */}
                                </li>
                                {activeSessionCheck() ? 
                                    <li className="has-children position-static">
                                        <a onClick={() => hotNavigate()} href="#"><span className="menu-text">Dashboard</span></a>
                                        {/* <span className="menu-toggle"><i className="far fa-angle-down" /></span> */}
                                    </li> : 
                                    <li className="has-children position-static">
                                        <Link to="/login-register"><span className="menu-text">Login/Register</span></Link>
                                        {/* <span className="menu-toggle"><i className="far fa-angle-down" /></span> */}
                                    </li>
                                }
                                <li className="has-children position-static">
                                    <Link to="/more-services"><span className="menu-text">Find a professional</span></Link>
                                </li>
                                <li className="has-children position-static">
                                    <Link to="/subscription-plans"><span className="menu-text">Subscription Plans</span></Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
       </>
    );
}

export default UserHeader;