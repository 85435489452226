import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import bgHomeService from "../../assets/images/servicecollage2.png"
import about1 from "../../assets/images/about/about01/about1.jpg"
import hero1 from "../../assets/images/cato.jpg"
import hero2 from "../../assets//images/cato2.png"
import shape1 from "../../assets/images/shape-animation/about-shape-1.png"
import shape2 from "../../assets/images/shape-animation/nwesletter-shape-2.png"
import { enquireScreen } from "enquire-js";

import { FillSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import CatFishHeader from "../../layouts/user/catfishheader";
import CatfishLayout from "../../layouts/user/catfishindex";
import UserFooter from "../../layouts/user/footer";
import { UserContext } from '../../context/userProvider';
import { TOKEN_KEY, USER_KEY } from "../../util/auth";
import Endpoint from "../../util/endpoint";
import { nairaFormat } from "../../util/helpers";


require("../../assets/css/catfish.css")



const fishArr = [
    {
        name: "Vundu Catfish",
        date: "AUG 27, 2024",
        location: "Enugu, Enugu State",
        img: hero1,
        price: "₦2,700.99",
        description: "Known for its large size and robust flavor. Its firm texture and versatile taste make it suitable for baking, broiling, or smoking"
    },
    {
        name: "Vundu Catfish",
        date: "AUG 27, 2024",
        location: "Enugu, Enugu State",
        img: hero1,
        price: "₦2,700.99",
        description: "Known for its large size and robust flavor. Its firm texture and versatile taste make it suitable for baking, broiling, or smoking"
    },
    {
        name: "Vundu Catfish",
        date: "AUG 27, 2024",
        location: "Enugu, Enugu State",
        img: hero1,
        price: "₦2,700.99",
        description: "Known for its large size and robust flavor. Its firm texture and versatile taste make it suitable for baking, broiling, or smoking"
    },
    {
        name: "Vundu Catfish",
        date: "AUG 27, 2024",
        location: "Enugu, Enugu State",
        img: hero1,
        price: "₦2,700.99",
        description: "Known for its large size and robust flavor. Its firm texture and versatile taste make it suitable for baking, broiling, or smoking"
    },
    {
        name: "Vundu Catfish",
        date: "AUG 27, 2024",
        location: "Enugu, Enugu State",
        img: hero1,
        price: "₦2,700.99",
        description: "Known for its large size and robust flavor. Its firm texture and versatile taste make it suitable for baking, broiling, or smoking"
    },
    {
        name: "Vundu Catfish",
        date: "AUG 27, 2024",
        location: "Enugu, Enugu State",
        img: hero1,
        price: "₦2,700.99",
        description: "Known for its large size and robust flavor. Its firm texture and versatile taste make it suitable for baking, broiling, or smoking"
    }

]
const  FishShopUserIndex = () => {
    const { pathname } = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    const userContext = useContext(UserContext);
    const [shopArr, setshopArr] = useState([]);



    const logOutUser = () => {
        $("#preloader").fadeIn("slow")
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY);

        setTimeout(() => {
            window.location.href = "/catfish-shop";
        }, 2000);
    }
    const fetchUserTransactions = () => {
        Endpoint.getUserFishShopSummary(userContext?.userPayload?.userId)
        .then((res) => {
            console.log(res?.data)
            setshopArr(res?.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        enquireScreen((b) => {
            setIsMobile(b)
        });
        window.scrollTo(0, 0);
        fetchUserTransactions()
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 4000);
    }, [pathname])
    return (
        <div className="">
            <CatFishHeader
            />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>

            <div>



                {/* Featurs Section End */}
                {/* Fruits Shop Start*/}
                <div className="container-fluid fruite ">
                    <div className="container py-3">
                        <div className="tab-class text-center">
                            <div className="row g-4 mb-5">
                                <div className="col-lg-12 text-center">
                                    <h1 className="text-center outfit-600">Hi {userContext?.userProfile?.firstName}!</h1> 
                                </div>

                            

                            </div>
                            <div className="tab-content">
                              <div className="d-flex mb-3" style={{width:"100%", justifyContent:"space-between"}}>
                                    <h1 className="text-left outfit-500" style={{ fontSize: "22px" }}>Purchase Summary</h1>
                                    <button onClick={() => logOutUser()} className="btn btn-danger btn-sm" style={{ height: "40px" }}><i className="fa fa-sign-out" /> &nbsp;Logout</button>
                              </div>
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead className="outfit-500">
                                                        <tr>
                                                            <th class="table-primary">SN</th>
                                                            <th class="table-primary">Date</th>
                                                            <th class="table-primary">Variant</th>
                                                            <th class="table-primary">Price</th>
                                                            <th class="table-primary">Status</th>
                                                            {/* <th class="table-primary">Status</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="outfit-400">
                                                      {shopArr && shopArr.map((x,i) => {
                                                        return(
                                                            <tr>
                                                                <td class="table-primary">{i+1}</td>
                                                                <td class="table-secondary">{x?.userTransaction?.createdAt && x?.userTransaction?.createdAt?.substring(0,10)}</td>
                                                                <td class="table-secondary">{x?.fishShop?.name} {x?.fishShop?.extraInfo}</td>
                                                                <td class="table-secondary">{x?.userTransaction?.transactionAmount > 0 ? nairaFormat(x?.userTransaction?.transactionAmount, 2) : 0}</td>
                                                                <td class="table-secondary">{x?.userTransaction?.transactionStatus == 1 ? "Paid" : x?.userTransaction?.transactionStatus == 1 ? "Pending" : ""}</td>
                                                                {/* <td class="table-secondary">Paid</td> */}
                                                            </tr>
                                                        )
                                                      })}
                                                    </tbody>
                                                </table>
                                            </div>

                                       
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>



            <UserFooter />
        </div>
    );
}

export default FishShopUserIndex;
