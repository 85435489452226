import { Link } from "react-router-dom";

const UserFooter = () => {
    return (
        <>
            <div className="footer-section section mt-5">
                <div className="container">
                    {/* Footer Top Widgets Start */}
                    <div className="row">
                        {/* Footer Widget Start */}
                        <div className="col-xl-6 col-md-5 col-12 max-mb-50">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Address</h4>
                                <div className="footer-widget-content">
                                    <div className="content">
                                        <p>47, Along Liberty Road, Oke-Ado, Ibadan, Oyo state</p>
                                        <p>+234 80 5609 6611</p>
                                        <p><a href="#">kobokistltd@gmail.com </a></p>
                                    </div>
                                    <div className="footer-social-inline">
                                        <a href="https://web.facebook.com/kobokistltd" target="_blank"><i className="fab fa-facebook-square" /></a>
                                        <a href="https://www.tiktok.com/@kobokistltd" target="_blank"><i className="fab fa-tiktok" /></a>
                                        <a href="https://www.instagram.com/kobokist_limited?igsh=bWV2bXVudGozbnVy" target="_blank"><i className="fab fa-instagram" /></a>
                                        <a href="https://wa.me/2347037262062" target="_blank"><i className="fab fa-whatsapp" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Footer Widget End */}
                        {/* Footer Widget Start */}
                        <div className="col-xl-3 col-md-4 col-sm-7 col-12 max-mb-50">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Kobokist</h4>
                                <div className="footer-widget-content">
                                    <ul className="column-1">
                                        {/* <li><a href="#">Start here</a></li> */}
                                        <li><Link to="/our-motivation">Our Motivation</Link></li>
                                        {/* <li><a href="#">Blog</a></li> */}
                                        {/* <li><a href="#">Courses</a></li> */}
                                        <li><Link to="/about-us">About us</Link></li>
                                        {/* <li><a href="#">Contact us</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Footer Widget End */}
                        {/* Footer Widget Start */}
                        <div className="col-xl-3 col-md-3 col-sm-5 col-12 max-mb-50">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Information</h4>
                                <div className="footer-widget-content">
                                    <ul>
                                        <li><Link to="/membership">Membership</Link></li>
                                        {/* <li><a href="#">Purchase guide</a></li> */}
                                        <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                        <li><Link to="/terms-of-use">Terms of service</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Footer Widget End */}
                    </div>
                    {/* Footer Top Widgets End */}
                    {/* Footer Copyright Start */}
                    <div className="row max-mt-20">
                        <div className="col">
                            <p className="copyright">KoboKist 2024</p>
                        </div>
                    </div>
                    {/* Footer Copyright End */}
                </div>
            </div>
        </>
    );
}

export default UserFooter;