import { CATFISH_ROUTE_INDEX } from "./helpers";


export const TOKEN_KEY = "kobokist-user-token"
export const USER_KEY = "kobokist-web-user"

export function getActiveStore() {
    return sessionStorage.getItem('user') ? sessionStorage : localStorage;
}

export function getUserToken() {
    return getActiveStore().getItem(TOKEN_KEY)
}


export function logOutUser(Navigate) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);

    setTimeout(() => {
        window.location.href = "/login-register";
    }, 2000);
}


export function loginUser(payload, routeCondition) {
    localStorage.setItem(TOKEN_KEY, payload?.authToken);
    localStorage.setItem(USER_KEY, JSON.stringify(payload));

    if(routeCondition == CATFISH_ROUTE_INDEX){
        window.location.href = routeCondition;
    }
    else{
        window.location.href = "/userindex";
    }
}

export function isLoggedIn() {
   const token = localStorage.getItem(TOKEN_KEY);
    const user = JSON.parse(localStorage.getItem(USER_KEY));

    if(token != null && user != null){
        window.location.href = "/userindex";
    }
}
export function activeSessionCheck() {
    const token = localStorage.getItem(TOKEN_KEY);
    const user = JSON.parse(localStorage.getItem(USER_KEY));

    if (token != null && user != null) {
        return true;
    }
    return false;
}