import React, {useEffect, useContext, useState} from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import { Link } from "react-router-dom";
import $ from "jquery"
import EmptyCart from "./empty-cart";
import { UserContext } from "../../context/userProvider";
import { nairaFormat } from "../../util/helpers";
import { baseContentURL } from "../../util/endpoint";


const Cart = () => {
    const userContext = useContext(UserContext);


    const handleRemoveFromCart = (item) => {
        userContext.removeFromCart(item)
    }
    useEffect(() => {
        // console.log(userContext?.userPayload, "userContext?.userPayload")
        // console.log(userContext?.cartArray, "userContext?.cartArray")
        
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return(
        <>
            <div>
                {/* Page Title Section Start */}
                <div className="page-title-section section mt-3">
                    <div className="page-title">
                        <div className="container">
                            <h1 className="title">Cart</h1>
                        </div>
                    </div>
                    <div className="page-breadcrumb">
                        <div className="container">
                            <ul className="breadcrumb outfit-400">
                                <li><a href="index.html">Home/ </a></li>
                                <li className="current">Cart</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Page Title Section End */}
                {/*Cart section start*/}
              {userContext?.cartArray && userContext?.cartArray?.length > 0 ? 
                    <div className="cart-section section section-padding-bottom">
                        <div className="container faq-wrapper">
                            <div className="row">
                                <div className="col-12">
                                    {/* Cart Table */}
                                    <div className="cart-table table-responsive max-mb-30">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="pro-thumbnail">Image</th>
                                                    <th className="pro-title">Product</th>
                                                    <th className="pro-price">Service Date</th>
                                                    {/* <th className="pro-quantity">Quantity</th> */}
                                                    <th className="pro-quantity">Plan</th>
                                                    <th className="pro-subtotal">Price</th>
                                                    <th className="pro-remove">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userContext?.cartArray && userContext?.cartArray?.map((x, i) => {
                                                    return (
                                                        <tr>
                                                            <td className="pro-thumbnail outfit-500">
                                                                <a href="#"><img className="" src={baseContentURL + x?.serviceImage} alt="Product" /></a>
                                                            </td>
                                                            <td className="pro-title outfit-400" ><a href="#">{x?.serviceName} </a></td>
                                                            <td className="pro-price outfit-500"><span>10/07/2024</span></td>
                                                            <td className="pro-title outfit-400">
                                                                <a href="#">{x?.name} {x?.durationInHours} </a>
                                                            </td>
                                                            <td className="pro-subtotal outfit-400"><span>{x?.price && nairaFormat(x?.price, 2)}</span></td>
                                                            <td className="pro-remove"><a href="#" onClick={() => handleRemoveFromCart(x)}><i className="fa fa-trash-alt" /></a></td>
                                                        </tr>
                                                    )
                                                })}




                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row max-mb-n30">
                                        <div className="col-lg-6 col-12 max-mb-30">
                                            {/* Calculate Shipping */}
                                            {/* <div className="calculate-shipping">
                                                <h4>Calculate Logistics</h4>
                                                <form action="#">
                                                    <div className="row max-mb-n30">
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <select className="outfit-400">
                                                                <option>Lagos</option>
                                                                <option>Enugu</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <select className="outfit-400">
                                                                <option>Lagos Island</option>
                                                                <option>Ikeja</option>
                                                                <option>Ikoyi</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                        <input type="text" placeholder="Postcode / Zip" />
                                                    </div>
                                                    <div className="col-md-6 col-12 max-mb-30">
                                                        <button className="btn btn-primary btn-hover-secondary">Estimate</button>
                                                    </div>
                                                    </div>
                                                </form>
                                            </div> */}
                                            {/* Discount Coupon */}
                                            <div className="discount-coupon">
                                                <h4>Discount Coupon Code</h4>
                                                <form action="#">
                                                    <div className="row max-mb-n30">
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <input type="text" placeholder="Coupon Code" />
                                                        </div>
                                                        <div className="col-md-6 col-12 max-mb-30">
                                                            <button className="btn btn-primary btn-hover-secondary">Apply Code</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        {/* Cart Summary */}
                                        <div className="col-lg-6 col-12 max-mb-30 d-flex">
                                            <div className="cart-summary">
                                                <div className="cart-summary-wrap">
                                                    <h4>Cart Summary</h4>
                                                    
                                                    <p>Sub Total <span> {userContext?.cartTotal && nairaFormat(userContext?.cartTotal, 2)}</span></p>
                                                    <p>Logistics <span>{userContext?.logisticsPrice && nairaFormat(userContext?.logisticsPrice, 2)}</span></p>
                                                    <h2>Grand Total <span>{userContext?.cartTotal && nairaFormat(userContext?.cartTotal + userContext?.logisticsPrice, 2)}</span></h2>
                                                </div>
                                                <div className="cart-summary-button">
                                                    {userContext?.userPayload != null ?
                                                        <Link to={"/service-checkout"} className="mr-2">
                                                            <button className="btn btn-primary btn-hover-secondary">Checkout</button>
                                                        </Link> : 
                                                        <Link 
                                                            state={{
                                                                some: {
                                                                    displayProps: 'active',
                                                                }
                                                            }}
                                                            to={"/login-register"} className="mr-2">
                                                            <button className="btn btn-primary btn-hover-secondary">Checkout</button>
                                                        </Link>
                                                }
                                                   

                                                    <button className="btn btn-primary btn-hover-secondary">Update Cart</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <EmptyCart/>
              }
                {/*Cart section end*/}
            </div>

        </>
    )
}
export default withLayout(UserLayout)(Cart)