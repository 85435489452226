import React from 'react';
import CatFishHeader from './catfishheader';
import UserFooter from './footer';
import CatfishFooter from './catfishfooter';

function CatfishLayout({ children }) {
    return (
        <div>
            <CatFishHeader />
            <div>
                {children}
            </div>
            <CatfishFooter />
        </div>
    );
}

export default CatfishLayout;