import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import { FillSpinner, RotateSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { states } from "../../util/statestore";
import { ToastContainer, toast } from 'react-toastify';
import Endpoint, { baseContentURL } from "../../util/endpoint";
import { UserContext } from "../../context/userProvider";
import { Modal } from 'antd';
import { nairaFormat } from "../../util/helpers";


require("../../assets/css/pricing-plan.css")



const Subscriptions = () => {
    const { pathname } = useLocation();
    const userContext = useContext(UserContext);
    const [selectedStateValue, setselectedStateValue] = useState('')
    const [selectedStateName, setselectedStateName] = useState('Oyo')
    const [selectedLgaValue, setselectedLgaValue] = useState('')
    const [selectedLgaName, setselectedLgaName] = useState('')
    const [lgaArr, setLgaArr] = useState([])
    const [servicePlanArr, setservicePlanArr] = useState([])
    const [fetching, setfetching] = useState(true)
    const navigate = useNavigate();
    const [modal1Open, setModal1Open] = useState(false);
    const [selectedSubscription, setselectedSubscription] = useState({});

    
    const handleStateLocationSelect = (e) => {

        const selectedIndex = e.target.selectedIndex;
        const value = e.target.value;
        const text = e.target.options[selectedIndex].text;
        setselectedStateValue(value);
        setselectedStateName(text)

        setselectedLgaValue('');
        setselectedLgaName('')
        setfetching(true)
        // setTimeout(() => {
        fetchAllServices(text)
        // }, 2000);
        // let filter = states?.filter(x => x.name == value);
        // if (filter) {
        //     setLgaArr(filter[0].lgas)
        // }
    }

    const handleLGALocationSelect = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const value = e.target.value;
        const text = e.target.options[selectedIndex].text;
        setselectedLgaValue(value);
        setselectedLgaName(text)
        filterServicesByLocation(text)
    }
    const handleRouteToDetails = (item) => {
        console.log(item)
        // to = "/service-details"
        if (selectedLgaName == null || selectedLgaName == "") {
            toast.error(<p className="outfit-500 text-white text-center" style={{ fontSize: "13px" }}>Select location to get services available to your location before proceeding</p>);
            return;
        }

        // navigate("/service-details", {
        //     state: {
        //         some: {
        //             serviceItem: item,
        //         }
        //     }
        // });
    }

    const fetchAllServices = (data) => {
        Endpoint.getSubscriptionPlanList(data || selectedStateName)
            .then((res) => {
                setservicePlanArr(res?.data)
                setfetching(false)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    const filterServicesByLocation = (data) => {
        setfetching(true)

        setservicePlanArr([])

        Endpoint.getSubscriptionPlanList(data)
            .then((res) => {
                setservicePlanArr(res?.data)
                setfetching(false)
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }
    const handleConfirmSubPrompt = (data) => {
        console.log(data)
        setselectedSubscription(data)
        setModal1Open(true)
    }
    const processtCheckout = () => {
        $("#preloader").fadeIn("slow")
        const payload = {
            "userId": userContext?.userPayload?.userId,
            "subscriptionId": selectedSubscription?.id
        }
        Endpoint.subscriptionCheckout(payload)
            .then((res) => {
                console.log(res?.data, "checkout response")
                window.location.href = res?.data?.data?.authorization_url
            })
            .catch((err) => {
                console.log(err)
                setModal1Open(false)
                $("#preloader").delay(450).fadeOut("slow")
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "14px" }}>
                    {err?.data?.message}
                </p>,
                {
                    autoClose: 12000
                }
                );
            })
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAllServices()
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 4000);
    }, [pathname]);

    return (
        <div className="">
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            {/* <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1'><span className='outfit-600' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div> */}
            <Modal
            className="outfit-500"
                title="Confirm Subscription"
                style={{
                    top: 20,
                }}
                open={modal1Open}
                onOk={() => processtCheckout()}
                onCancel={() => setModal1Open(false)}
                okText="Proceed to Payment"
            >
                <p className="outfit-400">Are you sure you want to continue to payment? By subscribing, you'll gain access to our services and benefits. Click 'Proceed to Payment' to complete your subscription, or 'Cancel' to go back.</p>
            </Modal>
            <div>
                <div>
                    {/* Page Title Section Start */}
                    <div className="page-title-section section">
                      <div className="row" style={{justifyContent:"end", paddingLeft:"12px", paddingRight:"12px"}}>
                            <div className="col-12 col-md-2 float-right">
                                <select onChange={(e) => handleStateLocationSelect(e)} className="sort-by form-control outfit-500" style={{ border: "1px solid #e6e6e6a3", height: "40px", background: "#80808012" }}>
                                    <option>Select State</option>
                                    {states.map((x, i) => {
                                        return (
                                            <option key={i} value={x.name} selected={x.name === "Oyo"}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                                </select>

                            </div>
                      </div>
                        <div className="page-title mt-3">
                            <div className="container">
                                <h3 className="title mb-3">Subscription Plans </h3>
                                <p className="text-center">
                                    {/* (Service Categories) */}
                                    {/* {selectedLgaName != '' ?
                                        <p className="result-count">We found <span>{servicePlanArr?.length}</span> service(s) available within {selectedLgaName != '' ? selectedLgaName + "," : ""} {selectedStateName}</p> :
                                        <p className="result-count text-center">Filter to see service(s) available within your location</p>
                                    } */}
                                </p>
                            </div>
                        </div>
                        <div className="page-breadcrumb mt-4">
                            <div className="container">
                                <ul className="breadcrumb">
                                    <li><a className="outfit-500">Home/ </a></li>
                                    <li className="current outfit-500"> Subscriptions</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="section section-padding-bottom mt-4">
                        <div className="container">
                            <div className="row max-mb-n50">
                               
                                <div className="col-lg-12 col-12 order-lg-2">
                                    {fetching &&
                                        <center className="mb-5">
                                            <RotateSpinner color="#ababab" />

                                        </center>
                                    }

                                    <main className="main container">

                                        <div className="row justify-content-center">
                                            {!fetching && servicePlanArr && servicePlanArr.map((x, i) => {
                                                return (
                                                    <div className="col-12 col-lg-4 mb-4">
                                                        <div className="price-card ">
                                                            <div className="price-card--top">
                                                                <h3>{x?.name}</h3>
                                                                <h5><span>{x?.price > 0 ? nairaFormat(x?.price, 0) : 0}</span><span className="price-text">/Month</span></h5>
                                                                <hr />
                                                            </div>
                                                            <div className="price-card--bottom">
                                                                <ul>
                                                                    {x?.planFeatures && x?.planFeatures.map((p, t) => (
                                                                        <li className="outfit-400">
                                                                            <i className="fa fa-check" />{p}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                <button onClick={() => handleConfirmSubPrompt(x)} type="button" className="btn" id="btn">Try it</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {!fetching && servicePlanArr?.length <=0 && 
                                        <p className="outfit-400 text-center">No subscription plans found for the selected location</p>
                                        }
                                    </main>



                                </div>
                                {/* Sidebar Widget Wrapper Start */}

                                {/* Sidebar Widget Wrapper End */}
                            </div>
                        </div>
                    </div>




                </div>

            </div>

        </div>
    );
}

export default withLayout(UserLayout)(Subscriptions);