import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Endpoint from './util/endpoint';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { ToastContainer, toast } from 'react-toastify';



const root = ReactDOM.createRoot(document.getElementById('root'));
// function loadScript(src) {
//   const script = document.createElement('script');
//   script.src = src;
//   script.async = true;
//   script.onload = () => console.log(`${src} has been loaded`);
//   document.body.appendChild(script);
// }

// loadScript('/assets/js/vendor.js');
Endpoint.init()
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="119821606489-2c3ehl85hsbu3hq1bie71jja8ued2f66.apps.googleusercontent.com">
     
    {/* <GoogleOAuthProvider clientId="900970377615-8vargajtjbb1klg9plp608a1jt6o313t.apps.googleusercontent.com"> */}
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
