import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import bgHomeService from "../../assets/images/servicecollage2.png"
import about1 from "../../assets/images/about/about01/about1.jpg"
import about2 from "../../assets/images/about/about01/about2.jpg"
import shape1 from "../../assets/images/shape-animation/about-shape-1.png"
import shape2 from "../../assets/images/shape-animation/nwesletter-shape-2.png"
// import shape3 from "../../assets/images/Artisans-deck.png"
import aboutvideo from "../../assets/images/video/about-video.jpg"
import youtubeplay from "../../assets/images/icons/icon-youtube-play.png"
import contact from "../../assets/images/collageuse.png"
import happyclean from "../../assets/images/happyclean.jpg"
import justclean from "../../assets/images/clean.jpg"
import course1 from "../../assets/images/courses/170/course-1.jpg"
import cookwoman from "../../assets/images/cookwoman.jpg"
import gele from "../../assets/images/gelemakeup2.jpg"
import nanny2 from "../../assets/images/nanny2.jpg"
import groceries from "../../assets/images/groceries.jpg"
import braiding from "../../assets/images/braiding.jpeg"
import newclean from "../../assets/images/newclean.png"
import newnanny from "../../assets/images/newnanny.png"
import newgrocery from "../../assets/images/newgrocery.png"
import Marquee from "react-fast-marquee";
import heroimage from "../../assets/images/Artisans-deck.png"




import { FillSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Endpoint, { baseContentURL } from "../../util/endpoint";





const HomeServicesIndex = () => {
    const { pathname } = useLocation();
    const [serviceCategoryArr, setserviceCategoryArr] = useState([])

    const fetchAllServices = () => {
        Endpoint.allServiceCategories()
            .then((res) => {
                setserviceCategoryArr(res?.data, "Sss")
                setTimeout(() => {
                    $("#preloader").delay(450).fadeOut("slow")
                }, 1000);
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }  
     

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAllServices()
    }, [pathname])
    return (
        <div className="">
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <div>
                {/* Slider/Intro Section Start */}
                <div className="intro2-section section">
                    <div className="container">
                        <div className="row row-cols-lg-2 row-cols-1 max-mb-n30">
                            {/* Intro One Content Start */}
                            <div className="col align-self-center max-mb-30" data-aos="fade-up">
                                <div className="intro2-content text-center text-md-left">
                                    <h2 className="title outfit-700">Discover Top-Rated Professionals for Every Need </h2>
                                    <div className="desc">
                                        <p>Whether you need home cleaning, a skilled cook, a trustworthy babysitter, or expert laundry services, we've got you covered.</p>
                                    </div>
                                    <Link to="/more-services" className="btn btn-primary btn-hover-secondary outfit-700">Book a professional &nbsp;<i className="fa fa-arrow-right mr-2" /></Link>
                                    <a className="link link-lg" href="#"> Have questions?​ <mark>Get In Touch <i className="fa fa-arrow-right" /></mark></a>
                                </div>
                            </div>
                            {/* Intro One Content End */}
                            {/* Intro One Course Start */}
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="contact-image intro2-image">
                                    <img src={heroimage} style={{ backgroundRepeat: "no-repeat" }} alt="" className="hero-anim" />

                                    <div className="shape shape-1 scene">
                                        <span data-depth={3}>
                                            <img src={shape1} alt="" />
                                        </span>
                                    </div>
                                    <div className="shape shape-2 scene">
                                        <span data-depth={-3}><img src={shape1} alt="" /></span>
                                    </div>
                                    <div className="shape shape-3 scene">
                                        <span data-depth={4}>shape 3</span>
                                    </div>
                                    <div className="shape shape-4 scene">
                                        {/* <span data-depth={4}><img src={heroimage} className="hero-anim responsive-display-none" alt="" /></span> */}
                                    </div>
                                    {/* <div className="shape shape-5 scene">
                                        <span data-depth={4}><img src={shape2} alt="" /></span>
                                    </div> */}
                                    {/* Animation Shape End */}
                                </div>
                            </div>
                            {/* Intro One Course End */}
                        </div>
                    </div>
                </div>
                {/* Slider/Intro Section End */}
                {/* Brand Section Start */}
                <div className="brand-section section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <Marquee className='mt-5' style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
                                    <img src={cookwoman} className='' style={{ width: "10vmin", height:"10vmin", marginRight: "50px", borderRadius:"50px" }} />
                                    <img src={gele} className='' style={{ width: "10vmin", marginRight: "50px", borderRadius: "50px" }} />
                                    <img src={newclean} className=' ' style={{ width: "10vmin", marginRight: "50px", borderRadius: "50px" }} />
                                    <img src={braiding} className=' ' style={{ width: "10vmin", marginRight: "50px", borderRadius: "50px" }} />
                                    <img src={newnanny} className=' ' style={{ width: "10vmin", marginRight: "50px", borderRadius: "50px" }} />
                                    <img src={newgrocery} className=' ' style={{ width: "10vmin", marginRight: "50px", borderRadius: "50px" }} />
                                   
                                </Marquee> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Brand Section End */}
                {/* Course Section Start */}
                <div className="section section-padding" style={{ backgroundColor: "#f5f7fa" }}>
                    <div className="container">
                        {/* Section Title Start */}
                        <div className="section-title text-center" data-aos="fade-up">
                            <span className="sub-title">Comprehensive Artisan Services at Your Fingertips</span>
                            <h2 className="title outfit-700">Explore Our Wide Range of Offerings</h2>
                        </div>
                        {/* Section Title End */}
                        {/* Courses Wrapper Start */}
                        <div className="row row-cols-lg-2 row-cols-1 max-mb-n30">
                            {/* Course Start */}
                            {serviceCategoryArr && serviceCategoryArr.map((x) => {
                                return (
                                    <div className="col max-mb-30" data-aos="fade-up">
                                        <div className="course-3">
                                            <div className="thumbnail">
                                                <Link to="/more-services" className="image"><img src={baseContentURL + x?.imageUrl} alt="Course Image" /></Link>
                                            </div>

                                            <div className="info">
                                                <span className="price outfit-700"><Link className="" to={"/more-services"}><p style={{ color: "#076fcc" }} className="outfit-600">{x?.name}</p></Link><span></span></span>
                                                <h3 className="title "><Link to={"/more-services"}><p className="outfit-600">{x?.subCaption?.length > 40 ? x?.subCaption.substring(0, 40) + "..." : x?.subCaption}</p></Link></h3>
                                                {/* <ul className="meta">
                                                    <li><i className="fa fa-file-alt" />20 Lessons</li>
                                                    <li><i className="fa fa-user-alt" />51 Students</li>
                                                </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}






                        </div>
                        {/* Courses Wrapper End */}
                        <div className="row max-mt-50">
                            <div className="col text-center">
                                <Link to={"/more-services"}>
                                    <button style={{ border: "2px solid" }} className="btn btn-outline-primary outfit-700"> View all Services <i className="fa fa-long-arrow-right ml-3" /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Course Section End */}
                {/* Learners Section Start */}
                <div className="learners-section section section-padding">
                    <div className="container">
                        {/* Section Title Start */}
                        <div className="section-title text-center" data-aos="fade-up">
                            <span className="sub-title">KoboKist available services</span>
                            <h2 className="title outfit-700">Discover   <span className="outfit-700">Flexible Payment </span>Options to Suit Your Needs.</h2>
                        </div>
                        {/* Section Title End */}
                        {/* Feature Wrapper Start */}
                        <div className="row row-30 row-cols-xl-3 row-cols-sm-2 row-cols-1 max-mb-n30">
                            {/* Feature Start (Icon Box) */}
                            <div className="col max-mb-30" data-aos="fade-up">
                                <a href="courses-grid-1.html" className="icon-box icon-box-left text-left">
                                    <div className="icon">
                                        <img src="assets/images/learner/learner-02.png" alt="" />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Subscription Service</h3>
                                        <div className="desc">
                                            <p>Get more value with our subscription plans! Enjoy consistent, high-quality services monthly with the added convenience of automatic payments.</p>
                                        </div>
                                        {/* <!-- <span className="link">Explore Plans <i className="fa fa-long-arrow-right" /></span> --> */}
                                    </div>
                                </a>
                            </div>

                            {/* Feature Start (Icon Box) */}
                            {/* Feature Start (Icon Box) */}
                            <div className="col max-mb-30" data-aos="fade-up">
                                <a href="courses-grid-1.html" className="icon-box icon-box-left text-left">
                                    <div className="icon">
                                        <img src="assets/images/learner/learner-02.png" alt="" />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Secure Payment Before Service</h3>
                                        <div className="desc">
                                            <p>Enjoy peace of mind with our secure payment system. Pay upfront for your service, and relax knowing that your satisfaction is our priority.</p>
                                        </div>
                                        {/* <!-- <span className="link">Get Free Quote <i className="fa fa-long-arrow-right" /></span> --> */}
                                    </div>
                                </a>
                            </div>
                            <div className="col max-mb-30" data-aos="fade-up">
                                <a href="courses-grid-1.html" className="icon-box icon-box-left text-left">
                                    <div className="icon">
                                        <img src="assets/images/learner/learner-02.png" alt="" />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">On-Demand Service Requests</h3>
                                        <div className="desc">
                                            <p>Need something done right away? Our on-demand service requests allow you to get immediate assistance with just a few clicks. Perfect for last-minute needs!</p>
                                        </div>
                                        {/* <!-- <span className="link">Request Now <i className="fa fa-long-arrow-right" /></span> --> */}
                                    </div>
                                </a>
                            </div>

                            {/* Feature Start (Icon Box) */}
                            {/* Feature Start (Icon Box) */}
                            {/* <div className="col max-mb-30" data-aos="fade-up">
                                <a href="courses-grid-1.html" className="icon-box icon-box-left text-left">
                                    <div className="icon">
                                        <img src="assets/images/learner/h2-learner-02.png" alt="" />
                                    </div>
                                    <div className="content">
                                        <h3 className="title">Multimedia Channels</h3>
                                        <div className="desc">
                                            <p>We provide study materials with various formats: video, audio, slides, doc, prints, books and applications.</p>
                                        </div>
                                        <span className="link">Start here <i className="fa fa-long-arrow-right" /></span>
                                    </div>
                                </a>
                            </div> */}
                            {/* Feature Start (Icon Box) */}
                        </div>
                        {/* Feature Wrapper End */}
                    </div>
                </div>
                {/* Learners Section End */}
                {/* About Video Section Start */}
                <div className="about-video-section section position-relative section-padding-top section-padding-bottom-160">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                {/* About Me Video Wrapper Start */}
                                <div className="about-me-video-wrapper about-us-one-video">
                                    {/* About Me Video Start */}
                                    <a href="https://www.youtube.com/@KobokistLimited" className="about-me-video video-popup" data-aos="fade-up">
                                        <img className="image" src={aboutvideo} alt="" />
                                        <img className="icon" src={youtubeplay} alt="" />
                                    </a>
                                    {/* About Me Video End */}
                                    {/* Animation Shape Start */}
                                    <div className="shape shape-1 scene">
                                        <span data-depth={3}>
                                            <img src="assets/images/shape-animation/shape-2.svg" alt="" className="svgInject" />
                                        </span>
                                    </div>
                                    <div className="shape shape-2 scene">
                                        <span data-depth={-3}><img src="assets/images/shape-animation/shape-3.png" alt="" /></span>
                                    </div>
                                    <div className="shape shape-3 scene">
                                        <span data-depth={4}>shape 3</span>
                                    </div>
                                    <div className="shape shape-4 scene">
                                        <span data-depth={4}><img src="assets/images/shape-animation/shape-1.png" alt="" /></span>
                                    </div>
                                    {/* Animation Shape End */}
                                </div>
                                {/* About Me Video Wrapper End */}
                            </div>
                            <div className="col-lg-4">
                                <div className="about-content max-width-100 pl-60 pl-md-20 pl-sm-0 pl-xs-0 mt-sm-50 mt-xs-50">
                                    <span className="sub-title">Get started  <strong>effortlessly</strong></span>
                                    <h2 className="title outfit-700">Get  <span className="outfit-700">Anything</span> done from your home</h2>
                                    <p>Spend some time and get a proper insight into what we (KoboKist) are about. We desire to give you a solution that caters to your needs.</p>
                                    {/* <a className="link link-lg" href="#"><mark>Download free guidebook <i className="far fa-long-arrow-right" /></mark></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Section Bottom Shape Start */}
                    <div className="section-bottom-shape-two">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height={315}>
                            <path className="elementor-shape-fill" d="M 50 0 S75 0 100 100 L100 0" />
                        </svg>
                    </div>
                    {/* Section Bottom Shape End */}
                </div>
                {/* About Video Section End */}


                {/* Funfact Section Start */}
                <div className="funfact-section section">
                    <div className="container funfact-border bg-pattern" data-bg-image="assets/images/bg/background-pattern-grid-line-02.png">
                        <div className="row">
                            <div className="col-lg-12 mx-auto">
                                <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1">
                                    {/* Funfact Start */}
                                    <div className="col" data-aos="fade-up">
                                        <div className="funfact funfact-style-two max-mb-50">
                                            {/* <h2 className="title">Regsitered users </h2> */}
                                            <div className="number"><span className="counter">1790</span></div>
                                            <h6 className="text">Regsitered users</h6>
                                        </div>
                                    </div>
                                    {/* Funfact End */}
                                    {/* Funfact Start */}
                                    <div className="col" data-aos="fade-up">
                                        <div className="funfact funfact-style-two max-mb-50">
                                            {/* <h2 className="title">Proudly Received</h2> */}
                                            <div className="number"><span className="counter">19</span></div>
                                            <h6 className="text">Subscribing users</h6>
                                        </div>
                                    </div>
                                    {/* Funfact End */}
                                    {/* Funfact Start */}
                                    <div className="col" data-aos="fade-up">
                                        <div className="funfact funfact-style-two max-mb-50">
                                            {/* <h2 className="title">Firmly Established</h2> */}
                                            <div className="number"><span className="counter">24</span></div>
                                            <h6 className="text">Artisans</h6>
                                        </div>
                                    </div>
                                    {/* Funfact End */}
                                    {/* Funfact Start */}
                                    <div className="col" data-aos="fade-up">
                                        <div className="funfact funfact-style-two max-mb-50">
                                            {/* <h2 className="title">Getting Featured on</h2> */}
                                            <div className="number"><span className="counter">1090</span></div>
                                            <h6 className="text">Professionals</h6>
                                        </div>
                                    </div>
                                    {/* Funfact End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Funfact Section End */}
                {/* Blog Section Start */}
                {/* <div className="blog-section section section-padding" data-bg-color="#f5f1ed">
                    <div className="container">
                        <div className="row row-25 row-cols-lg-3 row-cols-md-2 row-cols-1">
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="blog-list-post-wrapper">
                                    <div className="section-title text-left mt-25 mb-25">
                                        <span className="sub-title">Enjoyable insights</span>
                                        <h2 className="title">Most Viewed <span> Best Blogs</span></h2>
                                    </div>
                                    <ul className="blog-list-post">
                                        <li><a href="blog-details.html">Become a Better Blogger: Content Planning</a></li>
                                        <li><a href="blog-details.html">Promoting Your Online Business on Pinterest</a></li>
                                        <li><a href="blog-details.html">Gamification and Game-Based Learning</a></li>
                                        <li><a href="blog-details.html">Designing an Online Course from Expert’s Perspective</a></li>
                                        <li><a href="blog-details.html">Why Online Courses Are the Future of Education</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="blog-2">
                                    <div className="thumbnail">
                                        <a href="blog-details.html" className="image"><img src="assets/images/blog/340/blog-01.jpg" alt="Blog Image" /></a>
                                    </div>
                                    <div className="info">
                                        <a href="blog-details.html" className="category">Artist</a>
                                        <h3 className="title"><a href="blog-details.html">10 Essential Bodyweight Exercises</a></h3>
                                        <ul className="meta">
                                            <li><i className="far fa-calendar" />Apr 06, 2020</li>
                                            <li><i className="far fa-eye" />70 views</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col max-mb-30" data-aos="fade-up">
                                <div className="blog-2">
                                    <div className="thumbnail">
                                        <a href="blog-details.html" className="image"><img src="assets/images/blog/340/blog-02.jpg" alt="Blog Image" /></a>
                                    </div>
                                    <div className="info">
                                        <a href="#" className="category">Artist</a>
                                        <h3 className="title"><a href="blog-details.html">Pocket-Sized Notebooks Hold Miniature Paintings</a></h3>
                                        <ul className="meta">
                                            <li><i className="far fa-calendar" />Apr 06, 2020</li>
                                            <li><i className="far fa-eye" />64 views</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Blog Section End */}
                {/* Newsletter Section Start */}
                <div className="newsletter-section section section-padding position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="newsletter-content">
                                    <h2 className="title outfit-700">Sign up for our <span className="outfit-700">Newsletters</span></h2>
                                    <p>Enter your email address to register to our newsletter subscription <br className="d-none d-md-block" />delivered on a regular basis! </p>
                                    <div className="newsletter-form">
                                        <form action="#">
                                            <input type="email" placeholder="Your E-mail" />
                                            <button className="btn btn-primary btn-hover-secondary">Subscribe</button>
                                        </form>
                                    </div>
                                    {/* Animation Shape Start */}
                                    <div className="shape shape-1 scene">
                                        <span data-depth={4}>shape 3</span>
                                    </div>
                                    <div className="shape shape-2 scene">
                                        <span data-depth={4}><img src="assets/images/shape-animation/nwesletter-shape-1.png" alt="" /></span>
                                    </div>
                                    <div className="shape shape-3 scene">
                                        <span data-depth={4}><img src="assets/images/shape-animation/nwesletter-shape-2.png" alt="" /></span>
                                    </div>
                                    {/* Animation Shape End */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Newsletter Section End */}
            </div>



        </div>
    );
}

export default withLayout(UserLayout)(HomeServicesIndex);