import { useState, useContext, useEffect } from "react";
import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import $ from "jquery"
import { USER_KEY, logOutUser } from "../../util/auth";
import { UserContext } from "../../context/userProvider";
import Endpoint from "../../util/endpoint";
import { nairaFormat, SUBSCRIPTION_ROUTE_PROPS } from "../../util/helpers";
import { Tag } from "antd";
import { FillSpinner } from "react-spinners-kit";
import imgplaceholder from "../../assets/images/image_placeholder.png"
import { Link } from "react-router-dom";

require("../../assets/css/pricing-plan.css")





const ACCOUNT_DETAILS = "ACCOUNT_DETAILS"
const DASHBOARD = "DASHBOARD"
const SERVICE_ADDRESS = "SERVICE_ADDRESS"
const SERVICE_REQUESTS = "SERVICE_REQUESTS"


const UserSubscription = ({ serviceInfo }) => {
    const userContext = useContext(UserContext);
    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem(USER_KEY)))
    const [displayIndicator, setdisplayIndicator] = useState(DASHBOARD)
    const [firstName, setFirstName] = useState(userProfile?.fullName?.split(' ')[0]);
    const [lastName, setlastName] = useState(userProfile?.fullName?.split(' ')[1]);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageSize, setpageSize] = useState(5);
    const [serviceRequestList, setserviceRequestList] = useState([]);

    const handleIndicator = (data) => {
        setdisplayIndicator(data)
    }

    const handleLogout = () => {
        logOutUser()
    }

    const fetchServiceRequests = () => {
        Endpoint.getServiceRequestList(pageNumber, pageSize)
            .then((res) => {
                console.log(res?.data)
                setserviceRequestList(res?.data?.data)
                $("#preloader").delay(450).fadeOut("slow")
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        // fetchServiceRequests();
    }, [])
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 text-center mb-4">
                    <h2 className="kobo-dash-title">Subscription Overview</h2>
                </div>
                <hr/>
                {/* <div className="col-12">
                    <h4>
                        Subscription Details
                        <Tag color={"green"} className="outfit-500 ml-2">
                            Active
                        </Tag>
                    </h4>
                </div> */}
                {userContext?.userSubscription != null ? (
                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="col-12 col-md-5 mb-4">
                                <div className="price-card shadow">
                                    <div className="price-card--top text-center">
                                        <h3>{userContext?.userSubscription?.name}</h3>
                                        <h5>{userContext?.userSubscription?.price > 0 ? nairaFormat(userContext?.userSubscription?.price, 2) : "-"}<span className="price-text">/month</span></h5>
                                        <hr />
                                    </div>
                                    <div className="price-card--bottom">
                                        <ul className="list-unstyled text-left" style={{padding:"20px"}}>
                                            <li className="mb-1">✔ Access to premium features</li>
                                            <li className="mb-1">✔ Priority support</li>
                                            <li className="mb-1">✔ Monthly service requests</li>
                                            {/* Add more benefits or features as applicable */}
                                        </ul>
                                        <button disabled type="button" className="btn btn-success w-100 mt-3">
                                            Active Subscription
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 offset-md-1">
                                <div className="profile-info text-left">
                                    <h4 style={{ color: "636060" }}>Subscription Credits: <span className="font-weight-bold">{userContext?.userSubscription?.serviceAllowanceCount}</span></h4>
                                    <h4>Remaining Credit(s): <span className="font-weight-bold">{userContext?.userSubscription?.serviceThreshold}</span></h4>
                                    <h4>Valid Until: <span className="font-weight-bold" style={{fontSize:"15px"}}>{userContext?.userSubscription?.expiryDate}</span></h4>
                                    <h4 className="mt-4"><span className="font-weight-bold">You're All Set!</span></h4>
                                    <p>
                                        Kindly note that service requests made through your subscription plan are only available for the address listed on your profile. Please ensure your profile address is up to date.
                                        {/* Your active plan ensures that you continue to enjoy our top-notch services without interruption. If you have any questions or need assistance, we're here to help. Feel free to explore your benefits and make the most of your subscription. */}
                                    </p>
                                    <Link
                                        state={{
                                            some: {
                                                routeProps: SUBSCRIPTION_ROUTE_PROPS,
                                            }
                                        }}
                                    to={"/more-services"} className="btn btn-primary">Request a service</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-12 text-center mt-4">
                        {/* <i className="fa fa-hourglass-half" style={{ fontSize: "3em" }} /> */}
                            <i className="fa fa-exclamation-circle" style={{ fontSize: "3em", color: "#dc3545" }} />
                        <br />
                        <br />
                        <h4>You do not have an active subscription plan.</h4>
                        <p>Please choose a plan to enjoy our services.</p>
                        {/* Optionally add a button to navigate to the subscription plans page */}
                            <Link to={"/subscription-plans"} className="btn btn-primary">View Plans</Link>
                    </div>
                )}
            </div>
        </div>

    );
}

export default UserSubscription;