import { useState, useContext, useEffect } from "react";
import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import $ from "jquery"
import { USER_KEY, logOutUser } from "../../util/auth";
import { UserContext } from "../../context/userProvider";
import Endpoint from "../../util/endpoint";
import { nairaFormat } from "../../util/helpers";
import { Tag } from "antd";
import { FillSpinner } from "react-spinners-kit";
import imgplaceholder from "../../assets/images/image_placeholder.png"
import IDCard from "./service-person-card";
const ACCOUNT_DETAILS = "ACCOUNT_DETAILS"
const DASHBOARD = "DASHBOARD"
const SERVICE_ADDRESS = "SERVICE_ADDRESS"
const SERVICE_REQUESTS = "SERVICE_REQUESTS"


const UserServiceAllocation = ({ serviceInfo, visibilityProps }) => {
    const userContext = useContext(UserContext);
    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem(USER_KEY)))
    const [displayIndicator, setdisplayIndicator] = useState(DASHBOARD)
    const [firstName, setFirstName] = useState(userProfile?.fullName?.split(' ')[0]);
    const [lastName, setlastName] = useState(userProfile?.fullName?.split(' ')[1]);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageSize, setpageSize] = useState(5);
    const [serviceRequestList, setserviceRequestList] = useState([]);

    const handleIndicator = (data) => {
        setdisplayIndicator(data)
    }

    const handleLogout = () => {
        logOutUser()
    }

    const fetchServiceRequests = () => {
        Endpoint.getServiceRequestList(pageNumber, pageSize)
            .then((res) => {
                console.log(res?.data)
                setserviceRequestList(res?.data?.data)
                $("#preloader").delay(450).fadeOut("slow")
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        // fetchServiceRequests();
    }, [])

    const handleGoBack = () => {
        visibilityProps("SERVICE_REQUESTS")
    }
    return (
        <div className="row justify-content-center">
            <div className='col-4'>
                <h2 onClick={() => handleGoBack()} style={{ fontSize: "15px", cursor: "pointer" }}> <span style={{ fontSize: "19px" }}><i className='fa fa-arrow-left text-left' /></span> &nbsp; Back</h2>
            </div>
            <div className="col-8">
                <h3 style={{fontSize:"20px"}} className="text-left">Allocation Details &nbsp;
                    {/* <Tag color={"green"}>
                        Allocated
                    </Tag> */}

                    <Tag className="outfit-500" color={serviceInfo?.servicedStatus == 0 ? "volcano" : serviceInfo?.servicedStatus == 1 ? "geekblue" : serviceInfo?.servicedStatus == 2 ? "green" : serviceInfo?.servicedStatus == 3 ? "" : ""}>
                        {serviceInfo?.status}
                    </Tag>
                </h3>
            </div>
            {serviceInfo?.servicePersonAllocations != null ?
        <>
                    <IDCard person={serviceInfo?.servicePersonAllocations} visibilityProps={visibilityProps}/>
                    <div className="row">
                        <div className="col-12 col-lg-7 offset-xl-1 justify-content-start mt-5" style={{padding:"35px"}}>
                            <div className="profile-info">
                                <h3 className="profile-name text-left" style={{ fontSize: "22px" }}>Security Check: Verify Service Personnel</h3>
                                {/* <div className="author-career text-left">/Advanced Educator</div> */}
                                <p className="author-bio text-left">For your security, when a service person arrives at your location, it's important to request their service code and name. Make sure to verify these details against the credentials displayed on your dashboard. This step ensures that the person providing the service is authorized and that you are protected against potential risks. Taking this precaution helps maintain a secure and trustworthy service experience.</p>

                                {/* <ul className="author-social-networks">
      <li className="item">
        <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-twitter social-link-icon" /> </a>
      </li>
      <li className="item">
        <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-facebook-f social-link-icon" /> </a>
      </li>
      <li className="item">
        <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-instagram social-link-icon" /> </a>
      </li>
      <li className="item">
        <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-pinterest social-link-icon" /> </a>
      </li>
      <li className="item">
        <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-youtube social-link-icon" /> </a>
      </li>
    </ul> */}
                            </div>
                        </div>

                    </div>
        </>
        
                : <h4 className="outfit-400" style={{width:"90%", fontSize:"16px"}}>
                    <i className="fa fa-hourglass-half" style={{fontSize:"3em"}}/> 
                    <br />
                    <br/>
                    Your request has been received! Please wait while we allocate a professional to your service. We are matching you with the best available expert, and you'll be notified as soon as the allocation is complete. Thank you for your patience!</h4>}
          
        </div>
    );
}

export default UserServiceAllocation;