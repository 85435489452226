
import { enquireScreen } from "enquire-js";

import { FillSpinner } from "react-spinners-kit";
import $ from "jquery"
import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import CatFishHeader from "../../layouts/user/catfishheader";
import CatfishLayout from "../../layouts/user/catfishindex";
import UserFooter from "../../layouts/user/footer";
import Endpoint, { baseContentURL } from "../../util/endpoint";
import { KOBO_CART_FISH_SHOP, nairaFormat } from "../../util/helpers";
import { UserContext } from '../../context/userProvider';
import { ToastContainer, toast } from 'react-toastify';
import CatfishFooter from "../../layouts/user/catfishfooter";


require("../../assets/css/catfish.css")


const FishShop = () => {
    const { pathname } = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    const userContext = useContext(UserContext);
    const [fishArr, setfishArr] = useState([]);
    const [selectedKg, setselectedKg] = useState(0);
    const [kgArr, setkgArr] = useState([]);

    
    const fetchShopList = () => {
        Endpoint.getFishShopList()
        .then((res) => {
            $("#preloader").delay(450).fadeOut("slow")
            setfishArr(res?.data)
            console.log(res?.data, "fish")
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleAddToCart = (item) => {
        console.log(item, "added")
        if(selectedKg <= 0){
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "14px" }}>
                Select a Kg to continue
            </p>
            );
            return;
        }
        userContext.globalAddToCartRegular(item, KOBO_CART_FISH_SHOP, selectedKg)
    }
    const handleKgSelection = (e) => {
        console.log(e.target.value)
        setselectedKg(e.target.value)
    }
    const initializeKilogram = () => {
        let initArr = []; 
        for (let i = 1; i <= 50; i++) {
            let formedOBJ = {
                label: i + "Kg",
                value: i
            };
            initArr = [...initArr, formedOBJ];
        }
        setkgArr(initArr);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        enquireScreen((b) => {
            setIsMobile(b)
        });
        initializeKilogram()
        fetchShopList()
        // setTimeout(() => {
        //     $("#preloader").delay(450).fadeOut("slow")
        // }, 4000);
    }, [pathname])
    return (
        <div className="">
            <CatFishHeader
            />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>

            <div>
                <ToastContainer
                    position="top-center"
                    theme="colored"
                />
            
          
                {/* Featurs Section End */}
                {/* Fruits Shop Start*/}
                <div className="container-fluid fruite ">
                    <div className="container py-3">
                        <div className="tab-class text-center">
                            <div className="row g-4 mb-5">
                                <div className="col-lg-12 text-center">
                                    <h1 className="text-center outfit-600">The Fish Shop</h1>
                                </div>
                               
                            </div>
                            <div className="tab-content">
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <div className="row g-4">
                                                {fishArr && fishArr?.map((x, i) => {
                                                    return(
                                                        <div className="col-md-6 col-lg-4 col-xl-3 mb-3">
                                                            <div className="rounded position-relative fruite-item">
                                                                <div className="fruite-img">
                                                                    <img style={{objectFit:"cover"}} src={baseContentURL + x?.imageUrl} className="img-fluid w-100 rounded-top" alt="" />
                                                                </div>
                                                                <div className="text-white bg-secondary px-3 py-1 rounded position-absolute outfit-400" style={{ top: '10px', left: '10px' }}>{x?.name}</div>
                                                                <div className="p-4 border border-secondary border-top-0 rounded-bottom">
                                                                    <h4>{x?.extraInfo}</h4>
                                                                    {/* <h5 style={{marginTop:"-11px"}}>{x?.extraInfo}</h5> */}
                                                                    <p>{x?.description}</p>
                                                                    <div className="d-flex justify-content-between flex-lg-wrap">
                                                                        <p className="text-dark fs-5 fw-bold mb-0">{x?.price > 0 ? nairaFormat(x?.price, 2) : x?.price} / kg</p>&nbsp;
                                                                        <select onChange={(e) => handleKgSelection(e)} className="outfit-500" style={{ height: "28px" }}>
                                                                            <option>Kg</option>
                                                                            {kgArr && kgArr?.map((x, i) => {
                                                                                return(
                                                                                    <option value={x?.value}>{x?.label}</option>
                                                                                )
                                                                            })}
                                                                            {/* <option value={1}>1kg</option>
                                                                            <option value={2}>2kg</option>
                                                                            <option value={3}>3kg</option>
                                                                            <option value={4}>4kg</option>
                                                                            <option value={5}>5kg</option>
                                                                            <option value={6}>6kg</option> */}
                                                                        </select>
                                                                        <br/>
                                                                        
                                                                       
                                                                    </div><button type="button" onClick={() => handleAddToCart(x)} className="btn border btn-primary btn-sm rounded-pill px-3 text-white mt-2"><i className="fa fa-shopping-bag me-2 text-white" /> &nbsp; Add to cart</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>



<CatfishFooter/>
        </div>
    );
}

export default FishShop;
