import { useState, useContext, useEffect } from "react";
import UserLayout from "../../layouts/user";
import UserHeader from "../../layouts/user/header";
import withLayout from "../../layouts/withLayout";
import $ from "jquery"
import { USER_KEY, logOutUser } from "../../util/auth";
import { UserContext } from "../../context/userProvider";
import Endpoint from "../../util/endpoint";
import { nairaFormat } from "../../util/helpers";
// import { Tag } from "antd";
import { message, Spin, Upload, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FillSpinner } from "react-spinners-kit";
import imgplaceholder from "../../assets/images/image_placeholder.png"
import FacialVerification from "./facial-verification";
import { ToastContainer, toast } from 'react-toastify';

const ACCOUNT_DETAILS = "ACCOUNT_DETAILS"
const DASHBOARD = "DASHBOARD"
const SERVICE_ADDRESS = "SERVICE_ADDRESS"
const SERVICE_REQUESTS = "SERVICE_REQUESTS"


const { Dragger } = Upload;
const props = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};
const UserProfile = ({ serviceInfo }) => {
    const userContext = useContext(UserContext);
    const [userProfile, setUserProfile] = useState(userContext?.userProfile)
    const [displayIndicator, setdisplayIndicator] = useState(DASHBOARD)
    const [firstName, setFirstName] = useState(userContext?.userProfile?.firstName);
    const [lastName, setlastName] = useState(userContext?.userProfile?.lastName);
    const [pageNumber, setpageNumber] = useState(1);
    const [pageSize, setpageSize] = useState(5);
    const [serviceRequestList, setserviceRequestList] = useState([]);
    const [initFacialVerification, setinitFacialVerification] = useState(false);
    const [saving, setsaving] = useState(false);
    const [pageTitle, setPageTitle] = useState("Profile Details");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [verificationType, setverificationType] = useState("");

    const [inputValues, setInputValues] = useState({
        checkbox: false,
        text: '',
    });
    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleIndicator = (data) => {
        setdisplayIndicator(data)
    }
    const toggleFacialVerification = () => {
        // $("#kobo-side-menu").toggle()
        if (inputValues?.nin == null && inputValues?.bvn == null){
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                    One of either your NIN or BVN is required to initiate verification
                    </p>);
                    return;
        }
        setIsModalOpen(false);
        setPageTitle(pageTitle == "Profile Details" ? "Facial Verification" : "Profile Details")
        setinitFacialVerification(!initFacialVerification)
        window.scrollTo(0, 0);
    }
    const handleLogout = () => {
        logOutUser()
    }

    const fetchServiceRequests = () => {
        Endpoint.getServiceRequestList(pageNumber, pageSize)
            .then((res) => {
                console.log(res?.data)
                setserviceRequestList(res?.data?.data)
                $("#preloader").delay(450).fadeOut("slow")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const updateUserProfile = () => {
        const payload = {
            "userId": userContext?.userProfile?.id,
            "firstName": inputValues?.firstName ?? "",
            "lastName": inputValues?.lastName ?? "",
            "emailAddress": inputValues?.email ?? "",
            "homeAddress": inputValues?.homeAddress ?? "",
            "currentPassword": inputValues?.currentPassword ?? "",
            "newPassword": inputValues?.confirmNewPassword ?? ""
        }
        setsaving(true)
        Endpoint.updateProfile(payload)
        .then((res) => {
            console.log(res?.data)
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>
                Profile updated successfully!
            </p>);
            setsaving(false)
        })
        .catch((err) => {
            setsaving(false)
            console.log(err)
        })
    }
    useEffect(() => {
        // fetchServiceRequests();
    }, [])

    const handleVerificationType = (data) => {
        setverificationType(data)
        // if(data == "nin"){
        //     setverificationType(data)
        // }
        // else if (data == "bvn") {
        //     setverificationType(data)
        // }
    }
    return (
        <div className="tab-pane fade show active" id="" role="tabpanel">
            <ToastContainer
                position="top-center"
                theme="colored"
            />

            <Modal className="outfit-500" 
            // title="Initiate Verification" 
            closable
                footer={[<button onClick={() => toggleFacialVerification()} className="btn btn-primary btn-sm" style={{ height: "40px", background:"#2e61c8"}}>Proceed</button>]} open={isModalOpen} onOk={handleOk} closeIcon={false} onCancel={handleCancel}>

                <div className="row justify-content-center mt-4">
                    <div className="col-12">
                        <label for="formFileLg" class="form-label text-left">Select verification type</label>
                        <select onChange={(e) => handleVerificationType(e.target.value)} class="form-select form-select-lg mb-3" aria-label="Large select example">
                            <option selected>Verification type</option>
                            <option value="nin">NIN</option>
                            <option value="bvn">BVN</option>
                        </select>
                    </div>
                    {verificationType == "nin" && 
                        <div className=" col-12 mb-30">

                            <div className="text-left">
                                {/* <label for="formFileLg" class="form-label text-left">NIN</label> */}
                                <input onChange={(e) => handleInput(e)} name="nin" id="current-pwd" placeholder="Enter NIN" type="text" />
                            </div>
                        </div>}
                    {verificationType == "bvn" && <div className="col-12 mb-30">

                            <div className="text-left">
                                {/* <label for="formFileLg" class="form-label text-left">BVN</label> */}
                                <input onChange={(e) => handleInput(e)} name="bvn" id="current-pwd" placeholder="Enter BVN" type="text" />
                            </div>
                        </div>}
                </div>
            </Modal>
            <div className="myaccount-content">
                <h3 style={{fontSize:"21px"}}>{pageTitle}</h3>
                {userContext?.userProfile?.userStatus == 1  &&
                    <p className="outfit-500 text-left" style={{ color: "#2986b1", fontSize:"13px" }}>
                        <i className="fa fa-spinner fa-spin" /> &nbsp;We are reviewing your verification request... &nbsp;
                    </p>
                }
                {userContext?.userProfile?.userStatus == 2 &&
                    <p className="outfit-500 text-left" style={{ color: "green", fontSize: "13px" }}>
                        <i className="fa fa-check-circle" /> &nbsp;Your account is verified! &nbsp;
                    </p>
                }
                {userContext?.userProfile?.userStatus <= 0 && pageTitle == "Profile Details" && 
                    <p className="outfit-500 text-left" style={{ color: "#b51010" }}><i className="fa fa-exclamation-circle" /> &nbsp;<i>Your account is not yet verified. &nbsp;</i> <span><a onClick={() => showModal()} href="#" style={{ textDecoration: "underline", color: "#b51010", fontWeight: "800" }}>Click here to start verification</a></span></p>
                }
               {!initFacialVerification ? 
                    <div className="account-details-form">
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-6 col-12 mb-30">
                                    <input id="first-name" onChange={(e) => handleInput(e)} name="firstName" defaultValue={firstName} placeholder="First Name" type="text" />
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                    <input id="last-name" defaultValue={lastName} onChange={(e) => handleInput(e)} name="lastName" placeholder="Last Name" type="text" />
                                </div>
                                {/* <div className="col-12 mb-30">
                                    <input id="display-name" disabled defaultValue={userProfile?.fullName} placeholder="Display Name" type="text" />
                                </div> */}
                                <div className="col-12 mb-30">
                                    <input id="email" defaultValue={userContext?.userProfile?.emailAddress} onChange={(e) => handleInput(e)} name="emailAddress" placeholder="Email Address" type="email" />
                                </div>
                                <div className="col-12 mb-30">
                                   
                                    <input id="current-pwd" defaultValue={userContext?.userProfile?.homeAddress} onChange={(e) => handleInput(e)} name="homeAddress" placeholder="Home Address" type="text" />
                                </div>
                                
                               
                               
                                

                                <div className="col-12 mb-30 text-left">
                                    <h4>Password change</h4>
                                </div>
                                <div className="col-12 mb-30">
                                    <input onChange={(e) => handleInput(e)} name="currentPassword" id="current-pwd" placeholder="Current Password" type="password" />
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                    <input onChange={(e) => handleInput(e)} name="newPassword" id="new-pwd" placeholder="New Password" type="password" />
                                </div>
                                <div className="col-lg-6 col-12 mb-30">
                                    <input onChange={(e) => handleInput(e)} name="confirmNewPassword" id="confirm-pwd" placeholder="Confirm Password" type="password" />
                                </div>
                                <div className="col-12">
                                    <button type="button" onClick={() => updateUserProfile()} className="btn btn-primary btn-hover-secondary">
                                        {saving ? <Spin spinning={true}/> : " Save Changes"}
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div> : 
                    
                    <FacialVerification userDetails={userContext?.userProfile} visibilityProps={toggleFacialVerification} userId={userContext?.userProfile?.id} nin={inputValues?.nin} bvn={inputValues?.bvn} />
            }
            </div>
        </div>
    );
}

export default UserProfile;