import React, { useEffect, useContext, useState } from "react";
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import justclean from "../../assets/images/clean.jpg"
import collageuse from "../../assets/images/collageuse.png"
import { Link } from "react-router-dom";
import $ from "jquery"
import EmptyCart from "./empty-cart";
import { UserContext } from "../../context/userProvider";
import { nairaFormat } from "../../util/helpers";
import { baseContentURL } from "../../util/endpoint";


const PrivacyPolicy = () => {
    const userContext = useContext(UserContext);


    useEffect(() => {

        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 2000);
    }, [])
    return (
        <div className=" section section-padding-bottom mt-5">
            <div className="privacy-policy container">
                <h1>Privacy Policy for Kobokist Limited</h1>
                <p><strong>Effective Date:</strong> 1st of September, 2024</p>
                <p>
                    At Kobokist Limited, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and protect your personal data when you use our website, platform, and services.
                </p>

                <h2>1. Information We Collect</h2>
                <p>We collect various types of information to provide and improve our services. The types of information we collect include:</p>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> When you create an account, place an order, book a service, or contact us, we may collect personal details such as your name, email address, phone number, mailing address, payment information, and any other information you voluntarily provide.
                    </li>
                    <li>
                        <strong>Transaction Information:</strong> We collect information related to your transactions on the Site, including details of the products and services you have purchased or booked.
                    </li>
                    <li>
                        <strong>Technical Information:</strong> We may collect information about your device, browser type, IP address, and usage patterns on the Site through cookies and other tracking technologies.
                    </li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p>Kobokist Limited uses the information we collect for the following purposes:</p>
                <ul>
                    <li><strong>Providing Services:</strong> To process your orders, manage your account, and deliver the products and services you request.</li>
                    <li><strong>Improving Our Services:</strong> To understand how our Site is used, enhance user experience, and improve our offerings.</li>
                    <li><strong>Communications:</strong> To send you updates, promotional materials, and other communications related to our services. You can opt-out of these communications at any time.</li>
                    <li><strong>Compliance and Legal Obligations:</strong> To comply with legal requirements, enforce our Terms and Conditions, and protect the rights, property, or safety of Kobokist Limited, our users, and others.</li>
                </ul>

                <h2>3. Sharing and Disclosure of Information</h2>
                <p>We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
                <ul>
                    <li>
                        <strong>Service Providers:</strong> Third-party vendors and service providers who assist us in delivering our services, such as payment processors, delivery services, and IT support. These parties are required to protect your information and use it only for the purposes we specify.
                    </li>
                    <li>
                        <strong>Legal Requirements:</strong> We may disclose your information if required by law, such as in response to a subpoena, court order, or other legal process.
                    </li>
                    <li>
                        <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our business, your information may be transferred to the new owner as part of the transaction.
                    </li>
                </ul>

                <h2>4. Data Security</h2>
                <p>
                    We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
                </p>

                <h2>5. Cookies and Tracking Technologies</h2>
                <p>
                    Kobokist Limited uses cookies and similar tracking technologies to enhance your experience on our Site. Cookies are small files that are stored on your device and allow us to recognize your browser and capture certain information. You can control the use of cookies through your browser settings, but disabling cookies may affect your ability to use some features of the Site.
                </p>

                <h2>6. Your Rights and Choices</h2>
                <p>You have the following rights regarding your personal information:</p>
                <ul>
                    <li><strong>Access:</strong> You have the right to request access to the personal information we hold about you.</li>
                    <li><strong>Correction:</strong> You have the right to request corrections to any inaccurate or incomplete information.</li>
                    <li><strong>Deletion:</strong> You have the right to request the deletion of your personal information, subject to certain legal requirements.</li>
                    <li><strong>Opt-Out:</strong> You have the right to opt-out of receiving promotional communications from us by following the unsubscribe instructions in the communications or by contacting us directly.</li>
                </ul>

                <h2>7. Third-Party Links</h2>
                <p>
                    Our Site may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party sites, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of any third-party sites you visit.
                </p>

                <h2>8. Children’s Privacy</h2>
                <p>
                    Kobokist Limited does not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information from our records.
                </p>

                <h2>9. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.
                </p>

                <h2>10. Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                </p>
                <address>
                    Kobokist Limited<br />
                    47, Along Liberty Road, Oke-Ado, Ibadan, Oyo state<br />
                    Email: <a href="mailto:kobokistltd@gmail.com">kobokistltd@gmail.com</a><br />
                    Phone: 08056096611
                </address>
            </div>

        </div>

    )
}
export default withLayout(UserLayout)(PrivacyPolicy)