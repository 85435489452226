import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery"
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import { GoogleOutlined, GooglePlusCircleFilled } from "@ant-design/icons";
import googleIcon from "../../assets/images/google.png"
import { FillSpinner } from "react-spinners-kit";
import { GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import axios from "axios";

const LoginRegister = () => {
    const { pathname } = useLocation();

    const handleLogin = () => {
        // $("#preloader").fadeIn("slow")
        // setTimeout(() => {
        //     window.location.href = "/userindex"
        // }, 3000);
    }

    // const googleSignIn = useGoogleLogin({
    //     onSuccess: async (tokenResponse) => {
    //        console.log(tokenResponse)
    //         const { access_token, id_token } = tokenResponse;
    //         console.log('Access Token:', access_token);
    //         console.log('ID Token:', id_token);
    //     },
    //     onError: () => console.log('Login Failed'),
    //     scope: 'email profile openid', // Requesting profile and email scopes
    //     flow: 'implicit', // Use implicit flow to get id_token directly
    // });

    const googleSignIn = () => {
        <GoogleLogin
            onSuccess={credentialResponse => {
                console.log(credentialResponse);
            }}
            onError={() => {
                console.log('Login Failed');
            }}
        />
    }
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            console.log(credentialResponse);
        },
        onError: () => {
            console.log('Login Failed');
        },
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 1000);

        // intializeGoogleOneTapSignIn()
        
    }, [pathname]);

    return (
        <>
            {/* <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div> */}
            <div>
                {/* Page Title Section Start */}
                <div className="page-title-section section">
                    <div className="page-title mt-3">
                        <div className="container">
                            <h1 className="title">Login/Register</h1>
                        </div>
                    </div>
                    <div className="page-breadcrumb mt-3">
                        <div className="container">
                            <ul className="breadcrumb">
                                <li><a href="#" className="outfit-500">Home/ &nbsp;</a></li>
                                <li className="current outfit-500">login-register</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Page Title Section End */}
                {/*Login Register section start*/}
                <div className="login-register-section section section-padding-bottom mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-6">
                                        {/* Login Form Start */}
                                        <div className="login-form-wrapper">
                                            <h3 className="title">Login</h3>
                                            <form className="login-form" action="#">
                                                <div className="single-input mb-3">
                                                    <label htmlFor="username">Username or email</label>
                                                    <input type="text" id="username" name="username" placeholder="Username or email" />
                                                </div>
                                                <div className="single-input mb-3">
                                                    <label htmlFor="password">Password</label>
                                                    <input type="text" id="password" name="password" placeholder="Password" />
                                                </div>
                                                <div className="single-input mb-3">
                                                    <div className="row">
                                                        <div className="col-6 remember-me-wrap">
                                                            <div className="checkbox-input">
                                                                <input type="checkbox" name="login-form-remember" id="login-form-remember" />
                                                                <label htmlFor="login-form-remember" style={{ fontSize: "13px" }}>Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 lost-your-password-wrap">
                                                            <p>
                                                                <a href="#" style={{fontSize:"12px"}} className="lost-your-password">Lost your password?</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="single-input">
                                                    <button type="button" onClick={() => handleLogin()} className="btn btn-primary btn-hover-secondary btn-width-100">Log In</button>
                                                    {/* <button type="button" onClick={() => googleSignIn()} className="btn btn-outline-primary btn-hover-secondary btn-width-100 mt-3 outfit-600">Sign in with Google <img src={googleIcon} style={{width:"30px"}}/></button> */}
                                                    <p className="text-center mt-3" style={{fontSize:"11px"}}>OR</p>
                                                    <div className="" style={{ justifyContent: 'center', width: "100%", display: "flex" }}>
                                                        <GoogleLogin
                                                            onSuccess={credentialResponse => {
                                                                console.log(credentialResponse);
                                                            }}
                                                            onError={() => {
                                                                console.log('Login Failed');
                                                            }}
                                                            // useOneTap={true}
                                                            // Styling the button
                                                            // style={{ width: '100%' }}
                                                            text="continue_with"
                                                            // type="icon"
                                                        />
                                                    </div>
                                                    {/* <CustomGoogleLoginButton/> */}
                                                    {/* <GoogleLogin
                                                        onSuccess={credentialResponse => {
                                                            console.log(credentialResponse);
                                                        }}
                                                        onError={() => {
                                                            console.log('Login Failed');
                                                        }}
                                                    /> */}
                                                </div>
                                            </form>
                                        </div>
                                        {/* Login Form End */}
                                    </div>
                                    <div className="col-xl-6 offset-xl-1 col-lg-6">
                                        {/* Register Form Start */}
                                        <div className="login-form-wrapper mt-sm-50 mt-xs-50">
                                            <h3 className="title">Register</h3>
                                            <form className="register-form" action="#">
                                                {/* <div className="single-input mb-30">
                                                    <label htmlFor="usernameOne">Username</label>
                                                    <input type="text" id="usernameOne" name="username" placeholder="Username" />
                                                </div> */}
                                                <div className="single-input mb-3">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="text" id="email" name="username" placeholder="Email" />
                                                </div>
                                                    <div className="single-input mb-3">
                                                        <label htmlFor="email">Firstname</label>
                                                        <input type="text" id="firstname" name="username" placeholder="Firstname" />
                                                    </div>
                                                <div className="single-input mb-3">
                                                    <label htmlFor="email">Surname</label>
                                                    <input type="text" id="surname" name="surname" placeholder="Surname" />
                                                </div>
                                                <div className="single-input mb-3">
                                                    <label htmlFor="passwordOne">Password</label>
                                                    <input type="text" id="passwordOne" name="password" placeholder="Password" />
                                                    {/* <p className="description">The password must be at least six characters long, contain upper and lower case letters, contain numbers.</p> */}
                                                </div>
                                                <div className="single-input mb-3">
                                                    <label htmlFor="passwordOne">Confirm Password</label>
                                                    <input type="text" id="passwordOne" name="password" placeholder="Password" />
                                                    {/* <p className="description">The password must be at least six characters long, contain upper and lower case letters, contain numbers.</p> */}
                                                </div>
                                                <div className="single-input">
                                                    <button className="btn btn-primary btn-hover-secondary btn-width-100">Register</button>
                                                    <button className="btn btn-outline-primary btn-hover-secondary btn-width-100 mt-3 outfit-600">Sign up with Google <img src={googleIcon} style={{ width: "30px" }} /></button>
                                                </div>
                                            </form>
                                        </div>
                                        {/* Register Form End */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default withLayout(UserLayout)(LoginRegister);