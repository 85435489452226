
import './App.css';


import './assets/css/main.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';
import './assets/css/vendor.css';
import './assets/css/override.css';
import './assets/css/serviceperson.css';
// import './assets/css/login-register.css';
import './assets/css/login2.css';
// import './assets/css/plugins.css';
import './assets/css/carousel-home.css';

// import Landing from './screens/shared/landing';
// import LandingTwo from './screens/shared/landing2';
import LandingThree from './screens/shared/landing3';
import UserDashboard from './screens/user/dashboard';
import HomeServicesIndex from './screens/services/index';
import ServiceDetails from './screens/services/service-details';
import LoginRegister from './screens/shared/login-register';
import LoginRegisterTwo from './screens/shared/login-register2';
import MoreServices from './screens/services/more-services';
import Subscriptions from './screens/services/subscriptions';
import Cart from './screens/shared/shopping-cart';
import Checkout from './screens/shared/checkout';
import LoginRegisterThree from './screens/shared/login2';
// import LoginRegisterFour from './screens/shared/login4';
import "animate.css/animate.compat.css"

import CatFishShopIndex from './screens/fish-farming/index';

import { UserProvider } from './context/userProvider';
import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PaymentSuccess from './screens/shared/payment-success';
import MeetingGround from './screens/meeting-ground';
import MeetingGroundCollection from './screens/meeting-ground/meeting-grounds';
import MeetingGroundDetails from './screens/meeting-ground/meeting-ground-details';
import FishShop from './screens/fish-farming/fish-shop';
import FishShopCart from './screens/fish-farming/shopping-cart';
import FishShopUserIndex from './screens/fish-farming/userindex';
import KoboProperties from './screens/real-estate/properties';
import PropertyDetails from './screens/real-estate/property-details';
import Termsofuse from './screens/shared/termsofuse';
import PrivacyPolicy from './screens/shared/privacy-policy';
import OurMotivation from './screens/shared/our-motivation';
import Membership from './screens/shared/membership';
import AboutUs from './screens/shared/aboutUs';
// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();
function App() {
  return (
    <UserProvider>
    <Router>
      <Routes>
        {/* <Route path={"/old"} element={<Landing />} exact /> */}
        {/* <Route path={"/home"} element={<LandingTwo />} exact /> */}
        <Route path={"/"} element={<LandingThree />} exact />
        <Route path={"/userindex"} element={<UserDashboard />} exact />
        <Route path={"/home-services"} element={<HomeServicesIndex />} exact />
        <Route path={"/service-details"} element={<ServiceDetails />} exact />
        {/* <Route path={"/login-register"} element={<LoginRegisterTwo />} exact /> */}
        <Route path={"/login-register"} element={<LoginRegisterThree />} exact />
        {/* <Route path={"/login-register"} element={<LoginRegisterFour />} exact /> */}
          <Route path={"/more-services"} element={<MoreServices />} exact />
          <Route path={"/subscription-plans"} element={<Subscriptions />} exact />
        <Route path={"/service-cart"} element={<Cart />} exact />
          <Route path={"/service-checkout"} element={<Checkout />} exact />
          <Route path={"/payment-processed"} element={<PaymentSuccess />} exact />



          <Route path={"/meeting-ground"} element={<MeetingGround />} exact />
          <Route path={"/meeting-ground-list"} element={<MeetingGroundCollection />} exact />
          <Route path={"/meeting-ground-details"} element={<MeetingGroundDetails />} exact />



          <Route path={"/catfish-shop"} element={<CatFishShopIndex />} exact />
          <Route path={"/catfish-shop-list"} element={<FishShop />} exact />
          <Route path={"/fish-shop-cart"} element={<FishShopCart />} exact />
          <Route path={"/fish-shop-userindex"} element={<FishShopUserIndex />} exact />



          <Route path={"/properties"} element={<KoboProperties />} exact />
          <Route path={"/property-details"} element={<PropertyDetails />} exact />
          <Route path={"/terms-of-use"} element={<Termsofuse />} exact />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} exact />
          <Route path={"/our-motivation"} element={<OurMotivation />} exact />
          <Route path={"/membership"} element={<Membership />} exact />
          <Route path={"/about-us"} element={<AboutUs />} exact />

        {/* <Route path={"/aboutus"} element={<AboutUs />} exact /> */}

      </Routes>
    </Router>
    </UserProvider>
  );
}

export default App;
