import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery"
import withLayout from "../../layouts/withLayout";
import UserLayout from "../../layouts/user";
import { GoogleOutlined, GooglePlusCircleFilled } from "@ant-design/icons";
import googleIcon from "../../assets/images/google.png"
import { FillSpinner } from "react-spinners-kit";
import { GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import axios from "axios";
import Endpoint from "../../util/endpoint";
import { isLoggedIn, loginUser, USER_KEY } from "../../util/auth";
import { Alert } from "antd";
import { ToastContainer, toast } from 'react-toastify';



const LoginRegisterThree = () => {
    const { pathname } = useLocation();
    const location = useLocation();
    const { displayProps } = location.state?.some || 'active';
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem(USER_KEY)))
    const [inputValues, setInputValues] = useState({
        checkbox: false,
        text: '',
    });
    // const [otpScreen, setotpScreen] = useState(false);
    // const [reg, setotpScreen] = useState(false);

    // const handleLogin = () => {
    //     $("#preloader").fadeIn("slow")
    //     setTimeout(() => {
    //         window.location.href = "/userindex"
    //     }, 3000);
    // }
    const handleLogin = () => {
        console.log(inputValues?.username, "cc")
        if (!inputValues?.username) {
            return;
        }
        $("#preloader").delay(450).fadeIn();

        const payload = {
            "username": inputValues?.username,
            "password": inputValues?.password,
            "fcmToken": ""

        }
            console.log(payload)
            Endpoint.signIn(payload)
            .then((res) => {
                console.log(res?.data)
                // return
                if(res?.data?.authToken != null){
                    loginUser(res?.data, displayProps);
                    $("#preloader").delay(450).fadeOut();
                }
                else{
                    alert("Invalid login credentials")
                    $("#preloader").delay(450).fadeOut();
                    throw new Error('Network response was not ok' + res.statusText);
                }
            })
            .catch((err) => {
                console.log(err)
                $("#preloader").delay(450).fadeOut();
            })
    }


    const handleSignUp = () => {
        console.log(inputValues?.username, "cc")
        if (!inputValues?.username) {
            return;
        }
        $("#preloader").delay(450).fadeIn();

        const payload = {
            "firstname": inputValues?.firstname,
            "lastname": inputValues?.lastname,
            "email": inputValues?.username,
            "password": inputValues?.password
        }
        console.log(payload)
        Endpoint.createAccount(payload)
            .then((res) => {
                console.log(res?.data)
                // return
                if (res?.data?.statusCode == 200) {
                    $("#preloader").fadeOut();
                    $("#signupBx").hide();
                    $("#otpBx").delay(450).fadeIn();
                    // loginUser(res?.data);
                }
                else {
                    alert("Something went wrong")
                    $("#preloader").delay(450).fadeOut();
                    throw new Error('Network response was not ok' + res.statusText);
                }
            })
            .catch((err) => {
                console.log(err)
                $("#preloader").delay(450).fadeOut();
            })
    }

    const validateOTP = () => {
        console.log(inputValues?.username, "cc")
        $("#preloader").fadeIn();

        Endpoint.validateotp(inputValues?.username, inputValues?.otp)
            .then((res) => {
                console.log(res?.data)
                if (res?.data?.authToken != null) {
                    loginUser(res?.data);
                }
                else {
                    $("#preloader").hide();
                    toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Invalid OTP</p>);
                    
                    throw new Error('Network response was not ok' + res.statusText);
                }
            })
            .catch((err) => {
                console.log(err)
                $("#preloader").delay(450).fadeOut();
            })
    }
    const googleSignInTrigger = (data) => {
        $("#preloader").delay(450).fadeIn("slow")
        const payload = {
            "email": "",
            "idToken": data?.credential
        }
        Endpoint.signInWithGoogle(payload)
            .then((res) => {
                console.log(res?.data, "response")
                loginUser(res?.data, displayProps)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            console.log(credentialResponse);
            googleSignInTrigger(credentialResponse)
        },
        onError: () => {
            console.log('Login Failed');
        },
    });
    const toggleForm = () => {
        const container = document.querySelector('.container');
        console.log(container, "container")
        container.classList.toggle('active');
    };
    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };
    useEffect(() => {
        isLoggedIn()
        window.scrollTo(0, 0);
        setTimeout(() => {
            $("#preloader").delay(450).fadeOut("slow")
        }, 1000);
        setTimeout(() => {
            document.getElementById("pword").value = ""
            document.getElementById("pword2").value = ""
            document.getElementById("uname").value = ""
            document.getElementById("email").value = ""
            document.getElementById("sname").value = ""
            
        }, 2000);
     

    }, []);

    return (
        <>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <div id="preloader">
                <div id="status">
                    <FillSpinner color="#ff5f00" backColor="#FFF" frontColor="#FFF" size={20} />
                    <p className='loader-text mt-1' style={{ fontWeight: "600" }}><span className='' style={{ color: "#076fcc" }}>Kobo<span style={{ color: "#ff5f00" }}>Kist</span></span></p>
                </div>
            </div>
            <div>
               
                {displayProps && 
                <div className="alert alert-primary text-center outfit-500" role="alert">
                    Create a <a href="#" className="alert-link">KoboKist</a> account or Login to continue
                </div>}
                <Link className="outfit-500" to={"/home-services"} style={{ marginLeft: "15px", marginTop: "10px", color:"#5850da" }}><i className="fa fa-home"/> Home</Link>
                <section className="lr-section">
                    
                    {/* <h2 className="fd-txt">KoboKist</h2> */}
                    <div className={"kobo-auth-container container " + displayProps}>
                        <div className="user signinBx">
                            <div className="imgBx"><img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/78c4af118001599.608076cf95739.jpg" alt="" /></div>
                            <div className="formBx">
                                <form>
                                    {/* <h2 className="fd-txt">KoboKist</h2> */}
                                    <h2>Sign In</h2>
                                    <input type="text" onChange={(e) => handleInput(e)} name="username" id="uname" placeholder="Email Address" />
                                    <input type="password" onChange={(e) => handleInput(e)} name="password" id="pword" placeholder="Password" />
                                    <input type="button" onClick={() => handleLogin()} defaultValue="Login" />
                                    <p className="text-center mt-2" style={{ fontSize: "11px" }}>OR</p>
                                    <div className="" style={{ justifyContent: 'center', width: "100%", display: "flex", marginTop:"-16px" }}>
                                         
                                        <GoogleLogin
                                            onSuccess={credentialResponse => {
                                                console.log(credentialResponse);
                                                googleSignInTrigger(credentialResponse)
                                            }}
                                            onError={() => {
                                                console.log('Login Failed');
                                            }}
                                            text="continue_with"
                                            type="icon"
                                        />
                                        &nbsp; <p className="text-center mt-3" style={{ fontSize: "11px" }}>Sign in with google</p>

                                    </div>
                                    <p className="signup">
                                        Don't have an account ?
                                        <a href="#" onClick={() => toggleForm()}>Sign Up.</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                        <div className="user signupBx ">
                            <div className="formBx" id="signupBx" 
                            // style={{ display: "none" }}
                            >
                                <form action>
                                    <h2>Create an account</h2>
                                    <input type="text" id="uname" onChange={(e) => handleInput(e)} name="firstname" placeholder="Firstname" />
                                    <input type="text" id="sname" onChange={(e) => handleInput(e)} name="lastname" placeholder="Surname" />
                                    <input type="email" id="email" onChange={(e) => handleInput(e)} name="username" placeholder="Email Address" />
                                    <input type="password" id="pword2" onChange={(e) => handleInput(e)} name="password" placeholder="Create Password" />
                                    <input type="button" onClick={() => handleSignUp()} defaultValue="Sign Up" />
                                    <p className="text-center" style={{ fontSize: "11px" }}>OR</p>
                                    <div className="" style={{ justifyContent: 'center', width: "100%", display: "flex", marginTop: "-16px" }}>

                                        <GoogleLogin
                                            onSuccess={credentialResponse => {
                                                console.log(credentialResponse);
                                                googleSignInTrigger(credentialResponse)
                                            }}
                                            onError={() => {
                                                console.log('Login Failed');
                                            }}
                                            text="continue_with"
                                            type="icon"
                                        />
                                        &nbsp; <p className="text-center mt-3" style={{ fontSize: "11px" }}>Sign up with google</p>

                                    </div>
                                    <p className="signup">
                                        Already have an account ?
                                        <a href="#" onClick={() => toggleForm()}>Sign in.</a>
                                    </p>
                                </form>
                            </div>
                            <div className="formBx" id="otpBx" 
                                style={{ display: "none" }}
                            >
                                
                                <form action>
                                    <p className="signup text-center">
                                        We've sent a One-Time Password (OTP) to your email. Please check your inbox (and spam/junk folder) and enter the OTP to proceed. <i class="fa-solid fa-circle-check" style={{ color:"#009f6e"}}/>
                                    </p>
                                    <h2>Enter OTP</h2>
                                    {/* <input type="text" id="uname" placeholder="Firstname" /> */}
                                    <input onChange={(e) => handleInput(e)} name="otp" type="text" placeholder="Enter OTP" />
                                    <input type="button" onClick={() => validateOTP()} defaultValue="Submit" />
                                    <p className="signup">
                                        Already have an account ?
                                        <a href="#" onClick={() => toggleForm()}>Sign in.</a>
                                    </p>
                                </form>
                            </div>
                            <div className="imgBx"><img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/78c4af118001599.608076cf95739.jpg" alt="" /></div>
                        </div>
                    </div>
                   
                </section>
               
            </div>
           

        </>
    );
}

export default LoginRegisterThree;