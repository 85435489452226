import React from 'react';
import UserHeader from './header';
import UserFooter from './footer';

function UserLayout({ children }) {
    return (
        <div>
            <UserHeader />
            <div>
                {children}
            </div>
            <UserFooter />
        </div>
    );
}

export default UserLayout;